import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import {
    GetInspectionDescriptionMaster,
    GetInspectionDetailForAdmin,
    toDownloadFile,
} from "../../Services/InspectionService";
import { useNavigate, useParams } from "react-router-dom";
import "./InspectionViewDetails.scss";
import { GetChatList } from "../../Services/FeedbackService";
import { useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import {
    AddChatToTenantInspection,
    GetTenantInspectionChats,
} from "../../Services/InspectionService";
import { DownloadFile } from "../../Services/Files";
import UseCookies from "../../Helpers/UseCookies";

const InspectionViewDetails = () => {
    const { inspectionId } = useParams();
    const Context = useSelector((state) => state.AppContext);
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [inspectionDetail, setInspectionDetail] = useState([]);
    const [descriptionList, setDescribtionList] = useState([]);
    const [chatList, setChatList] = useState([]);
    const navigate = useNavigate();
    const [chat, setChat] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const formatDate = (timestamp) => {
        const timestampDate = new Date(timestamp);

        return timestampDate.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    };

    const getInspectionDescriptionMaster = async () => {
        setIsLoading(true);
        await GetInspectionDescriptionMaster(cookies()?.bearerToken)
            .then((res) => {
                if (res?.status == 200) {
                    setDescribtionList(res?.data);
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
                // //console.log(organizationList);
            });
    };

    const getInspectionDetails = async () => {
        await GetInspectionDetailForAdmin(inspectionId, cookies()?.bearerToken)
            .then((res) => {
                if (res?.status == 200) {
                    setInspectionDetail(res?.data?.inspectionDetail);
                }
            })
            .catch((error) => console.log(error));
    };
    const getChatList = async () => {
        await GetTenantInspectionChats(inspectionId, cookies()?.bearerToken)
            .then((res) => {
                if (res?.status == 200) {
                    setChatList(res?.data);
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => { });
    };

    const sendChat = async () => {
        if (chat == "") {
            return;
        }
        await AddChatToTenantInspection(chat, inspectionId, cookies()?.bearerToken)
            .then((res) => {
                if (res?.status == 200) {
                    getChatList();
                    setChat("");
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => { });
    };

    const downloadFile = async (fileName, blobUrl) => {
        DownloadFile(fileName, blobUrl, cookies()?.bearerToken);
    };



    useEffect(() => {
        getInspectionDescriptionMaster();
        getInspectionDetails();
        getChatList();
    }, []);
    return (
        <div>
            <div
                className="head"
                style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
            >
                <i
                    className="pi pi-arrow-left arrowIcon"
                    style={{ fontSize: "1.5rem", color: "white", cursor: "pointer" }}
                    onClick={() => {
                        navigate("/inspection");
                    }}
                ></i>
                <p style={{ color: "#fff", margin: "auto" }}>
                    View Tenant Inspection Details
                </p>
            </div>
            {isLoading ? (
                <div
                    className="spinner spinner-center"
                    style={{ marginLeft: "750px" }}
                ></div>
            ) : (
                <div style={{ display: "flex", height: "max-content", marginBottom: "30px" }}>
                    <div style={{ width: "70%", borderRight: "solid 1px #ccc" }}>
                        {descriptionList?.map((_, index) => {
                            //console.log("Inspection List", _);
                            if (
                                inspectionDetail.filter((ele) => ele?.description === _.name)
                                    .length == 0
                            ) {
                                return <></>;
                            } else {
                                return (
                                    <>
                                        <div
                                            key={index}
                                            style={{
                                                display: "flex",
                                                padding: "6px 10px",
                                                backgroundColor: "#ae9d81",
                                                margin: "10px 10px",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "50%",
                                                    color: "#fff",
                                                    padding: "0 10px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                {_?.name}
                                            </div>
                                            <div
                                                style={{
                                                    width: "10%",
                                                    color: "#fff",
                                                    padding: "0 10px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Satisfied
                                            </div>
                                            <div
                                                style={{
                                                    width: "10%",
                                                    color: "#fff",
                                                    padding: "0 10px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                UnSatisfied
                                            </div>
                                            <div
                                                style={{
                                                    width: "20%",
                                                    color: "#fff",
                                                    padding: "0 10px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Comment
                                            </div>
                                            <div
                                                style={{
                                                    width: "10%",
                                                    color: "#fff",
                                                    padding: "0 10px",
                                                    textAlign: "center",
                                                }}
                                            >
                                                Attachment
                                            </div>
                                        </div>
                                        <>
                                            {inspectionDetail
                                                ?.filter((ele) => ele?.description === _.name)
                                                ?.map((list, index) => {
                                                    //console.log("inspectionDetail", list);
                                                    return (
                                                        <>
                                                            <div
                                                                key={index}
                                                                style={{ display: "flex", padding: "0 10px" }}
                                                            >
                                                                <div
                                                                    style={{ width: "50%", padding: "0 10px" }}
                                                                >
                                                                    {list?.question}
                                                                </div>
                                                                <div
                                                                    style={{ width: "10%", padding: "0 10px" }}
                                                                >
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <Checkbox
                                                                            checked={list?.isSatisfied}
                                                                            color="#ae9d81"
                                                                        ></Checkbox>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={{ width: "10%", padding: "0 10px" }}
                                                                >
                                                                    <div style={{ textAlign: "center" }}>
                                                                        <Checkbox
                                                                            checked={!list?.isSatisfied}
                                                                            color="#ae9d81"
                                                                        ></Checkbox>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style={{ width: "20%", padding: "0 10px" }}
                                                                >
                                                                    <InputText
                                                                        style={{
                                                                            width: "100%",
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden",
                                                                            textOverflow: "ellipsis",
                                                                        }}
                                                                        rows="4"
                                                                        cols="50"
                                                                        value={list?.comments}
                                                                        readOnly
                                                                        title={list?.comments}
                                                                    />
                                                                    {/* <InputText tooltip="sdfsadfwrgfawsgrergsdagegeasgrsthgasrfse" className='a' style={{
                                                                    width: "100%", whiteSpace: 'nowrap',
                                                                    width: '100px',
                                                                    overFlow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                }} rows="4" cols="50" placeholder='sdfs' /> */}
                                                                </div>
                                                                <div
                                                                    style={{
                                                                        width: "10%",
                                                                        padding: "0 10px",
                                                                        textAlign: "center",
                                                                    }}
                                                                >
                                                                    <p
                                                                        style={{
                                                                            cursor: "pointer",
                                                                        }}
                                                                        onClick={() =>
                                                                            downloadFile(
                                                                                list?.fileName,
                                                                                list?.blobURL
                                                                            )
                                                                        }
                                                                    >
                                                                        {list?.fileName?.length ? 1 : "No"} file
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <hr style={{ width: "95%" }} />
                                                        </>
                                                    );
                                                })}
                                        </>
                                    </>
                                );
                            }
                        })}
                    </div>
                    <div style={{ width: "30%" }}>
                        <div
                            style={{
                                display: "flex",
                                padding: "6px 10px",
                                backgroundColor: "#ae9d81",
                                margin: "10px 10px",
                                justifyContent: "center",
                            }}
                        >
                            <p style={{ color: "#fff", margin: 0 }}>Chat Box</p>
                        </div>
                        <div style={{ margin: "5px 10px" }}>
                            <InputTextarea
                                // type="text"
                                className="p-inputtext-lg"
                                style={{ width: "88%", height: "40px", fontSize: "15px", marginLeft: '0px', overflowWrap: 'break-word', overflow: 'scroll', overflowX: 'hidden', overflowY: 'hidden' }}
                                placeholder="Comments"
                                value={chat}
                                rows={2}
                                onChange={(e) => {
                                    setChat(e.target.value);
                                }}
                            />
                            <i
                                className="pi pi-send"
                                style={{
                                    fontSize: "1.5rem",
                                    color: "#AE9D81",
                                    margin: "10px",
                                    transform: "rotateZ(45deg)",
                                    cursor: "pointer",
                                }}
                                onClick={sendChat}
                            ></i>
                        </div>
                        <div style={{}}>
                            {chatList?.length !== 0 ? (
                                <div div className="flex-1 p-4 flexbox-3">
                                    <div style={{ overflow: "scroll", height: "700px", overflowX: 'hidden' }}>
                                        {chatList.map((chat, index) => (
                                            <div
                                                key={chat.id}
                                                className={
                                                    localStorage.getItem("userName") === chat.messageFrom
                                                        ? "user2"
                                                        : "user"
                                                }
                                            >
                                                {/* <div style={{ fontWeight: 200, fontSize: '15px', margin: '0px 0px -5px 0px' }}>{chat?.messageFrom}</div> */}
                                                <div
                                                    style={{
                                                        fontWeight: 200,
                                                        fontSize: "15px",
                                                        margin: "0px 0px 5px 0px",
                                                    }}
                                                >
                                                    {chat?.messageFrom}
                                                </div>
                                                <p
                                                    style={{
                                                        fontWeight: 400,
                                                        margin: "0px",
                                                        wordWrap: "break-word",
                                                        width: "250px",
                                                    }}
                                                >
                                                    {chat?.message}
                                                </p>

                                                <span
                                                    style={{
                                                        fontWeight: 200,
                                                        fontSize: "15px",
                                                        margin: "0px 0px -5px 0px",
                                                    }}
                                                >

                                                </span>
                                                <span
                                                    style={{
                                                        fontWeight: 200,
                                                        fontSize: "15px",
                                                        margin: "0px 0px -5px 0px",
                                                        float: "right",

                                                    }}
                                                >
                                                    {formatDate(chat?.messagedDate)}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div style={{ padding: "100px 0" }}>
                                        <p style={{ textAlign: "center" }}>No Chats Found</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InspectionViewDetails;
