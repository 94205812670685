import React, { useEffect, useState } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import editIcon from '../../../src/assets/images/editIcon.jpg';


//PrimeReact
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Paginator } from 'primereact/paginator';
import { toast } from 'react-toastify'
import { Dialog } from 'primereact/dialog';

// -----------------------------------





//-----------Context ---------------
import { useDispatch, useSelector } from 'react-redux'
import { setNavVisibilty } from '../../Redux/AppSlice'
// -----------------------------------


//-----------Services ---------------
import { GetAllLocation, AddOrEditLocation } from "../../Services/StorageLocationService";
import { exportDataInExcel, exportExcel } from '../../Services/ExportServices';
import { Calendar } from 'primereact/calendar';
//-----------------------------------

function StorageLocation() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [isLoading, setIsLoading] = useState(false);
    const [locationList, setLocationList] = useState([]);
    const [isAdd, setIsAdd] = useState(false);
    const [isAddLocation, setIsAddLocation] = useState(false);
    const [locationNameExists, setLocationNameExists] = useState(false);
    const [locationNameARExists, setLocationNameARExists] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [location, setLocation] = useState({
        locationName: "", locationNameInAr: "", locationStatus: true, locationId: ""
    });








    // Filters and Pagination state values
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [first, setFirst] = useState(0);



    // Filters
    const onGlobalFilterChange = (e) => {
        const value = e ? e.target.value : "";
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const onPageChange = () => {

    }
    // ------------------------------------------------------------

    const statusBodyTemplate = ((row) => {
        // //console.log(row);
        if (row.isDeleted === false) {
            return <p style={{ color: "green" }}  >Active</p>
        }
        else {
            return <p style={{ color: "red" }} >Inactive</p>
        }
    });

    const actionBodyTemplate = (row) => {
        return (
            <div onClick={(e) => {
                setIsAddLocation(true);
                setIsAdd(false);
                setLocation((prev) => ({
                    ...prev,
                    locationName: row?.name,
                    locationNameInAr: row?.nameInArabic,
                    locationStatus: !row?.isDeleted,
                    locationId: row?.id

                }))
                // //console.log(organization);
            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };



    const getLocationList = (() => {
        setIsLoading(true);
        GetAllLocation(cookies()?.bearerToken)
            .then((res) => {
                setLocationList(res?.data.map((location) => {
                    return {
                        id: location.id,
                        name: location.name,
                        nameInArabic: location.nameInArabic,
                        isDeleted: location.isDeleted,
                        createdDate: location.createdDate,
                        status: location.isDeleted ? "Inactive" : "Active"
                    }
                }))
                // setTotalRecords(res?.data?.count);
            })
            .catch((error) => {
                console.warn(error);
            })
            .finally(() => {
                setIsLoading(false);
            })
    });
    // const exportRoleInfo = () => {
    //     exportDataInExcel("Role", cookies()?.bearerToken);
    // }


    const addLocation = (() => {
        let data = {

            "name": location?.locationName,
            "nameInArabic": location?.locationNameInAr,
            "isDeleted": !location?.locationStatus
        }

        let locationNameExists = locationList.filter((item) => {
            return item.name === data.name;
        });

        let LocationNameArExists = locationList.filter((item) => {
            return item.nameInArabic === data.nameInArabic;
        });

        if (locationNameExists.length > 0) {
            setLocationNameExists(true);
            return;
        }
        setLocationNameExists(false);
        if (
            LocationNameArExists.length > 0) {
            setLocationNameARExists(true);
            return;
        }
        setLocationNameARExists(false);

        if (location?.locationName == "" || location?.locationNameInAr == "") {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
        setIsAddLocation(false);
        AddOrEditLocation(data, cookies()?.bearerToken)
            .then((res) => {
                // //console.log(res);
                setLocation({
                    locationName: "", locationNameInAr: "", locationStatus: true
                })
                setLocationNameExists(false);
                setLocationNameARExists(false);
                setIsInvalid(false)
                getLocationList(first, 10);
                toast.success("New Location is added successfully", { position: "top-right" });
            })
            .catch((error) => {
                //console.log(error);
            })
    })


    const editLocation = (() => {
        let data = {
            "id": location?.locationId,
            "name": location?.locationName,
            "nameInArabic": location?.locationNameInAr,
            "isDeleted": !location?.locationStatus
        }

        let locationNameExists = locationList.filter((item) =>
            item?.name === data?.name && item?.id != location?.locationId
        );

        let LocationNameArExists = locationList.filter((item) =>
            item?.nameInArabic === data?.nameInArabic && item?.id != location?.locationId
        );

        if (locationNameExists.length > 0) {
            setLocationNameExists(true);
            return;
        }
        setLocationNameExists(false);
        if (LocationNameArExists.length > 0) {
            setLocationNameARExists(true);
            return;
        }
        setLocationNameARExists(false);

        if (location?.locationName == "" || location?.locationNameInAr == "") {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
        AddOrEditLocation(data, cookies()?.bearerToken)
            .then((res) => {
                //console.log(res);
                setLocation({
                    locationName: "", locationNameInAr: "", locationStatus: true, locationId: ""
                })
                setIsAddLocation(false);
                setLocationNameExists(false);
                setLocationNameARExists(false);
                getLocationList(first, 10);
                toast.success("Location is updated successfully", { position: "top-right" });

            })
            .catch((error) => {
                //console.log(error);
            })
    })

    const back = () => {
        navigate('/dashboard');
    };

    const Export = () => {
        if (globalFilterValue) {
            // exportDataInExcel(`Department?searchTerm=${globalFilterValue}`, cookies()?.bearerToken);
            return
        }
        // exportDataInExcel('Department', cookies()?.bearerToken);
    }
    const onExportStorageLocation = () => {
        let urlRoute = `StoreLocation?searchTerm=${globalFilterValue}`;
        exportExcel(urlRoute);
    }


    useEffect(() => {
        getLocationList();
    }, [])




    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}> {isAdd ? "Add" : "Edit"} Location Details</p>
        </div>
    }


    const popup = (() => {
        return (
            <>

                <Dialog header={header} visible={true} closable={false} style={{ width: '30vw' }} onHide={() => {
                    setLocationNameExists(false); setLocationNameARExists(false);
                    setIsAddLocation(false); setIsInvalid(false);
                    setLocation({
                        locationName: "", locationNameInAr: "", locationStatus: true, locationId: ""
                    })
                }}>
                    <div className="popup " >
                        {/* <div className="heading">
                        <p>Add New Details</p>
                    </div> */}
                        <div className="body">
                            <div className="label grid">
                                <div className='col'>Location Name<span style={{ color: 'red' }}>*</span></div>
                                <div className='col'>Status</div>
                            </div>
                            <div className="input grid ">
                                <div className="col" >
                                    <InputText placeholder="Enter Location Name" style={{ width: "100%", border: "1px solid #ae9d81", boxShadow: 'none' }} value={location?.locationName} onChange={(e) => { setLocation((prev) => ({ ...prev, locationName: e.target.value })) }} />
                                    {locationNameExists &&
                                        // <div>
                                        <p style={{ color: "red" }}>Location name is already exists</p>
                                        // </div>
                                    }
                                    {isInvalid && location?.locationName == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Location name is required</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col ">
                                    <label className="switch ">
                                        <input type="checkbox" value={location?.locationId} checked={location?.locationStatus} onChange={(e) => { setLocation((prev) => ({ ...prev, locationStatus: e.target.checked })); }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            </div>
                            <div className="label grid">
                                <div className='col'>Location Name AR<span style={{ color: 'red' }}>*</span></div>
                            </div>
                            <div className="input grid ">
                                <div className="col" >
                                    <InputText placeholder="Enter Location Name AR" style={{ width: "50%", border: "1px solid #ae9d81", boxShadow: 'none', direction: 'rtl', textAlign: 'right' }} value={location?.locationNameInAr} onChange={(e) => { setLocation((prev) => ({ ...prev, locationNameInAr: e.target.value })) }} />
                                    {locationNameARExists &&
                                        // <div>
                                        <p style={{ color: "red" }}>Location name (AR)  is already exists</p>
                                        // </div>
                                    }
                                    {isInvalid && location?.locationNameInAr == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Location name (AR) is required</p>
                                        // </div>
                                    }
                                </div>

                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel" onClick={() => {
                                    setLocationNameExists(false);
                                    setLocationNameARExists(false);
                                    setIsAddLocation(false);
                                    setIsInvalid(false)
                                    setLocation({
                                        locationName: "", locationNameInAr: "", locationStatus: true, locationId: ""
                                    });
                                }} style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "Submit" : "Update"} onClick={() => { isAdd ? addLocation() : editLocation() }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog >

            </>
        )
    })

    // Export Filter
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [exportDetails, setExportDetails] = useState({
        startDate: "",
        endDate: "",
        organization: "",
        desk: "",
        status: true
    });

    return (
        <div className="storagelocation">
            <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                        </label>
                        <Calendar
                            id="StartDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.startDate}
                            placeholder="Choose start end"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.endDate}
                            placeholder="Choose end date"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <InputText
                            id="organization"
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                            placeholder="Enter organization" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="Status" className="font-bold block mb-2">Status
                        </label>
                        {/* <InputText
                        id="Status"
                        style={{ width: "95%" }}
                        value={exportDetails?.status}

                        placeholder="Enter organization" ></InputText> */}
                        <div className="input grid ">
                            <div className="col ">
                                <label className="switch ">
                                    <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                    }}
                >
                    <Button
                        label="Submit"
                        onClick={onExportStorageLocation}
                        // icon={"pi pi-file-export"}
                        style={{
                            backgroundColor: " #ae9d81",
                            color: "#fff",
                            border: "none",
                            // borderRadius: "20px",
                            marginRight: "0",
                            cursor: "pointer",
                            padding: "0.6rem",
                        }}
                    />
                </div>


            </Dialog>
            <div className='header flex align-items-center justify-content-between mb-2 h-auto' >
                <div className='flex align-items-center org'>
                    <div className="menubtn" style={{ padding: '10px 10px 5px 20px' }}>
                        <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem', color: 'black' }} />
                    </div>
                    <div className='heading' style={{ marginLeft: '30px' }}>
                        <span></span>
                        <p> Storage Location </p>
                    </div>
                </div>
                <div className='flex align-items-center'>
                    <div className='mx-2'>
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Status</h4>
                        <div className='flex align-items-center mx-2' style={{ border: "solid 1px #AE9D81", borderRadius: "4px" }}>
                            <i className="pi pi-search px-2" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='border-0' style={{ width: "180px" }} />
                            <i className="pi pi-times px-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} />
                        </div>
                    </div>
                    <div className='mx-2 align-self-end'>
                        <Button label='Add Location' onClick={() => { setIsAddLocation(true); setIsAdd(true) }} style={{ backgroundColor: '#ae9d81', color: '#fff', border: '1px #ae9d81 solid', cursor: 'pointer' }} />
                    </div>
                    <div className='mx-2 align-self-end'>
                        <Button label='Export' onClick={onExportStorageLocation} icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: 'pointer' }} />
                    </div>
                </div>

            </div>
            <div className='datatable' style={{ margin: "0px 48px" }}>
                {isLoading ?
                    <div className='spinner spinner-center' style={{ marginLeft: "700px" }}></div>
                    :
                    <DataTable
                        rows={10}
                        paginator
                        value={locationList}
                        filters={filters}
                        tableStyle={{ width: '90rem' }}
                        style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} stripedRows
                        emptyMessage={<><p style={{ textAlign: "center" }}>Storage location list is empty</p></>}>
                        <Column field='id' header='Storage Location Id' style={{ width: '16.66%' }}></Column>
                        <Column field='name' header='Storage Name' style={{ width: '16.66%' }}></Column>
                        <Column field='nameInArabic' header='Storage Name AR' style={{ width: '16.66%' }}></Column>
                        <Column field='createdDate' header='Created Date' style={{ width: '16.66%' }}></Column>
                        <Column field='status' header='Status' body={statusBodyTemplate} style={{ width: '16.66%' }}></Column>
                        <Column rowEditor header="Action" body={actionBodyTemplate} style={{ width: '16.66%' }}></Column>
                    </DataTable>}
            </div>
            {/* {isLoading ? ""
                :
                <div style={{ justifyContent: "center", display: 'flex' }} >
                    <Paginator first={first} rows={10} totalRecords={totalCount} onPageChange={(e) => onPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport' }} className="justify-content-start" />
                </div>
            } */}
            <div>
                {isAddLocation == true ? popup() : ""}
            </div>

        </div >
    )
}

export default StorageLocation