import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import './style.scss';


// Prime React Components
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import UseCookies from '../../Helpers/UseCookies';


// Services
import { EditLostItemForm, GetLostItem } from '../../Services/LostandFoundService';
import { GetActiveCategory, GetStatusForLostItem } from '../../Services/LostAndFoundCategoryService';
import { BulkUploadBase64Files, DownloadFile, getAttachmentFile } from '../../Services/Files';
import { CiImageOn } from 'react-icons/ci';
import { Dialog } from 'primereact/dialog';
import IntlTelInput from 'react-intl-tel-input';



function EditLostItem() {
    const { id, route } = useParams();
    const inputRef = useRef(null);
    const localizationValue = "en";
    const [cookies, setCookie, removeCookie] = UseCookies();
    const navigate = useNavigate();
    const [lostItemForm, setLostItemForm] = useState(
        {
            'id': 0,
            "firstName": '',
            "lastName": "",
            "email": "",
            "phoneNumber": "",
            "countryCode": "",
            "category": "",
            "categoryId": "",
            "whatDidYouLose": "",
            "lostDateTime": "",
            "whereDidYouLose": "",
            "statusId": 0
        }
    );
    const [activeCategories, setActiveCategories] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [isInvalid, setIsInvalid] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const [previousStatus, setPreviousStatus] = useState(0);


    // File preview
    const [previewLoader, setPreviewLoader] = useState({
        id: "",
        isLoad: false,
    });
    const [previewAttachment, setPreviewAttachment] = useState({
        isOpen: false,
        attachmentUrl: "",
    });
    //   --------------------


    // Mobile number with conutry code - Deepak
    const handlePhoneChange = (value, countryData) => {
        if (
            value?.length <= 12 &&
            /^[0-9]+$|^$/.test(value) &&
            value !== lostItemForm?.phoneNumber
        ) {
            setLostItemForm({
                ...lostItemForm,
                phoneNumber: value,
                countryCode: countryData?.dialCode,
            });
        }
        // setPhoneNumber(value);
    };

    const getSelectedCountryName = () => {
        let selectedCountry = "bh";
        if (inputRef?.current?.countries && lostItemForm?.countryCode) {
            selectedCountry = [...inputRef?.current?.countries]?.find(
                (val) => val.dialCode === lostItemForm?.countryCode.split("+")[1]
            )?.iso2;
        }
        console.log("selectedCountry", selectedCountry);
        return selectedCountry ? selectedCountry : "bh";
    };
    // ------------------------------



    const getLostItem = async (lostItemItem) => {
        await GetLostItem(lostItemItem, cookies()?.bearerToken)
            .then(async (res) => {
                //console.log(res.data);
                if (res.status === 200) {
                    let categoryList = [];
                    if (activeCategories?.length == 0) {
                        categoryList = await getActiveCategories();
                    }
                    let category = categoryList?.filter((_) => _.id == res.data.categoryId)[0];
                    setLostItemForm((prev) => (
                        {
                            ...prev,
                            id: res.data.id,
                            firstName: res.data.firstName,
                            lastName: res.data.lastName,
                            email: res.data.email,
                            phoneNumber: res.data.phoneNumber,
                            countryCode: res.data.countryCode,
                            category: category,
                            categoryId: res.data.categoryId,
                            lostItem: res.data.whatDidYouLose,
                            lostDateTime: res.data.lostDateTime,
                            lostLocation: res.data.whereDidYouLose,
                            statusId: res.data.statusId,
                            file: res.data?.files
                        }
                    ));
                    setPreviousStatus(res?.data?.statusId);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
            })
        //console.log("Lost Item ", lostItemForm);

    }

    const getActiveCategories = async () => {
        let list = []
        await GetActiveCategory(cookies()?.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log("Active Categories", res);
                    setActiveCategories(res.data);
                    list = res.data;
                }
            })
            .catch((err) => {
                //console.log(err);
            })
        return list;
    };

    const getStatus = async () => {
        await GetStatusForLostItem(cookies()?.bearerToken)
            .then((res) => {
                //console.log(res);
                if (res.status === 200) {
                    setStatusList(res.data);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
            })
    };

    const handleLostItemFormSubmit = async () => {
        if (lostItemForm?.statusId == 0) {
            setIsInvalid(true);
            return;
        }
        let data = {
            'id': id,
            "firstName": lostItemForm?.firstName,
            "lastName": lostItemForm?.lastName,
            "email": lostItemForm?.email,
            "phoneNumber": lostItemForm?.phoneNumber,
            "countryCode": lostItemForm?.countryCode,
            "categoryId": lostItemForm?.categoryId,
            "whatDidYouLose": lostItemForm?.lostItem,
            "lostDateTime": lostItemForm?.lostDateTime,
            "whereDidYouLose": lostItemForm?.lostLocation,
            "statusId": lostItemForm?.statusId
        }
        //console.log(data);
        setIsFormSubmitting(true);
        EditLostItemForm(data, cookies()?.bearerToken)
            .then((res) => {
                //console.log(res);
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                if (route === "g3") { navigate('/lostandfound/lost-list') }
                if (route == "s2") { navigate('/security-staff/2') }
            })
    };

    const downloadFile = (_) => {
        DownloadFile(_.fileName, _.blobUrl, cookies()?.bearerToken);
    };
    const previewImage = async (url) => {
        console.log(url);
        setPreviewLoader({ id: url.moduleId, isLoad: true });
        let data = [
            {
                fileName: url.fileName,
                azureBlobUrl: url.blobUrl.replaceAll("\\", "/"),
            },
        ]
        await getAttachmentFile(data, cookies()?.bearerToken)
            .then((res) => {
                console.log(res);
                setPreviewAttachment({
                    isOpen: true,
                    attachmentUrl: res?.data?.[0].base64,
                });
                setPreviewLoader({ id: "", isLoad: false });
            })
            .catch((err) => {
                console.log(err);
            });
    };



    useEffect(() => {
        getActiveCategories();
        getStatus();
        getLostItem(id);
    }, [])



    return (
        <div className="EditLostItem">
            <Dialog
                className="preview-Attachment-Popup"
                visible={previewAttachment.isOpen}
                onHide={() => {
                    setPreviewAttachment({ isOpen: false, attachmentUrl: "" });
                }}
                style={{ width: "50%" }}
                header={
                    <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                        Preview
                    </h3>
                }
            >
                <img
                    src={"data:image/jpeg;base64," + previewAttachment.attachmentUrl}
                    style={{ height: 400, width: 600 }}
                ></img>
            </Dialog>
            <div className="header" style={{ backgroundColor: '#ae9d81', color: 'white', height: "50px" }}>

                <i className="pi pi-arrow-left" onClick={() => { if (route === "g3") { navigate('/lostandfound/lost-list') } if (route == "s2") { navigate('/security-staff/2') } }}></i>
                <div style={{ margin: 'auto', fontWeight: '600', fontSize: '18px' }}>Edit Lost Item</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 50px' }}>
                <div>
                    <p>First Name</p>
                    <div>
                        <InputText disabled placeholder='Enter First Name' style={{ width: '350px' }} value={lostItemForm?.firstName} />
                    </div>
                </div>
                <div>
                    <p>Last Name</p>
                    <div>
                        <InputText disabled placeholder='Enter Last Name' style={{ width: '350px' }} value={lostItemForm?.lastName} />
                    </div>
                </div>
                <div>
                    <p>Email</p>
                    <div>
                        <InputText disabled placeholder='Enter Email' style={{ width: '350px' }} value={lostItemForm?.email} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div>
                    <p>Mobile Number</p>
                    <div>
                        {/* <InputText disabled placeholder='Enter Mobile Number' style={{ width: '350px' }} value={lostItemForm?.phoneNumber} /> */}
                        <IntlTelInput
                            style={{ width: "350px" }}
                            noCountryDataHandler={(e) => console.log(e)}
                            disabled={true}
                            value={lostItemForm?.phoneNumber}
                            containerClassName="intl-tel-input phoneInputContainerStyle"
                            inputClassName={"phoneInputStyle"}
                            onSelectFlag={(value, countryData) => {
                                setLostItemForm({ ...lostItemForm, countryCode: countryData?.dialCode })
                            }}
                            onPhoneNumberChange={(
                                valid,
                                value,
                                countryData,
                                number,
                                id
                            ) => {
                                handlePhoneChange(value, countryData);

                            }}
                            // countryData={getSelectedCountryData()}
                            // initialCountry={userInformations?.phoneNumberCode}
                            defaultCountry={getSelectedCountryName()}
                            // tempCountry="bh"
                            preferredCountries={["bh", "ae"]}
                            ref={inputRef}
                        />
                    </div>
                </div>
                <div>
                    <p>Category</p>
                    <div>
                        <InputText disabled placeholder='Enter Category' style={{ width: '350px' }} value={lostItemForm?.category?.name} />
                    </div>
                </div>
                <div>
                    <p>What did you lost?</p>
                    <div>
                        <InputText disabled placeholder='Enter what did you lost' style={{ width: '350px' }} value={lostItemForm?.lostItem} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }} >
                <div>
                    <p> When did you lost?(Date)</p>
                    <div>
                        <Calendar disabled placeholder='Select date' className="calendar" style={{ width: '350px' }} showIcon value={new Date(lostItemForm?.lostDateTime)} maxDate={new Date()} showTime hourFormat='24' />
                    </div>
                </div>
                <div>
                    <p>Where did you lost?</p>
                    <div>
                        <InputText disabled placeholder='Enter where did you lost' style={{ width: '350px', }} value={lostItemForm?.lostLocation} />
                    </div>
                </div>
                <div>
                    <p>Attachment</p>
                    {/* <div>
                        <InputText disabled style={{ width: '350px', height: '150px' }} />
                    </div> */}
                    <div className='defaultWidth'>
                        <div style={{
                            height: "100px",
                            overflow: "auto",
                            width: '350px',
                            border: "solid 1px #AE9D81",
                            borderRadius: '4px',
                            padding: "10px"
                        }}>
                            {
                                lostItemForm?.file?.map((_) => {
                                    return (
                                        <div style={{ display: "flex", alignItems: "center", marginLeft: '5px' }}>
                                            <CiImageOn style={{ fontSize: 20 }} />
                                            <p style={{ fontSize: '12px', padding: "0 10px", cursor: "pointer" }} onClick={() => {
                                                // downloadFile(_);
                                                previewImage(_);
                                            }}>{_.fileName}</p>
                                        </div>)
                                }
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ display: "flex", justifyContent: 'space-between', margin: '0px 50px 0px 50px' }} >
                <div>
                    <p> Status</p>
                    <div>
                        <Dropdown options={statusList} placeholder='Select status' className="calendar" style={{ width: '350px', borderColor: '#ae9d81' }}
                            disabled={previousStatus !== 1 ? true : false}
                            optionLabel='name' optionValue='id' value={statusList.filter((_) => _.id == lostItemForm?.statusId)[0]?.id} onChange={(e) => { setLostItemForm((prev) => ({ ...prev, statusId: e.target.value })) }} />
                    </div>
                    {
                        isInvalid &&
                        <span style={{ color: "red" }}>Status is required</span>
                    }
                </div>
                <div style={{}}>

                </div>

                <div style={{ marginTop: '25px' }}>
                    <Button label='Cancel' className='cnl-btn' onClick={() => { if (route === "g3") { navigate('/lostandfound/lost-list') } if (route == "s2") { navigate('/security-staff/2') } }} />
                    {previousStatus == 1 && <Button label='Update' className='smt-btn' loading={isFormSubmitting} disabled={isFormSubmitting} onClick={() => { handleLostItemFormSubmit() }} />}
                </div>

            </div>



        </div>
    )
}

export default EditLostItem