import Axios from "../Helpers/Axios";

export function GetUserList(take, skip, token) {
  return Axios(
    `User/GetSystemUsers?skip=${skip}&take=${take}`,
    "get",
    null,
    token
  );
}
export function RegisterVIPUser(data, token) {
  return Axios(`User/RegisterVIPUser`, "post", data, token);
}
export function EditVIPUser(data, token) {
  return Axios(`VIPLounge/VIPLoungeStaff/EditVIPUser`, "post", data, token);
}
export function RegisterVIPUserByLoungeStaff(data, token) {
  return Axios(`VIPLounge/VIPLoungeStaff/RegisterVIPUser`, "post", data, token);
}
export function ApproveVIPUser(vipUserId, token) {
  return Axios(
    `User/ApproveVIPUser?vipUserId=${vipUserId}
    `,
    "post",
    null,
    token
  );
}
export function ActivateVIPUser(encryptedUserId, token) {
  return Axios(
    `User/ActivateVIPUser?encryptedUserId=${encryptedUserId}`,
    "post",
    null,
    token
  );
}
export function GetVIPUserList(skip, take, searchTerm, token, from = "", to = "") {
  return Axios(
    `User/ViewVIPUsers?skip=${skip}&take=${take}&searchTerm=${searchTerm}&fromDate=${from}&toDate=${to}`,
    "get",
    null,
    token
  );
}
export function GetVIPUser(vipUserCode, token) {
  return Axios(
    `User/ViewVIPUser?vipUserCodeOrCardNumberOrMobileNumber=${vipUserCode}`,
    "get",
    null,
    token
  );
}
export function GetSubscriptionDetail(vipUserCode, token) {
  return Axios(
    `User/CheckSubscriptionDetail?vipUserCodeOrCardNumber=${vipUserCode}`,
    "get",
    null,
    token
  );
}

export function GetSubscription(skip, take, token) {
  return Axios(
    `VIPLounge/VIPLoungeMaster/GetSubscriptions?skip=${skip}&take=${take}`,
    "get",
    null,
    token
  );
}

// Invoice
export function GetVIPInvoices(skip, take, searchTerm, token, forExport = "", fromDate = "", toDate = "") {
  return Axios(
    `User/GetVIPInvoices?skip=${skip}&take=${take}&searchTerm=${searchTerm}&searchTerm=${searchTerm}&fromDate=${fromDate}&toDate=${toDate}&forExport=${forExport}`,
    "get",
    null,
    token
  );
}
export function supervisorGetVIPInvoices(searchTerm, token) {
  return Axios(
    `User/ViewInvoiceDetail?invoiceReferenceId=${searchTerm}`,
    "get",
    null,
    token
  );
}

export function ViewInvoiceDetail(invoiceDetailId, token) {
  return Axios(
    `User/ViewInvoiceDetail?invoiceDetailId=${invoiceDetailId}`,
    "get",
    null,
    token
  );
}
export function ApproveInvoice(invoiceDetailId, token) {
  return Axios(
    `User/ApproveInvoice?invoiceDetailId=${invoiceDetailId}`,
    "patch",
    null,
    token
  );
}
export function RejectInvoice(invoiceDetailId, comments, token) {
  return Axios(
    `User/RejectInvoice?invoiceDetailId=${invoiceDetailId}&comments=${comments}`,
    "patch",
    null,
    token
  );
}

// VIP search

export function extendExpiryDate(vipId, date, token) {
  return Axios(
    `User/ExtendExpiryOfVIPUser?vipUserCodeOrCardNumber=${vipId}&extendedExpiryDate=${date}`,
    "patch",
    null,
    token
  );
}

// get active vipcode
export function GetActiveVipCode(token, searchTerm) {
  return Axios(
    `VIPLounge/VIPLoungeStaff/ActiveVipCodes?searchTerm=${searchTerm}`,
    "get",
    null,
    token
  );
}

export function GetAllPurchaseDetails(token, skip, take, vipCode = "", from = "", to = "", forExport = "", OrgId, itemId = 0) {
  return Axios(
    `User/GetPurcasedPantryDetail?vipCode=${vipCode || ""}&skip=${skip}&take=${take}&fromDate=${from}&toDate=${to}&forExport=${forExport}&orgId=${OrgId}&itemId=${itemId}
    `,
    "get",
    null,
    token
  );
}

export function GetPurchaseItemDetails(token, id) {
  return Axios(
    `User/GetPurchasedPantryItemDetail?purchasedPantryDetailId=${id}
    `,
    "get",
    null,
    token
  );
}
