import React, { useState, useEffect } from "react";
import "./style.scss";
// -------------Imports---------------
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Paginator } from "primereact/paginator";

// -----------------------------------

// ----------Assets Import------------
import editIcon from "../../../src/assets/images/editIcon.jpg";
import nationalityListJSON from "../../assets/JSON/NationalityFlags.json"
import nationality from "../../assets/JSON/NationalityFlags.json";
// -----------------------------------

//--------------Service Imports ------------------
import {
  GetUserList,
  AddUser,
  EditUser,
  getNationality,
  getUserListWithSearch,
  IsUserNameExist,
  IsEmailExist,
} from "../../Services/UserManagementService";
import { GetRolesList } from "../../Services/RoleService";
import { GetDepartmentList } from "../../Services/UserManagementService";
import { GetOrganizationList } from "../../Services/OrganizationService";

// -----------------------------------------------

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import {
  setNavVisibilty,
  addRoleList,
  addDepartmentListContext,
  addOrganizationList,
} from "../../Redux/AppSlice";
import { FilterMatchMode } from "primereact/api";
import { exportDataInExcel, exportExcel, exportFilterdExcel } from "../../Services/ExportServices";
import { Calendar } from "primereact/calendar";
import UseCookies from "../../Helpers/UseCookies";
//----------------------------------------

function UserManagement() {
  const [isAddUserPopup, setIsAddUserPopup] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    reenterPassword: "",
    mobileNo: "",
    nationality: "",
    email: "",
    isDeleted: true,
    roles: [],
    organizations: [],
    departments: [],
    isDeleted: true,
    id: 0,
    countryCode: '+973'
  });
  const [isUserValid, setIsUserValid] = useState(false);
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isUserNameExists, setIsUserNameExists] = useState(false);
  const [isEmailExists, setIsEmailExists] = useState(false);
  const [isUsernameChanged, setIsUsernameChanged] = useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const [prevUsername, setPrevUsername] = useState("");
  const [prevEmail, setPrevEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [cookies, setCookie, removeCookie] = UseCookies();
  const [userList, setUserList] = useState([]);
  const Context = useSelector((state) => state.AppContext);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [roleList, setRoleList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [organizationList, setOrganizationList] = useState([]);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [nationalityList, setNationalityList] = useState(nationalityListJSON);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalRecords] = useState(0);

  /*
  @author - Deepak
  @date - 2014-7-11
  @description - Email valid state and add user loading
   */
  const [isEmailInvalid, setIsEmailValid] = useState(false);
  const [addUserLoading, setAddUserLoading] = useState(false);
  // ----------------------------------------------------------------

  /**
   * 
   * @author - Deepak
   * @date - 13-8-2024
   * @description -State for Country Code
   * @returns 
   */
  const [selectedCountry, setSelectedCountry] = useState(
    {
      "english_name": "Bahrain",
      "arabic_name": "البحرين",
      "alpha2_code": "BH",
      "alpha3_code": "BHR",
      "phone_code": "+973",
      "flagImage": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
    }
  );
  // ----------------------------------------------------------------

  const statusBodyTemplate = (row) => {
    // //console.log(row);
    if (row.isDeleted === false) {
      return <p style={{ color: "green" }}>Active</p>;
    } else {
      return <p style={{ color: "red" }}>Inactive</p>;
    }
  };
  const rowBodyTemplate = (row) => {
    // //console.log(row);
    return row?.roles.join(", ");
  };
  const actionButtonTemplate = (row) => {
    return (
      <div
        onClick={(e) => {
          //console.log("Edit User", row);
          // //console.log(row);
          //console.log(roleList);
          setIsAddUserPopup(true);
          setIsAdd(false);
          setIsUserValid(false);
          setUser((prev) => ({
            ...prev,
            firstName: row?.firstName,
            lastName: row?.lastName,
            email: row.email,
            mobileNo: row.mobileNo,
            nationality: row.nationality,
            isDeleted: !row.isDeleted,
            userStatus: row.status,
            roles: roleList.filter((role) => row.roles.includes(role.roleName)),
            departments: departmentList.filter((dep) =>
              row.departments.includes(dep.name)
            ),
            organizations: organizationList.filter((org) =>
              row.organizations.includes(org.name)
            ),
            id: row.userId,
            userName: row?.userName,
            countryCode: row?.countryCode
          }));
          console.log(nationality.filter((n) => n.phone_code == row.countryCode)[0]);
          setSelectedCountry(nationality.filter((country) => country.phone_code == row?.countryCode)[0])
          setPrevUsername(row.userName);
          setPrevEmail(row.email);
        }}
      >
        <img src={editIcon} style={{ cursor: "pointer" }} />
      </div>
    );
  };

  const back = () => {
    navigate("/dashboard");
  };

  const header = () => {
    return (
      <div
        className="heading"
        style={{
          fontWeight: "700",
          fontSize: "25px",
          color: "#ae9d81",
          textAlign: "center",
        }}
      >
        <p style={{ margin: "0px" }}> {isAdd ? "Add" : "Edit"} New Details</p>
      </div>
    );
  };



  /*
  @author Deepakraj
  @date 11/7/24
  @description - Email Validatio
   */
  // mail validation

  const emailValidation = () => {
    var emailFormat = /^([^\s@]+)@([A-Za-z])+\.([A-Za-z])+$/;
    let isValidEmail = emailFormat.test(user?.email);
    return isValidEmail;
  };

  // -------------------------------------------------



  // Deepak - 13/8/24
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.flagImage} src={option?.flagImage} style={{ width: '18px' }} />
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img alt={option.name} src={option?.flagImage} style={{ width: '18px' }} />
        <div>{option?.english_name}({option?.arabic_name}){option?.phone_code}</div>
      </div>
    );
  };
  const handleCountryChange = (value) => {
    console.log("Change", value);
    setUser((prev) => ({ ...prev, countryCode: value.phone_code }))

  }
  // ----------------------------------------------------------------

  const popup = () => {
    return (
      <>
        <Dialog
          header={header}
          visible={true}
          closable={false}
          style={{ width: "70vw" }}
          onHide={() => {
            setIsAddUserPopup(false);
            setIsAdd(false);
            setUser((prev) => ({
              ...prev,
              firstName: "",
              lastName: "",
              userName: "",
              password: "",
              mobileNo: "",
              nationality: "",
              email: "",
              isDeleted: true,
              roles: [0],
              organizations: [0],
              departments: [0],
            }));
          }}
        >
          <div className="popup" style={{ width: "1000px" }}>
            {/* <div className="heading">
                            <p>Add New Details</p>
                        </div> */}
            <div className="body">
              <div className="label grid">
                <div className="col">
                  First Name<span style={{ color: "red" }}>*</span>
                </div>
                <div className="col">
                  Last Name<span style={{ color: "red" }}>*</span>
                </div>
                <div className="col">
                  Username<span style={{ color: "red" }}>*</span>
                </div>
              </div>
              <div className="input grid">
                <div className="col">
                  <InputText
                    placeholder="Enter First Name"
                    value={user.firstName}
                    onChange={(e) => {
                      setUser((prev) => ({
                        ...prev,
                        firstName: e.target.value,
                      }));
                    }}
                  />
                  {
                    isUserValid && user.firstName == "" && (
                      // <div>
                      <p
                        style={{
                          color: "red",
                          fontSize: "13px",
                          fontWeight: "400",
                          margin: "4px 4px",
                        }}
                      >
                        First name is required
                      </p>
                    )
                    // </div>
                  }
                </div>
                <div className="col">
                  <InputText
                    placeholder="Enter Last Name"
                    value={user.lastName}
                    onChange={(e) => {
                      setUser((prev) => ({
                        ...prev,
                        lastName: e.target.value,
                      }));
                    }}
                  />
                  {
                    isUserValid && user.lastName == "" && (
                      // <div>
                      <p
                        style={{
                          color: "red",
                          fontSize: "13px",
                          fontWeight: "400",
                          margin: "4px 4px",
                        }}
                      >
                        Last name is required
                      </p>
                    )
                    // </div>
                  }
                </div>
                <div className="col">
                  <InputText
                    placeholder="Enter Username"
                    value={user.userName}
                    onChange={(e) => {
                      setUser((prev) => ({
                        ...prev,
                        userName: e.target.value,
                      }));
                      setIsUserNameExists(false);
                      if (!isAdd) {
                        setIsUsernameChanged(true);
                      }
                    }}
                  />
                  {
                    isUserValid && user.userName == "" && (
                      // <div>
                      <p
                        style={{
                          color: "red",
                          fontSize: "13px",
                          fontWeight: "400",
                          margin: "4px 4px",
                        }}
                      >
                        Username is required
                      </p>
                    )
                    // </div>
                  }
                  {
                    isUserNameExists && user.userName !== "" && (
                      // <div>
                      <p
                        style={{
                          color: "red",
                          fontSize: "13px",
                          fontWeight: "400",
                          margin: "4px 4px",
                        }}
                      >
                        Username is already exists.
                      </p>
                    )
                    // </div>
                  }
                </div>
              </div>
              <div className="label grid">
                <div className="col" style={{ marginTop: "10px" }}>
                  Email<span style={{ color: "red" }}>*</span>
                </div>
                {isAdd ? (
                  <>
                    <div className="col" style={{ marginTop: "10px" }}>
                      Password<span style={{ color: "red" }}>*</span>
                    </div>
                    <div className="col" style={{ marginTop: "10px" }}>
                      Re-enter Password<span style={{ color: "red" }}>*</span>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="input grid">
                <div className="col">
                  <InputText
                    placeholder="Enter Email"
                    value={user.email}
                    onBlur={() => emailValidation}
                    onChange={(e) => {
                      setUser((prev) => ({ ...prev, email: e.target.value }));
                      setIsEmailExists(false);
                      if (!isAdd) {
                        setIsEmailChanged(true);
                      }
                    }}
                  />
                  {
                    isUserValid && user.email == "" && (
                      // <div>
                      <p
                        style={{
                          color: "red",
                          fontSize: "13px",
                          fontWeight: "400",
                          margin: "4px 4px",
                        }}
                      >
                        Email is required
                      </p>
                    )
                    // </div>
                  }
                  {
                    isEmailExists && user.email !== "" && (
                      // <div>
                      <p
                        style={{
                          color: "red",
                          fontSize: "13px",
                          fontWeight: "400",
                          margin: "4px 4px",
                        }}
                      >
                        Email is already exists.
                      </p>
                    )
                    // </div>
                  }
                  {
                    isEmailInvalid && user.email !== "" && (
                      // <div>
                      <p
                        style={{
                          color: "red",
                          fontSize: "13px",
                          fontWeight: "400",
                          margin: "4px 4px",
                        }}
                      >
                        Email is invalid.
                      </p>
                    )
                    // </div>
                  }
                </div>
                {isAdd ? (
                  <>
                    <div className="col-4">
                      <InputText
                        placeholder="Enter Password"
                        type="password"
                        value={user.password}
                        onChange={(e) => {
                          setUser((prev) => ({
                            ...prev,
                            password: e.target.value,
                          }));
                        }}
                      />
                      {
                        isUserValid && user.password == "" && (
                          // <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: "13px",
                              fontWeight: "400",
                              margin: "4px 4px",
                            }}
                          >
                            Password is required
                          </p>
                        )
                        // </div>
                      }
                    </div>
                    <div className="col-4">
                      <InputText
                        placeholder="Re-enter Password"
                        type="password"
                        value={user.reenterPassword}
                        onChange={(e) => {
                          setUser((prev) => ({
                            ...prev,
                            reenterPassword: e.target.value,
                          }));
                          setIsPasswordInvalid(false);
                        }}
                      />
                      {
                        isUserValid && user.reenterPassword == "" && (
                          // <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: "13px",
                              fontWeight: "400",
                              margin: "4px 4px",
                            }}
                          >
                            Re-enter password is required
                          </p>
                        )
                        // </div>
                      }
                      {
                        isPasswordInvalid && user.reenterPassword !== "" && (
                          // <div>
                          <p
                            style={{
                              color: "red",
                              fontSize: "13px",
                              fontWeight: "400",
                              margin: "4px 4px",
                            }}
                          >
                            Password and re-entered password should be same.
                          </p>
                        )
                        // </div>
                      }
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="label grid">
                <div className="col" style={{ marginTop: "10px" }}>
                  Mobile Number<span style={{ color: "red" }}>*</span>
                </div>
                <div className="col" style={{ marginTop: "10px" }}>
                  Nationality<span style={{ color: "red" }}>*</span>
                </div>
                <div className="col" style={{ marginTop: "10px" }}>
                  Role<span style={{ color: "red" }}>*</span>
                </div>
              </div>

              <div className="input grid">
                <div className="col-4 align-items-center" >
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Dropdown value={selectedCountry} className="mr-2" placeholder="Country code" onChange={(e) => { setSelectedCountry(e.value); handleCountryChange(e.value) }} options={nationality} optionLabel="english_name"
                      valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                    />
                    <InputText
                      style={{ width: "50%" }}
                      placeholder="Enter Mobile Number"
                      type="number"
                      value={user.mobileNo}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          mobileNo: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {
                      isUserValid && user.countryCode == null && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "13px",
                            fontWeight: "400",
                            margin: "4px 4px",
                          }}
                        >
                          Country code is required
                        </p>
                      )
                    }
                    {
                      isUserValid && user.mobileNo == "" && (
                        <p
                          style={{
                            color: "red",
                            fontSize: "13px",
                            fontWeight: "400",
                            margin: "4px 4px",
                          }}
                        >
                          Mobile number is required
                        </p>
                      )
                      // </div>
                    }
                  </div>
                </div>
                <div className="col-4">
                  <Dropdown
                    value={user.nationality}
                    style={{
                      border: "1px solid #AE9D81",
                      width: "75%",
                      height: "50px",
                      borderRadius: "5px",
                      boxShadow: "none",
                    }}
                    onChange={(e) => {
                      setUser((prev) => ({
                        ...prev,
                        nationality: e?.target?.value,
                      }));
                    }}
                    options={nationalityList}
                    optionLabel="english_name"
                    optionValue="english_name"
                    placeholder="Select Nationality"
                  />
                  {/* <InputText placeholder="Enter Nationality" value={user.nationality} onChange={(e) => { setUser((prev) => ({ ...prev, nationality: e.target.value })) }} /> */}

                  {
                    isUserValid && user.nationality == "" && (
                      // <div>
                      <p
                        style={{
                          color: "red",
                          fontSize: "13px",
                          fontWeight: "400",
                          margin: "4px 4px",
                        }}
                      >
                        Nationality is required
                      </p>
                    )
                    // </div>
                  }
                </div>
                <div className="col-4">
                  <MultiSelect
                    value={user.roles}
                    onChange={(e) => {
                      setUser((prev) => ({ ...prev, roles: e.value }));
                    }}
                    options={Context.roleList}
                    optionLabel="roleName"
                    display="chip"
                    placeholder="Select Roles"
                    style={{
                      border: "1px solid #AE9D81",
                      width: "75%",
                      height: "44px",
                      borderRadius: "5px",
                      boxShadow: "none",
                    }}
                  />

                  {isUserValid && user.roles.length == 0 && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "13px",
                        fontWeight: "400",
                        margin: "4px 4px",
                      }}
                    >
                      Role is required
                    </p>
                  )}
                </div>
              </div>
              <div className="label grid">
                <div className="col" style={{ marginTop: "10px" }}>
                  Organization<span style={{ color: "red" }}>*</span>
                </div>
                <div className="col" style={{ marginTop: "10px" }}>
                  Department
                  {/* <span style={{ color: 'red' }}>*</span> */}
                </div>
                <div className="col" style={{ marginTop: "10px" }}>
                  Status<span style={{ color: "red" }}>*</span>
                </div>
              </div>
              <div className="input grid">
                <div className="col-4">
                  <MultiSelect
                    value={user.organizations}
                    onChange={(e) => {
                      // //console.log(e.value)
                      setUser((prev) => ({ ...prev, organizations: e.value }));
                    }}
                    options={organizationList.filter(
                      (org) => org.isDeleted !== true
                    )}
                    optionLabel="name"
                    display="chip"
                    placeholder="Select Organizations"
                    style={{
                      border: "1px solid #AE9D81",
                      width: "75%",
                      height: "44px",
                      borderRadius: "5px",
                      boxShadow: "none",
                    }}
                  />

                  {
                    isUserValid && user.organizations.length == 0 && (
                      // <div>
                      <p
                        style={{
                          color: "red",
                          fontSize: "13px",
                          fontWeight: "400",
                          margin: "4px 4px",
                        }}
                      >
                        Organization is required
                      </p>
                    )
                    // </div>
                  }
                </div>
                <div className="col-4">
                  <MultiSelect
                    value={user.departments}
                    onChange={(e) => {
                      // //console.log(e.value)k
                      setUser((prev) => ({ ...prev, departments: e.value }));
                    }}
                    options={departmentList}
                    optionLabel="name"
                    display="chip"
                    placeholder="Select Departments"
                    style={{
                      border: "1px solid #AE9D81",
                      width: "75%",
                      height: "44px",
                      borderRadius: "5px",
                      boxShadow: "none",
                    }}
                  />
                  {/* || user.roles.filter((role) => role.roleLabel == "ADMIN").length != 0 */}

                  {isUserValid && user.departments.length == 0 && (
                    <>
                      <p
                        style={{
                          color: "red",
                          fontSize: "13px",
                          fontWeight: "400",
                          margin: "4px 4px",
                        }}
                      >
                        Department is required
                      </p>
                      {/* <p style={{ color: "red" }}>{user.roles.filter((role) => role.roleLabel == "ADMIN").length}</p> */}
                    </>
                  )}
                </div>
                <div className="col-4">
                  <label className="switch ">
                    <input
                      type="checkbox"
                      checked={user?.isDeleted}
                      onChange={(e) => {
                        setUser((prev) => ({
                          ...prev,
                          isDeleted: e.target.checked,
                        }));
                      }}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>{" "}
              </div>
            </div>
            <div className="footer flex  grid">
              <div className="col flex justify-content-center ">
                <Button
                  label="Cancel"
                  disabled={addUserLoading}
                  onClick={() => {
                    setIsAdd(false);
                    setIsAddUserPopup(false);
                    setIsEmailExists(false);
                    setIsUserNameExists(false);
                    setIsPasswordInvalid(false);
                    setIsUserValid(false);
                    setIsEmailChanged(false);
                    setIsUsernameChanged(false);
                    setUser((prev) => ({
                      ...prev,
                      firstName: "",
                      lastName: "",
                      userName: "",
                      password: "",
                      mobileNo: "",
                      nationality: "",
                      email: "",
                      isDeleted: true,
                      roles: [0],
                      organizations: [0],
                      departments: [0],
                    }));
                  }}
                  style={{
                    backgroundColor: "#8E8E8E",
                    border: "none",
                    margin: "10px",
                  }}
                />
                <Button
                  label={isAdd ? "Submit" : "Update"}
                  disabled={addUserLoading}
                  loading={addUserLoading}
                  onClick={() => {
                    isAdd ? addUser() : editUser();
                  }}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    margin: "10px",
                  }}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </>
    );
  };

  // const getNationalityList = async () => {
  //   await getNationality()
  //     .then((data) => {
  //       if (data?.status == 200) {
  //         setNationalityList(data?.nationalityList);
  //       }
  //     });
  // };
  const getUserList = (skip, take, searchValue, roleId = "", departmentId = "", orgId = "", status = "") => {
    setIsLoading(true);
    // if (!searchValue) {
    GetUserList(take, skip, cookies()?.bearerToken, searchValue, roleId, departmentId, orgId, status ? status == "In Active" ? "true" : "false" : "")
      .then((res) => {
        //console.log("User List", res.data.data);
        if (res.status === 200) {
          setUserList(res?.data?.data);
          setTotalRecords(res?.data?.count);
        }
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // } else {
    //   getUserListWithSearch(take, skip, cookies()?.bearerToken, searchValue)
    //     .then((res) => {
    //       //console.log("User List", res.data.data);
    //       if (res.status === 200) {
    //         setUserList(res?.data?.data);
    //         setTotalRecords(res?.data?.count);
    //       }
    //     })
    //     .catch((err) => {
    //       //console.log(err);
    //     })
    //     .finally(() => {
    //       setIsLoading(false);
    //     });
    // }
  };
  const getRoleList = () => {
    GetRolesList(cookies()?.bearerToken)
      .then((res) => {
        // //console.log(res);
        let filteredRoles =
          res?.data?.filter((_) => _?.isDeleted !== true) || [];
        dispatch(addRoleList({ roleList: filteredRoles }));
        setRoleList(filteredRoles);
      })
      .catch((error) => {
        console.warn(error);
      });
  };
  const getDepartmentList = () => {
    GetDepartmentList(cookies()?.bearerToken)
      .then((res) => {
        // //console.log(res);

        setDepartmentList(res.data?.filter((_) => _?.isDeleted !== true));
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const getOrganizationList = () => {
    GetOrganizationList(cookies()?.bearerToken)
      .then((res) => {
        // //console.log("Fetching Organization List");
        setOrganizationList(res?.data.data?.filter((_) => _?.isDeleted !== true));
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        // //console.log(organizationList);
      });
  };

  const addUser = async () => {
    if (
      user?.firstName == "" ||
      user?.lastName == "" ||
      user?.mobileNo == "" ||
      user?.nationality == "" ||
      user?.email == "" ||
      user?.password == "" ||
      user.reenterPassword == "" ||
      user?.countryCode == null
    ) {
      //console.log("Invalid Data", user);
      setIsUserValid(true);
      return;
    }
    setIsUserValid(false);
    let isemailValid = emailValidation();
    if (!isemailValid) {
      setIsEmailValid(true);
      return;
    }
    setIsEmailValid(false);
    let IsUserExist = await IsUserNameExist(user.userName, cookies()?.bearerToken)
      .then((res) => {
        //console.log("Is User Name Exist", res);
        if (res.status == 200) {
          if (res.data) {
            setIsUserNameExists(res?.data);
            return res?.data;
          }
        }
      })
      .catch((err) => {
        //console.log(err);
      });
    let IsUsernameExists = await IsEmailExist(user.email, cookies()?.bearerToken)
      .then((res) => {
        //console.log("Is Email Exist", res);
        if (res.status == 200) {
          setIsEmailExists(res?.data);
          return res?.data;
        }
      })
      .catch((err) => {
        //console.log(err);
      });
    if (IsUserExist || IsUsernameExists) {
      //console.log("Email and User Name Exists");
      return;
    }
    if (user.password !== user.reenterPassword) {
      //console.log("Password Mis Match", user.password !== user.reenterPassword);
      setIsPasswordInvalid(true);
      return;
    }
    setIsEmailExists(false);
    setIsUserNameExists(false);
    setIsPasswordInvalid(false);
    setAddUserLoading(true);
    let data = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      userName: user?.userName,
      mobileNo: user?.mobileNo,
      nationality: user?.nationality,
      countryCode: user?.countryCode || selectedCountry?.phone_code || "",
      email: user?.email,
      password: user?.password,
      isDeleted: !user?.isDeleted,
      roles: user?.roles.map((role) => {
        return role.id;
      }),
      organizations: user?.organizations.map((org) => {
        return org.id;
      }),
      departments: user?.departments.map((dep) => {
        return dep.id;
      }),
    };
    AddUser(data, cookies()?.bearerToken)
      .then((res) => {
        if (res.status === 200) {
          //console.log(res);
          setFirst(0);
          getUserList(0, 10, globalFilterValue, exportDetails?.role, exportDetails?.department, exportDetails?.organization, exportDetails?.status);
          setIsAddUserPopup(false);
          setIsAdd(false);
          setSelectedCountry({
            "english_name": "Bahrain",
            "arabic_name": "البحرين",
            "alpha2_code": "BH",
            "alpha3_code": "BHR",
            "phone_code": "+973",
            "flagImage": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
          });
          setUser({
            firstName: "",
            lastName: "",
            userName: "",
            password: "",
            mobileNo: "",
            nationality: "",
            email: "",
            isDeleted: true,
            roles: [0],
            organizations: [0],
            departments: [0],
            id: 0,
            countryCode: '+973'
          });
          toast.success("New User is added successfully", {
            position: "top-right",
          });
        }
      })
      .catch((err) => {
        //console.log(err);
        toast.warn("Error in adding user", { position: "top-right" });
      })
      .finally(() => {
        setIsAdd(false);
        setIsAddUserPopup(false);
        setIsEmailExists(false);
        setIsUserNameExists(false);
        setIsPasswordInvalid(false);
        setIsUserValid(false);
        setIsEmailChanged(false);
        setIsUsernameChanged(false);
        setAddUserLoading(false);
      });
  };

  //Edit Department
  const editUser = async () => {
    if (
      user?.firstName == "" ||
      user?.lastName == "" ||
      user?.mobileNo == "" ||
      user?.nationality == "" ||
      user?.email == "" ||
      user?.countryCode == null
    ) {
      setIsUserValid(true);
      return;
    }
    let emailExist = false;
    let usernameExists = false;
    setIsUserValid(false);
    if (prevUsername !== user.userName) {
      await IsUserNameExist(user.userName, cookies()?.bearerToken)
        .then((res) => {
          //console.log("Is User Name Exist", res.data);
          if (res.status == 200) {
            if (res.data) {
              setIsUserNameExists(true);
              emailExist = true;
            } else {
              setIsUserNameExists(false);
              emailExist = false;
            }
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
    setIsUsernameChanged(false);
    if (prevEmail !== user.email) {
      await IsEmailExist(user.email, cookies()?.bearerToken)
        .then((res) => {
          //console.log("Is Email Exist", res.data);
          if (res.status == 200) {
            setIsEmailExists(true);
            usernameExists = res.data;
            //console.log(res.data, typeof (res.data));
          }
        })
        .catch((err) => {
          //console.log(err);
        });
    }
    //console.log("Email exits", emailExist);
    //console.log("Username exists", usernameExists);
    if (emailExist || usernameExists) {
      toast.warn("Username or Email is already exists");
      return;
    }
    setIsUserNameExists(false);
    setIsEmailExists(false);
    setIsUserValid(false);
    let isemailValid = emailValidation();
    if (!isemailValid) {
      setIsEmailValid(true);
      return;
    }
    setIsEmailValid(false);
    setAddUserLoading(true);
    let data = {
      firstName: user?.firstName,
      lastName: user?.lastName,
      userName: user?.userName,
      mobileNo: user?.mobileNo,
      nationality: user?.nationality,
      password: "123",
      email: user?.email,
      isDeleted: !user?.isDeleted,
      roles: user?.roles.map((role) => {
        return role.id;
      }),
      organizations: user?.organizations.map((org) => {
        return org.id;
      }),
      departments: user?.departments.map((dep) => {
        return dep.id;
      }),
      id: user?.id,
      countryCode: user?.countryCode,
    };
    //console.log("While Edit User", user, data);
    EditUser(data, cookies()?.bearerToken)
      .then((res) => {
        setFirst(0);
        //console.log(res);
        getUserList(0, 10, globalFilterValue, exportDetails?.role, exportDetails?.department, exportDetails?.organization, exportDetails?.status);
        setIsAdd(false);
        setIsAddUserPopup(false);
        setUser({
          firstName: "",
          lastName: "",
          userName: "",
          mobileNo: "",
          nationality: "",
          email: "",
          isDeleted: true,
          roles: [0],
          organizations: [0],
          departments: [0],
          id: 0,
          countryCode: "+973"
        });
        toast.success("User is updated successfully", {
          position: "top-right",
        });
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        setIsAdd(false);
        setIsAddUserPopup(false);
        setIsEmailExists(false);
        setIsUserNameExists(false);
        setIsPasswordInvalid(false);
        setIsUserValid(false);
        setIsEmailChanged(false);
        setIsUsernameChanged(false);
        setPrevEmail("");
        setPrevUsername("");
        setAddUserLoading(false);
      });
  };

  const onGlobalFilterChange = (e) => {
    if (!e) {
      getUserList(0, 10, "", exportDetails?.role, exportDetails?.department, exportDetails?.organization, exportDetails?.status);
      return;
    }
    setGlobalFilterValue(e.target.value);
    getUserList(0, 10, e.target.value, exportDetails?.role, exportDetails?.department, exportDetails?.organization, exportDetails?.status);
  };

  const onPageChange = (e, index) => {
    setFirst(e.first);
    getUserList(e.first, 10, globalFilterValue, exportDetails?.role, exportDetails?.department, exportDetails?.organization, exportDetails?.status);
    //console.log(e);
  };

  const exportUserInfo = () => {
    if (globalFilterValue) {
      exportDataInExcel(
        `User?searchTerm=${globalFilterValue}`,
        cookies()?.bearerToken
      );
      return;
    }
    exportDataInExcel("User", cookies()?.bearerToken);
  };
  useEffect(() => {
    getUserList(0, 10);
    getRoleList();
    getDepartmentList();
    getOrganizationList();
    // getNationalityList();
  }, []);

  // Export Filter
  const [exportFilterDialog, setExportFilterDialog] = useState(false);
  const [exportDetails, setExportDetails] = useState({
    role: "",
    department: "",
    organization: "",
    status: "",
  });


  const onExportUserDetails = () => {
    // let urlRoute = `User?searchTerm=${globalFilterValue || ""}&roleId=${exportDetails?.role}&departmentId=${exportDetails?.department}&organizationId=${exportDetails?.organization}&status=${exportDetails?.status}`;
    // exportExcel(urlRoute);
    let urlRoute = `User/GetSystemUsers?skip=0&take=${totalCount}&searchTerm=${globalFilterValue}&roleId=${exportDetails?.role}&orgId=${exportDetails?.organization}&departmentId=${exportDetails?.department}${exportDetails?.status ? `&status=${exportDetails?.status == "In Active" ? "true" : "false"}&showAll=false` : ""}&forExport=true`;
    exportFilterdExcel(urlRoute, cookies()?.bearerToken);
  };


  return (
    <>
      <Dialog
        header={<h3>Export Filter</h3>}
        draggable={false}
        style={{ width: "50vw", height: "max-content" }}
        closable={true}
        onHide={() => setExportFilterDialog(false)}
        visible={exportFilterDialog}
      >
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
          <div style={{ width: "48%" }} className="mb-2">
            <label htmlFor="StartDate" className="font-bold block mb-2">
              Role
            </label>
            <Dropdown options={roleList} value={exportDetails?.role}
              onChange={(e) => setExportDetails((prev) => ({ ...prev, role: e.value }))}
              optionLabel="roleName" optionValue="id" placeholder="Select role" className="w-full" />
          </div>
          <div style={{ width: "48%" }} className="mb-2">
            <label htmlFor="StartDate" className="font-bold block mb-2">
              Organization
            </label>
            <Dropdown options={organizationList} value={exportDetails?.organization}
              onChange={(e) => setExportDetails((prev) => ({ ...prev, organization: e.value }))}
              optionLabel="name" optionValue="id" placeholder="Select organization" className="w-full" />
          </div>
          <div style={{ width: "48%" }}>
            <label htmlFor="StartDate" className="font-bold block mb-2">
              Department
            </label>
            <Dropdown options={departmentList} value={exportDetails?.department}
              onChange={(e) => setExportDetails((prev) => ({ ...prev, department: e.value }))}
              optionLabel="name" optionValue="id" placeholder="Select department" className="w-full" />
          </div>
          <div style={{ width: "40%" }}>
            <label htmlFor="Status" className="font-bold block mb-2">
              Status
            </label>
            <div className="input grid ">
              <div className="col ">
                <label className="switch ">
                  <input
                    type="checkbox"
                    value={exportDetails?.status}
                    checked={exportDetails?.status}
                    onChange={(e) =>
                      setExportDetails(() => ({
                        ...exportDetails,
                        status: e.target.checked,
                      }))
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Button
            label="Submit"
            onClick={() => {
              onExportUserDetails()
            }}
            style={{
              width: "120px",
              height: "40px",
              backgroundColor: " #ae9d81",
              color: "#fff",
              border: "none",
              marginRight: "0",
              cursor: "pointer",
              padding: "0.6rem",
            }}
          />
        </div>
      </Dialog>
      <div className="userManagementMaster">
        <div className="header">
          <div
            tyle={{
              display: "flex",
              flexDirection: "row",
              flexGrow: "3",
              justifyContent: "flex-start",
            }}
            className="flex align-items-center org"
          >
            {/* <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} /> */}
            <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>
            <div className="heading">
              <p>User Management</p>
            </div>
          </div>
          <div className="flex align-items-center">
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Roles</h4>
              <Dropdown style={{ border: "solid 1px #ae9d81", width: 150 }} options={roleList} value={exportDetails?.role}
                onChange={(e) => { setExportDetails((prev) => ({ ...prev, role: e.value })); getUserList(0, 10, globalFilterValue, e.value, exportDetails?.department, exportDetails?.organization, exportDetails?.status) }}
                optionLabel="roleName" optionValue="id" placeholder="Select" showClear={exportDetails?.role ? true : false} />
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Organization</h4>
              <Dropdown style={{ border: "solid 1px #ae9d81", width: 150 }} options={organizationList} value={exportDetails?.organization}
                onChange={(e) => { setExportDetails((prev) => ({ ...prev, organization: e.value })); getUserList(0, 10, globalFilterValue, exportDetails?.role, exportDetails?.department, e.value, exportDetails?.status) }}
                optionLabel="name" optionValue="id" placeholder="Select" showClear={exportDetails?.organization ? true : false} />
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Department</h4>
              <Dropdown style={{ border: "solid 1px #ae9d81", width: 150 }} options={departmentList} value={exportDetails?.department}
                onChange={(e) => { setExportDetails((prev) => ({ ...prev, department: e.value })); getUserList(0, 10, globalFilterValue, exportDetails?.role, e.value, exportDetails?.organization, exportDetails?.status) }}
                optionLabel="name" optionValue="id" placeholder="Select" showClear={exportDetails?.department ? true : false} />
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Status</h4>
              <Dropdown value={exportDetails?.status} options={[{ name: "Active" }, { name: "In Active" }]}
                optionLabel='name' optionValue='name'
                onChange={(e) => {
                  setExportDetails((prev) => ({ ...prev, status: e.value }));
                  getUserList(0, 10, globalFilterValue, exportDetails?.role, exportDetails?.department,
                    exportDetails?.organization, e.value)
                }}
                placeholder='Select' style={{ border: "solid 1px #ae9d81", width: 150 }}
                showClear={exportDetails?.status ? true : false} />
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
              <div className="search">
                <span className="p-input-icon-left">
                  <i
                    className="pi pi-search"
                    style={{ color: "black", fontWeight: "700", fontSize: "1rem" }}
                  />
                  <InputText
                    placeholder="Search"
                    value={globalFilterValue}
                    onChange={onGlobalFilterChange}
                    style={{ width: 160 }}
                  />
                  <i
                    className="pi pi-times"
                    style={{ color: "black", fontWeight: "400" }}
                    onClick={() => {
                      setGlobalFilterValue("");
                      onGlobalFilterChange(null);
                    }}
                  />
                </span>
              </div>
            </div>

            <div className="mx-2 align-self-end">
              <Button
                label="Add New "
                onClick={() => {
                  setIsUserValid(false);
                  setIsAddUserPopup(true);
                  setUser({
                    firstName: "",
                    lastName: "",
                    userName: "",
                    password: "",
                    reenterPassword: "",
                    mobileNo: "",
                    nationality: "",
                    email: "",
                    isDeleted: true,
                    roles: [],
                    organizations: [],
                    departments: [],
                    id: 0,
                  });
                  setIsAdd(true);
                  setSelectedCountry({
                    "english_name": "Bahrain",
                    "arabic_name": "البحرين",
                    "alpha2_code": "BH",
                    "alpha3_code": "BHR",
                    "phone_code": "+973",
                    "flagImage": "https://cdn.jsdelivr.net/npm/country-flag-emoji-json@2.0.0/dist/images/BH.svg"
                  })
                }}
                style={{ backgroundColor: "#ae9d81", border: "none" }}
              />
            </div>
            <div className="mx-2 align-self-end">
              <Button
                label="Export"
                onClick={() => totalCount > 0 ? onExportUserDetails() : null}
                icon={"pi pi-file-export"}
                style={{
                  backgroundColor: "transparent",
                  color: "#ae9d81",
                  border: "1px #ae9d81 solid",
                  borderRadius: "20px",
                  height: "46px",
                  cursor: totalCount > 0 ? "pointer" : "not-allowed",
                }}
              />
            </div>
          </div>

        </div>

        <div className="datatable">
          {isLoading ? (
            <div className="spinner spinner-center"></div>
          ) : (
            <DataTable
              value={userList}
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              tableStyle={{ width: "90rem" }}
              stripedRows
              rows={10}
              filters={filters}
              globalFilterFields={[
                "userName",
                "email",
                "mobileNo",
                "nationality",
                "organizations",
                "roles",
              ]}
              emptyMessage={
                <>
                  <p style={{ textAlign: "center" }}>List is empty</p>
                </>
              }
            >
              <Column field="firstName" header="Name"></Column>
              <Column field="userName" header="Username"></Column>
              <Column field="email" header="Email"></Column>
              <Column field="mobileNo" header="Mobile Number"></Column>
              <Column field="nationality" header="Nationality"></Column>
              <Column
                field="roles"
                header="Role"
                body={rowBodyTemplate}
              ></Column>
              <Column field="organizations" header="Organization"></Column>
              <Column
                field="id"
                header="Status"
                body={statusBodyTemplate}
              ></Column>
              <Column
                rowEditor
                header="Action"
                body={actionButtonTemplate}
              ></Column>
            </DataTable>
          )}
        </div>
        {isLoading ? (
          ""
        ) : (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Paginator
              first={first}
              rows={10}
              totalRecords={totalCount}
              onPageChange={(e) => onPageChange(e, 1)}
              template={{
                layout:
                  "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
              }}
              className="justify-content-start"
            />
          </div>
        )}
      </div>

      <div>{isAddUserPopup == true ? popup() : ""}</div>
    </>
  );
}

export default UserManagement;
