import Axios from '../Helpers/Axios';

export function GetRolesList(token, organization = 0) {
    return Axios(`Role/GetRoles?organizationId=${organization}`, 'get', null, token)
}
export function AddRole(data, token) {
    return Axios('Role/AddRole', 'post', data, token)
}
export function EditRole(data, token) {
    return Axios('Role/EditRoleStatus', 'patch', data, token)
}