import UseCookies from '../../Helpers/UseCookies';
import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import './style.scss';
import { Button } from 'primereact/button';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AddReturnFormForm, GetLostItemList, GetLostItemListForDropdown, GetReturnFrom, ReturnFormSubmmision } from '../../Services/LostandFoundService';
import { InputTextarea } from 'primereact/inputtextarea';
import CustomWebcam from '../Camera/Camera';
import { BulkUploadBase64Files, getAttachmentFile } from '../../Services/Files';
import { toast } from 'react-toastify';
import IntlTelInput from 'react-intl-tel-input';
import { AutoComplete } from 'primereact/autocomplete';
import { Dialog } from 'primereact/dialog';
import { FileUpload } from 'primereact/fileupload';
import Modalinfo from '../utili/Modalinfo';
import { useReactToPrint } from 'react-to-print';
import { CiImageOn } from 'react-icons/ci';



function ReturnForm() {
    const { id, route } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const fileInputRef = useRef(null);
    const inputRef = useRef(null);
    const localizationValue = "en";
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [camerActive, setCamerActive] = useState(false);
    const [returnFormdata, setReturnFormdata] = useState({
        "firstName": "",
        "lastName": "",
        "email": "",
        "phoneNumber": "",
        "witnessPersonName": "",
        "comment": "",
        "foundId": 0,
        "status": "",
        "lostId": "",
        "attachment": []
    });
    const [isInvalid, setIsInvalid] = useState(false);
    const [isReturned, setIsReturned] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const [filename, setFilename] = useState("");
    const [lostList, setLostList] = useState([]);
    const [totalrecords, setTotalrecords] = useState(10);

    // File preview
    const [previewLoader, setPreviewLoader] = useState({
        id: "",
        isLoad: false,
    });
    const [previewAttachment, setPreviewAttachment] = useState({
        isOpen: false,
        attachmentUrl: "",
    });


    // Deepak-Print
    const contentToPrint = useRef(null);
    const [isPrint, setIsPrint] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isInvalidForm, setIsInvalidForm] = useState(false);
    // ----------------------

    // Mobile number with conutry code - Deepak
    const getMobileNumber = () => {
        let mobileNumber = inputRef?.current?.selectedCountryData?.dialCode + inputRef?.current?.state?.value
        assignValue("phoneNumber", "+" + mobileNumber)
        return mobileNumber;
    };
    const assignValue = (key, value) => {
        setReturnFormdata((prevState) => {
            return {
                ...prevState,
                [key]: value
            };
        });
    };
    const getSelectedCountryName = () => {
        let selectedCountry = "bh";
        if (inputRef?.current?.countries && returnFormdata?.countryCode) {
            selectedCountry = [...inputRef?.current?.countries]?.find(
                (val) => val.dialCode === returnFormdata?.countryCode
            )?.iso2;
        }
        return selectedCountry ? selectedCountry : "bh";
    };
    const handlePhoneChange = (value, countryData) => {
        console.log("countryData", countryData);
        if (
            value?.length <= 12 &&
            /^[0-9]+$|^$/.test(value) &&
            value !== returnFormdata?.phoneNumber
        ) {
            setReturnFormdata({
                ...returnFormdata,
                phoneNumber: value,
                countryCode: countryData?.dialCode,
            });
        }
        // setPhoneNumber(value);
    };
    const handleInputValue = (key, value) => {
        setReturnFormdata({ ...returnFormdata, [key]: value });
    };
    // ------------------------------   
    const handleSubmit = async () => {
        console.log(returnFormdata);
        if (returnFormdata?.firstName == "" ||
            returnFormdata?.lastName == "" ||
            // returnFormdata?.email == "" ||
            returnFormdata?.phoneNumber == "" ||
            returnFormdata?.countryCode == "" ||
            returnFormdata?.witnessPersonName == "" ||
            returnFormdata?.comment == "" ||
            returnFormdata?.lostId == "" ||
            returnFormdata?.attachment?.length == 0
        ) {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
        setIsSubmitted(true);
        let data = {
            "firstName": returnFormdata?.firstName,
            "lastName": returnFormdata?.lastName,
            "email": returnFormdata?.email,
            "phoneNumber": returnFormdata?.phoneNumber,
            "countryCode": returnFormdata?.countryCode,
            "witnessPersonName": returnFormdata?.witnessPersonName,
            "comment": returnFormdata?.comment,
            "foundId": id,
            "status": "",
            "lostId": returnFormdata?.lostId
        }
        //console.log(data);
        await AddReturnFormForm(data, cookies()?.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log(res);
                    AddAttachment(res?.data);
                    setIsPrint(true);
                    // setReturnFormdata((prev) => ({ ...prev, returnId: res?.data }))
                    toast.success("Return form submitted successfully", { position: "top-right" });
                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                handlePrint();
                setIsSubmitted(false);
                // if (route === "g4") { navigate('/lostandfound/4') }
                // if (route == "s3") { navigate('/security-staff/3') }
            })
    };

    const imageToBase64 = async (files) => {
        let imageList = [];
        if (files?.length > 0) {
            for (const image of files) {
                const imageData = await new Promise((resolve) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(image);
                    reader.onload = () => {
                        resolve(reader.result);
                    };
                });
                console.log(image);
                imageList.push({
                    fileName: image?.name,
                    base64: imageData.split(";base64,")[1]
                });
                files.pop();

            }
        }
        console.log(imageList);
        return imageList;
    }
    const uploadAttachments = async (returnId) => {
        let data = {
            "id": returnId,
            "module": "Return",
            "inspectionId": 0,
            "files": returnFormdata?.attachment
        }

        //console.log("Data", data);
        await BulkUploadBase64Files(data, cookies()?.bearerToken)
            .then((res) => {
                if (res.status == 200) {
                    //console.log(res);
                    // toast.success("Attachments uploaded successfully", { position: "top-right" });
                }
            })
            .catch((err) => {
                //console.log(err);
            })


    };





    const returnFormSubmission = async (id) => {
        if (returnFormdata?.attachment?.length == 0) {
            setIsInvalidForm(true);
            return;
        }
        await ReturnFormSubmmision(id, cookies()?.bearerToken)
            .then((res) => {
                toast.success("Return form completed successfully", { position: "top-right" });
                uploadAttachments(res?.data)
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                if (route === "g4") { navigate('/lostandfound/4') }
                if (route == "s3") { navigate('/security-staff/3') }
            });
    };

    const HandleSubmit = async () => {
        if (searchParams.get("isReturned"))
            setIsPrint(true);
        else {
            handleSubmit();
        }
    };
    const onUpload = async (e) => {
        if (e.files.length == 0) {
            toast.error("Please select a file to upload", { position: "top-right" });
            return
        }
        if (e.files.length > 0) {
            let imageDate = await imageToBase64(e.files);
            setReturnFormdata((prev) => ({ ...prev, attachment: [...prev?.attachment, ...imageDate] }))
            e.files.pop();
        }
    };

    const AddAttachment = async (returnId) => {
        let fileNameExtension = imgSrc.split(";base64,")[0].split("/")[1];
        let fileModule = "Found";
        let filedate = new Date().getFullYear() + new Date().getMonth() + new Date().getDate();
        let fileName = fileModule + filedate + "." + fileNameExtension;
        let data = {
            "id": returnId,
            "module": "Return",
            "inspectionId": 0,
            "files": returnFormdata?.attachment
        }
        //console.log("Data", data);
        await BulkUploadBase64Files(data, cookies()?.bearerToken)
            .then((res) => {
                if (res.status == 200) {
                    //console.log(res);
                    // toast.success("Attachments uploaded successfully", { position: "top-right" });
                }
            })
            .catch((err) => {
                //console.log(err);
            })
    };


    const getAllLostItems = async (skip, take = 10, searchTerm = "") => {
        await GetLostItemListForDropdown(skip, take, searchTerm, cookies()?.bearerToken,)
            .then((res) => {
                if (res.status === 200) {
                    setLostList(res?.data?.data);
                    setTotalrecords(res?.data?.count);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
    };

    const getReturnFrom = async (id) => {
        await GetReturnFrom(id, cookies()?.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log(res);
                    setReturnFormdata(res?.data);
                    setReturnFormdata((prev) => ({ ...prev, attachment: res?.data?.files }));
                }
            })
            .catch((err) => {
                //console.log(err);
            })
    };




    // Deepak-Print
    const handlePrint = useReactToPrint({
        documentTitle: "Print This Document",
        onBeforePrint: () => console.log("before printing..."),
        onAfterPrint: () => {
            // setPrintDialog(false);
        },
        removeAfterPrint: true,
    });

    const searchItems = (e) => {
        getAllLostItems(0, 10, e?.query);
    };

    useEffect(() => {
        getAllLostItems(0, 10);
    }, [])


    useEffect(() => {
        if (searchParams.get("isReturned")) {
            getReturnFrom(id);
            setIsReturned(true);
        }
    }, [searchParams.get("isReturned")])

    const ConvertAndAddToImageList = (image) => {
        let base64 = image.split(";base64,")[1];
        let fileNameExtension = image.split(";base64,")[0].split("/")[1];
        let fileModule = "Found";
        let filedate = new Date().getFullYear().toString() + new Date().getMonth().toString() + new Date().getDate().toString();
        let fileName = fileModule + filedate + "." + fileNameExtension;
        let imageData =
        {
            "fileName": fileName,
            "base64": base64
        };
        setReturnFormdata((prev) => ({ ...prev, attachment: [...prev.attachment, imageData] }));
        setImgSrc("");

    };
    const previewImage = (url) => {
        console.log(url);
        setPreviewLoader({ id: url.moduleId, isLoad: true });
        let data = [
            {
                fileName: url.fileName,
                azureBlobUrl: url.blobUrl.replaceAll("\\", "/"),
            },
        ]
        getAttachmentFile(data, cookies()?.bearerToken)
            .then((res) => {
                console.log(res);
                setPreviewAttachment({
                    isOpen: true,
                    attachmentUrl: res?.data?.[0].base64,
                });
                setPreviewLoader({ id: "", isLoad: false });
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (imgSrc) {
            ConvertAndAddToImageList(imgSrc);
        }
    }, [imgSrc])

    return (
        <div className="ReturnForm">
            {camerActive && <CustomWebcam setIsVisible={setCamerActive} image={imgSrc} setImage={setImgSrc} setFilename={setFilename} />}
            {isPrint &&
                <Dialog header={<h4 style={{ display: "flex", justifyContent: "center" }}>Return From Submission</h4>} visible={isPrint} style={{ width: "50vw", height: "50vh" }} onHide={() => setIsPrint(false)}>
                    <div className="print">
                        <div ref={contentToPrint} >
                            <div style={{ width: '100%', margin: '25px', justifyContent: "center", alignItems: "center" }} >
                                <img src="https://marassigalleria.bh/wp-content/uploads/2023/05/logomarassi.svg" style={{ textAlign: "center" }} />
                                <div class="container">
                                    <div class="letter-header">
                                        <p><strong>Mall Name</strong></p>
                                        <p><strong> Mall Address</strong></p>
                                        <p><strong>Date: [Insert Date]</strong></p>
                                    </div>

                                    <div class="letter-body">
                                        <h2>Acknowledgment of Returned Lost Item</h2>
                                        <p>I, <strong>[Customer Name]</strong>, confirm that I have received the following lost item,<br></br> which was found by the security team of <strong>[Mall Name]</strong>:</p>

                                        <p><strong>Item Description:</strong> [Description of the item]</p>
                                    </div>

                                    <div class="signature">
                                        <label class="signature-line"><span>Signature:</span> _________________________</label><br></br>
                                        <label class="signature-line"><span>Date:</span> _____________________________</label><br></br>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="popup " >
                        <div className="body">
                            <div className="col">
                                <div className="label grid">
                                    <div className='col'>
                                        Print Return Form {" "}
                                        <i className="pi pi-download" style={{ color: 'slateblue', marginLeft: "7px", cursor: "pointer" }}
                                            // on click i need to download a file 
                                            onClick={() => {
                                                handlePrint(null, () => contentToPrint.current);
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="label grid">
                                    <div className='col' style={{ display: "flex", alignItems: "center" }}>Upload Form<span style={{ color: 'red' }}>*</span> <Modalinfo /></div>
                                </div>
                                <div className="input grid ">
                                    <div className="col">
                                        <FileUpload name="demo[]" accept="image/*" mode='basic' multiple={false} maxFileSize={1000000} customUpload uploadHandler={onUpload} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                                        {isInvalidForm &&
                                            <div>
                                                <p style={{ color: "red" }}>Signed return form is mandatory</p>
                                            </div>}
                                    </div>
                                </div>
                                <div className="col flex justify-content-center">
                                    <Button
                                        label={"Cancel"}
                                        style={{
                                            backgroundColor: "#ae9d81",
                                            border: "none",
                                            borderRadius: "3px",
                                            margin: "10px"
                                        }}
                                        onClick={() => {
                                            setIsPrint(false);
                                        }} />
                                    <Button
                                        label={"Submit"}
                                        style={{
                                            backgroundColor: "#ae9d81",
                                            border: "none",
                                            borderRadius: "3px",
                                            margin: "10px"
                                        }}
                                        onClick={() => {
                                            returnFormSubmission(returnFormdata?.foundId);
                                        }} />
                                </div>
                            </div>
                        </div>
                    </div>

                </Dialog>
            }
            <div className="header" style={{ backgroundColor: '#ae9d81', color: 'white' }}>
                <i className="pi pi-arrow-left" style={{ cursor: "pointer" }}
                    onClick={() => {
                        if (route === "g4") {
                            navigate('/lostandfound/4');
                        }
                        if (route == "s3") {
                            navigate('/security-staff/3');
                        }
                    }}></i>
                <div style={{ margin: 'auto', fontWeight: '600', fontSize: '18px' }}>Return Form</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 50px' }}>
                <div>
                    <p>First Name<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Enter First Name' style={{ width: '350px' }} disabled={isReturned}
                            value={returnFormdata?.firstName} onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, firstName: e.target.value })
                            }} />
                    </div>
                    {isInvalid && returnFormdata?.firstName == "" &&
                        <p style={{ color: "red" }}>First name  is required</p>
                    }
                </div>
                <div>
                    <p>Last Name<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Enter Last Name' style={{ width: '350px' }} disabled={isReturned}
                            value={returnFormdata?.lastName} onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, lastName: e.target.value })
                            }}
                        />
                    </div>
                    {isInvalid && returnFormdata?.lastName == "" &&
                        <p style={{ color: "red" }}>Last name  is required</p>
                    }
                </div>
                <div>
                    <p>Email</p>
                    <div>
                        <InputText placeholder='Enter Email' style={{ width: '350px' }} disabled={isReturned}
                            value={returnFormdata?.email} onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, email: e.target.value })
                            }}
                        />
                    </div>
                    {/* {isInvalid && returnFormdata?.email == "" &&
                        <p style={{ color: "red" }}>Email  is required</p>
                    } */}
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div>
                    <p>Mobile Number<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        {/* <InputText placeholder='Enter Mobile Number' type='number' style={{ width: '350px' }}
                            value={returnFormdata?.phoneNumber}
                            onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, phoneNumber: e.target.value })
                            }}
                        /> */}
                        <IntlTelInput
                            // className='mg_care__input'
                            style={{ width: "350px", }}
                            value={returnFormdata?.phoneNumber}
                            containerClassName="intl-tel-input phoneInputContainerStyle"
                            noCountryDataHandler={(e) => console.log(e)}
                            inputClassName={localizationValue == "en" ? "phoneInputStyle" : "phoneInputStyle directionRtl"}
                            // onSelectFlag={(value, countryData) => {
                            //     setReturnFormdata({ ...returnFormdata, countryCode: countryData?.dialCode })
                            // }}
                            onPhoneNumberChange={(
                                valid,
                                value,
                                countryData,
                                number,
                                id
                            ) => {
                                handlePhoneChange(value, countryData);
                            }}
                            defaultCountry={getSelectedCountryName()}
                            preferredCountries={["bh", "ae"]}
                            ref={inputRef}
                            disabled={isReturned}
                        />


                    </div>
                    {isInvalid && returnFormdata?.phoneNumber == "" &&
                        <p style={{ color: "red" }}>Mobile number  is required</p>
                    }
                </div>
                <div>
                    <p>Returned Item<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText disabled placeholder='Found Id' style={{ width: '350px', borderColor: '#ae9d81' }} value={id} />
                    </div>
                    {isInvalid && id == "" &&
                        <p style={{ color: "red" }}>Found Id  is required</p>
                    }
                </div>
                <div>
                    <p>Lost Item Id</p>
                    <div style={{ width: '350px' }}>
                        {/* <Dropdown options={lostList} placeholder='Select Status' style={{ width: '350px', borderColor: '#ae9d81' }}
                            value={returnFormdata?.status}
                            onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, status: e.value })
                            }}
                        /> */}
                        <AutoComplete
                            placeholder="Select here"
                            style={{ width: '350px', borderColor: '#ae9d81' }}
                            value={returnFormdata?.lostId}
                            suggestions={lostList}
                            completeMethod={(e) => {
                                setReturnFormdata((prev) => ({ ...prev, lostId: e?.query }))
                                searchItems(e)
                            }}
                            virtualScrollerOptions={{ itemSize: 38 }}
                            field="lostCode"
                            dropdown
                            onChange={(e) => {
                                // searchItems(e?.value);
                                setReturnFormdata({ ...returnFormdata, lostId: e?.value?.id })
                            }}
                            disabled={isReturned}
                        ></AutoComplete>
                        {isInvalid && returnFormdata?.lostId == "" &&
                            <p style={{ color: "red" }}>Lost Id  is required</p>
                        }
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                        <p> Person image<span style={{ color: 'red' }}>*</span></p>
                        {!searchParams.get("isReturned") &&
                            <>
                                <FileUpload mode='basic' accept="image/*" className='fileUpload' maxFileSize={1000000} customUpload uploadHandler={onUpload} ref={fileInputRef}
                                    headerTemplate={<i className='pi pi-plus' style={{ color: "#ae9d81", margin: '0px 5px', width: "1.5rem", height: "1.4rem" }} ></i>} />
                                <i className='pi pi-camera' style={{ float: 'right', margin: '10px', color: "#ae9d81", cursor: "pointer" }} onClick={() => { setCamerActive(true); }}></i>
                            </>
                        }

                    </div>

                    <div>
                        <div>
                            <div className="calendar" style={{ width: '350px', height: "150px", border: 'solid 1px #ae9d81', borderRadius: "5px" }}>
                                {/* {imgSrc == "" ? "" : <img src={imgSrc} style={{ width: "50%", padding: "15px" }} />} */}
                                {
                                    returnFormdata?.attachment?.length > 0 ?
                                        <>
                                            {returnFormdata?.attachment?.map((image, index) => {
                                                return (
                                                    <div style={{ display: "flex", margin: "5px", height: "15px" }}>
                                                        <p style={{ fontWeight: 400, margin: "0px" }}>{index + 1}.  </p>
                                                        <p style={{ fontWeight: 400, margin: "0px", textOverflow: "ellipsis", whiteSpace: "nowrap" }} > {image?.fileName}</p>
                                                        {!isReturned && <i className="pi pi-times" style={{ cursor: 'pointer', color: '#ae9d81', margin: 0, padding: 0, fontSize: '1rem', display: 'flex', justifyContent: "center", alignItems: 'center' }}
                                                            onClick={() => {
                                                                setReturnFormdata((prev) => ({
                                                                    ...prev,
                                                                    attachment: prev?.attachment?.filter((item, i) => i !== index)
                                                                }))
                                                            }} />}
                                                    </div>)
                                            })}
                                        </> : ""
                                }
                            </div>
                        </div>
                        {isInvalid && returnFormdata?.attachment?.length == 0 &&
                            <p style={{ color: "red" }}>Person image  is required</p>
                        }

                    </div>
                </div>
                <div>
                    <p>Witness Person Name<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputText placeholder='Witness Person Name' style={{ width: '350px', borderColor: '#ae9d81' }}
                            value={returnFormdata?.witnessPersonName} onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, witnessPersonName: e.target.value })
                            }} disabled={isReturned}
                        />
                    </div>
                    {isInvalid && returnFormdata?.witnessPersonName == "" &&
                        <p style={{ color: "red" }}>Witness Person Name  is required</p>
                    }
                </div>
                <div >
                    <p>Comment<span style={{ color: 'red' }}>*</span></p>
                    <div>
                        <InputTextarea style={{ width: '350px', height: '150px', borderColor: "#ae9d81" }} value={returnFormdata?.comment}
                            onChange={(e) => {
                                setReturnFormdata({ ...returnFormdata, comment: e.target.value })
                            }} disabled={isReturned}
                        />
                    </div>
                    {isInvalid && returnFormdata?.comment == "" &&
                        <p style={{ color: "red" }}>Comment  is required</p>
                    }
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'end', gap: '20px', margin: "10px 50px 50px 0px" }}>
                <Button label='Cancel' className='cnl-btn' disabled={isSubmitted} onClick={() => { if (route === "g4") { navigate('/lostandfound/4') } if (route == "s3") { navigate('/security-staff/3') } }} />
                <Button label={searchParams.get("isReturned") ? "Print Form" : 'Submit'} loading={isSubmitted} className='smt-btn' onClick={HandleSubmit} />

            </div>

        </div >
    )
}

export default ReturnForm