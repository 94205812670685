import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import editIcon from '../../../src/assets/images/editIcon.jpg';
import { AddOrEditCategory, GetAllCategory } from "../../Services/LostAndFoundCategoryService";
import React, { useEffect, useState } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import { FilterMatchMode } from 'primereact/api';
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { exportExcel, exportFilterdExcel } from "../../Services/ExportServices";





function LostAndFoundCategory() {
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [categoryList, setCategoryList] = useState([]);
    const [isAddCategoryPopup, setIsAddCategoryPopup] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [isCategoryInvalid, setIsCategoryInvalid] = useState(false);
    const [isCategoryInvalidAR, setIsCategoryInvalidAR] = useState(false);
    const [categoryNameExists, setCategoryNameExists] = useState(false);
    const [categoryNameExistsAR, setCategoryNameExistsAR] = useState(false);
    const [isAddcategory, setIsAddCategory] = useState({
        "name": "", "nameInArabic": "", "isDeleted": false, id: 0,
    })
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalRecords] = useState(0)
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });



    const onGlobalFilterChange = (e) => {
        const value = e ? e.target.value : "";
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const statusBodyTemplate = ((row) => {
        // //console.log(row);
        if (row.isDeleted === false) {
            return <p style={{ color: "green" }}  >Active</p>
        }
        else {
            return <p style={{ color: "red" }} >Inactive</p>
        }
    });

    // const exportCategory = () => {
    //     exportDataInExcel('Organization', cookies()?.bearerToken);
    // }


    const actionBodyTemplate = (row) => {
        return (
            <div onClick={(e) => {
                setIsAdd(false);
                setIsAddCategoryPopup(true);
                setIsAddCategory({
                    "name": row.name,
                    "nameInArabic": row.nameInArabic,
                    "isDeleted": row.isDeleted,
                    "id": row.id

                })
                // //console.log(organization);
            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };

    const getCategoryList = (() => {
        setIsLoading(true);
        GetAllCategory(cookies()?.bearerToken)
            .then((res) => {
                setCategoryList(res?.data);
                // setTotalRecords(res?.data?.count);
            })
            .catch((error) => {
                console.warn(error);
            })
            .finally(() => {
                setIsLoading(false);
                // //console.log(organizationList);
            })
    });
    // const exportRoleInfo = () => {
    //     exportDataInExcel("Role", cookies()?.bearerToken);
    // }


    const addCategory = (() => {

        let data = {
            "name": isAddcategory?.name,
            "nameInArabic": isAddcategory?.nameInArabic,
            "isDeleted": isAddcategory?.isDeleted,

        };
        //console.log("Add L&D category", data);

        let filteredCategoryData = categoryList.filter((item) => {
            return item.name === data.name;
        });
        if (isAddcategory?.name === "") {
            setIsCategoryInvalid(true);
            return;
        }
        setIsCategoryInvalid(false);

        if (filteredCategoryData.length > 0) {
            setCategoryNameExists(true);
            return;
        }
        setCategoryNameExists(false);

        let filteredCategoryDataAR = categoryList.filter((item) => {
            return item.nameInArabic === data.nameInArabic;
        });
        if (isAddcategory?.nameInArabic === "") {
            setIsCategoryInvalidAR(true);
            return;
        }
        setIsCategoryInvalidAR(false);

        if (filteredCategoryDataAR.length > 0) {
            setCategoryNameExistsAR(true);
            return;
        }
        setCategoryNameExistsAR(false);



        let token = cookies()?.bearerToken
        AddOrEditCategory(data, token)
            .then((res) => {
                if (res?.status == 200) {
                    getCategoryList();
                    setIsAddCategoryPopup(false)
                    setIsAddCategory({
                        "name": "",
                        "nameInArabic": "",
                        "isDeleted": false,
                        id: 0,
                    });
                    setIsCategoryInvalid(false);
                    setIsCategoryInvalidAR(false);
                    setIsAdd(false);
                    toast.success("New Lost and Found Category is added Successfully", { position: "top-right" });
                } else {
                    toast.info("Something went wrong, While adding category", { position: "top-right" });
                }
            })
            .catch((error) => {
                //console.log(error);
            })
    })


    const editCategory = (() => {
        if (isAddcategory?.name == "") {
            setIsCategoryInvalid(true);
            return;
        }
        let data = {
            "id": isAddcategory?.id,
            "name": isAddcategory?.name,
            "nameInArabic": isAddcategory?.nameInArabic,
            "isDeleted": isAddcategory?.isDeleted,
        };
        let filteredData = categoryList.filter((item) => {
            return item.name === data.name && item.id !== isAddcategory?.id;
        })
        if (filteredData.length > 0) {
            setCategoryNameExists(true);
            return;
        }
        setCategoryNameExists(false);
        if (isAddcategory?.name === "") {
            setIsCategoryInvalid(true);
            return;
        }
        setIsCategoryInvalid(false);
        if (isAddcategory?.nameInArabic === "") {
            setIsCategoryInvalidAR(true);
            return;
        }
        setIsCategoryInvalidAR(false);
        let filteredCategoryDataAR = categoryList.filter((item) => {
            return item.nameInArabic === data.nameInArabic && item.id !== isAddcategory?.id;;
        });
        if (filteredCategoryDataAR.length > 0) {
            setCategoryNameExistsAR(true);
            return;
        }
        setCategoryNameExistsAR(false);
        AddOrEditCategory(data, cookies()?.bearerToken)
            .then(res => {
                //console.log(res);
                if (res?.status == 200) {
                    getCategoryList();
                    setIsAddCategoryPopup(false);
                    setIsAddCategory({
                        "name": "",
                        "nameInArabic": "",
                        "isDeleted": false,
                        "id": 0
                    })
                    toast.success("Lost and Found Category is updated successfully", { position: "top-right" });
                } else {
                    toast.info("Something went wrong, Wihle editing category", { position: "top-right" });
                }
            })
            .catch(err => {
                //console.log(err);
            })
    });

    const back = () => {
        navigate('/dashboard');
    };

    const onExportStorageLocation = () => {
        let urlRoute = `LostAndFoundCategory`;
        exportExcel(urlRoute);
    }

    useEffect(() => {
        getCategoryList();
    }, [])

    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>{isAdd ? "Add" : "Edit"} Lost and Found Category Details</p>
        </div>
    }
    const popup = (() => {
        return (
            <>
                <Dialog header={header} visible={true} style={{ width: '30vw' }}
                    closable={false} onHide={() => {
                        setIsAddCategoryPopup(false);
                        setIsCategoryInvalidAR(false);
                        setIsAdd(false);
                        setIsAddCategory({
                            "name": "",
                            "nameInArabic": "",
                            "isDeleted": false
                        })
                    }}>
                    <div className="popup " >
                        {/* <div className="heading">
                            <p>Add New Details</p>
                        </div> */}
                        <div className="body"  >
                            <div className="label grid" >
                                <div className='col' >Category Name<span style={{ color: 'red' }}>*</span></div>
                                <div className='col' style={{ position: 'absolute', left: '240px' }}>Status</div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    <InputText placeholder="Enter Category Name" value={isAddcategory?.name} onChange={(e) => { setIsAddCategory((prev) => ({ ...prev, name: e.target.value })) }} />
                                    {isCategoryInvalid && isAddcategory?.name == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Category name is required</p>
                                        // </div>
                                    }
                                    {categoryNameExists &&
                                        // <div>
                                        <p style={{ color: "red" }}>Category name is already exists</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col ">
                                    <label className="switch ">
                                        <input type="checkbox" checked={!isAddcategory?.isDeleted} onChange={(e) => { setIsAddCategory((prev) => ({ ...prev, isDeleted: !e.target.checked })); }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                            </div>
                            <div>
                                <div className='col' >Category Name AR<span style={{ color: 'red' }}>*</span></div>
                                <InputText placeholder="Enter Category Name AR" style={{ direction: 'rtl' }} value={isAddcategory?.nameInArabic} onChange={(e) => { setIsAddCategory((prev) => ({ ...prev, nameInArabic: e.target.value })) }} />
                                {isCategoryInvalidAR && isAddcategory?.nameInArabic == "" &&
                                    // <div>
                                    <p style={{ color: "red" }}>Category name AR is required</p>
                                    // </div>
                                }
                                {categoryNameExistsAR &&
                                    // <div>
                                    <p style={{ color: "red" }}>Category name AR is already exists</p>
                                    // </div>
                                }
                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel"
                                    onClick={() => {
                                        setIsAddCategoryPopup(false);
                                        setCategoryNameExists(false);
                                        setIsCategoryInvalid(false);
                                        setCategoryNameExistsAR(false);
                                        setIsAdd(false);
                                        setIsAddCategory({
                                            "name": "",
                                            "nameInArabic": "",
                                            "isDeleted": false
                                        });
                                    }}
                                    style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "Submit" : "Update"} onClick={() => { isAdd ? addCategory() : editCategory() }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog>

            </>
        )
    })
    // Export Filter
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [exportDetails, setExportDetails] = useState({
        startDate: "",
        endDate: "",
        organization: "",
        desk: "",
        status: true
    });

    const onexportLostAndFoundCategory = () => {
        let urlRoute = `LostAndFound/Category/GetAll?searchTerm=${globalFilterValue}&forExport=true`;
        exportFilterdExcel(urlRoute, cookies()?.bearerToken);
    }
    return (
        <>
            <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                        </label>
                        <Calendar
                            id="StartDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.startDate}
                            placeholder="Choose start end"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.endDate}
                            placeholder="Choose end date"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <InputText
                            id="organization"
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                            placeholder="Enter organization" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="Status" className="font-bold block mb-2">Status
                        </label>
                        {/* <InputText
                        id="Status"
                        style={{ width: "95%" }}
                        value={exportDetails?.status}

                        placeholder="Enter organization" ></InputText> */}
                        <div className="input grid ">
                            <div className="col ">
                                <label className="switch ">
                                    <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                    }}
                >
                    <Button
                        label="Submit"
                        onClick={''}
                        // icon={"pi pi-file-export"}
                        style={{
                            backgroundColor: " #ae9d81",
                            color: "#fff",
                            border: "none",
                            // borderRadius: "20px",
                            marginRight: "0",
                            cursor: totalCount > 0 ? "pointer" : "not-allowed",
                            padding: "0.6rem",
                        }}
                    />
                </div>


            </Dialog>
            <div className="LFCategoryMaster">
                <div className="header">
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                        <div className="heading">
                            <p style={{
                                color: " #00488F",

                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                letterSpacing: '-0.3px'
                            }} >Lost and Found Category</p>
                        </div>
                    </div>
                    <div className="flex align-items-center">

                        <div className="mx-2">
                            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                            <div className='flex align-items-center' style={{ border: "solid 1px #AE9D81", borderRadius: "4px" }}>
                                <i className="pi pi-search pl-2 pr-1" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='border-0' style={{ width: "180px" }} />
                                <i className="pi pi-times pl-1 pr-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} />
                            </div>
                        </div>
                        <div className="mx-2 align-self-end">
                            <Button label="Add New"
                                onClick={() => { setIsAddCategoryPopup(true); setIsAdd(true) }}
                                style={{ backgroundColor: '#ae9d81', border: 'none', cursor: 'pointer' }} />
                        </div>
                        <div className="mx-2 align-self-end">
                            <Button label='Export' onClick={() => { onexportLostAndFoundCategory() }} icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: 'pointer' }} />
                        </div>

                    </div>
                </div>

                <div className="datatable" style={{ marginLeft: '40px' }}>
                    {isLoading ?
                        <div className='spinner spinner-center' style={{ display: 'flex', margin: '200px 0px 0px 50%' }}></div>
                        :
                        <DataTable value={categoryList}
                            rows={10}
                            paginator
                            filters={filters}
                            tableStyle={{ width: '90rem' }}
                            style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                            stripedRows
                            emptyMessage={<><p style={{ textAlign: "center" }}>Lost and found category list is empty</p></>}
                            globalFilterFields={['name', "id", "nameInArabic"]}
                        >
                            <Column field="id" header="Category Id" style={{ width: '20%' }}></Column>
                            <Column field="name" header="Category Name" style={{ width: '20%' }}></Column>
                            <Column field="nameInArabic" header="Category Name(AR)" style={{ width: '20%' }}></Column>
                            <Column field="status" header="Status" body={statusBodyTemplate} style={{ width: '20%' }}></Column>
                            <Column rowEditor header="Action" body={actionBodyTemplate} style={{ width: '20%' }}></Column>
                        </DataTable>
                    }
                </div>
            </div >
            <div>
                {isAddCategoryPopup == true ? popup() : ""}
            </div>
        </>
    )
}
export default LostAndFoundCategory
