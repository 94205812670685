import React from 'react'
import "./DTInspection.scss"
import { useEffect, useState } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
    getCategories,
    getSubCategories,
} from "../../../Services/FeedbackService";

import {
    GetFeedbacks,
    GetGuestInspectionsInfo,
    GetGuestStaffInspections,
    GetSearchedFeedbacks,
    getSearchedFeedbacks,
    getSearchedInspectionForDashboard,
} from "../../../Services/DepartmentTeamService";
import { GetFeedbackList } from "../../../Services/FeedbackService";
import { FilterMatchMode } from "primereact/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Paginator } from "primereact/paginator";
import { exportDataInExcel } from "../../../Services/ExportServices";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";

// ----------Assets Import------------
import editIcon from "../../../../src/assets/images/editIcon.jpg";

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import {
    setGuestInspectionsInfo,
    setFeedbackList,
    setNavVisibilty,
} from "../../../Redux/AppSlice";
import { Dropdown } from "primereact/dropdown";
import {
    EditGuesStaffInspectionStatus,
    GuestInspectionStatuses,
} from "../../../Services/InspectionService";
import { GetAllFiles } from "../../../Services/Files";
import FileView from "../../utili/FileView";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import UseCookies from '../../../Helpers/UseCookies';
function DTInspection() {
    const { tab } = useParams();

    const Context = useSelector((state) => state.AppContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [activeIndex, setActiveIndex] = useState(tab - 1);
    const [guestInspectionsList, setGuestInspectionsList] = useState([]);
    const [feedbackList, setFeedbackLists] = useState("");
    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [guestStaffInspectionFilterValue, setGuestStaffInspectionValue] =
        useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [statusList, setStatusList] = useState([{ id: "", name: "" }]);
    const [isFilemodelView, setISFileModelView] = useState(false);
    const [imageList, setImageList] = useState([]);
    // Pagination
    const [first, setFirst] = useState(0);
    const [totalCount, setTotalRecords] = useState(0);
    const [feedbackFirst, setFeedbackFirst] = useState(0);
    const [feedbackTotalCount, setFeebackTotalRecords] = useState(0);
    const [guestStaffInspection, setGuestStaffInspection] = useState("");
    const [dialogVisible, setDialogVisible] = useState(false);
    const [dropDownObj, setDropDownObj] = useState({
        startDate: "",
        endDate: "",
        category: {},
        subCategory: {},
        location: ""
    });
    const [categoriesList, setCategoriesList] = useState([
        {
            id: 0,
            categoryName: "",
            categoryNameInAr: "",
            isDeleted: false,
        },
    ]);
    const [recordSubCategoriesList, setRecordSubCategoriesList] = useState([
        {
            subCategoryId: 0,
            subCategoryName: "",
            categoryName: "",
            createdOn: "",
            createdBy: "",
            isDeleted: false,
        },
    ]);
    const onSearchGuestStaffInspection = (e) => {
        setGlobalFilterValue(e ? e?.target?.value : "");
        if (!e) {
            getGuestInspectionsInfo(0, 10);
            return;
        }
        getGuestInspectionsInfo(0, 10, e?.target?.value);
    };
    const dateBodyTemplate = (row) => {
        const monthAbbreviations = {
            Jan: "01",
            Feb: "02",
            Mar: "03",
            Apr: "04",
            May: "05",
            Jun: "06",
            Jul: "07",
            Aug: "08",
            Sep: "09",
            Oct: "10",
            Nov: "11",
            Dec: "12",
        };
        const parts = row?.inspectedOn?.includes("/") ? row?.inspectedOn.split("/") : row?.inspectedOn.split("-");
        const day = parts[0];
        const monthAbbreviation = parts[1];
        const year = parts[2];

        const month = monthAbbreviations[monthAbbreviation];

        const outputDate = `${day}/${month}/${year}`;

        return outputDate;
    };
    const statusBodyTemplateTab3 = (row) => {
        return (
            <p
                style={{ padding: "0 20px" }}
                onClick={() => {
                    navigate(`/gueststaffinspectionview/${row?.guestInspectionId}`);
                }}
            >
                {/* <img src={editIcon} alt="edit Icon" /> */}
                <i className="pi pi-eye" style={{ color: "#708090" }}></i>
            </p>
        );
    };
    const onPageChangeGuestInspection = (e, index) => {
        setFirst(e.first);
        getGuestInspectionsInfo(e.first, 10, guestStaffInspection);
    };
    const exportGuestStaffInspectionForDT = () => {
        if (globalFilterValue) {
            exportDataInExcel(
                `GuestStaffInspectionForDT?searchTerm=${globalFilterValue}`,
                cookies()?.bearerToken
            );
            return;
        }
        exportDataInExcel("GuestStaffInspectionForDT", cookies()?.bearerToken);
    };
    const getGuestInspectionsInfo = (skip, take, searchValue = "") => {
        setIsLoading(true);
        getSearchedInspectionForDashboard(
            take,
            skip,
            searchValue,
            cookies()?.bearerToken
        )
            .then((res) => {
                if (res?.status === 200) {
                    setGuestInspectionsList(res?.data?.data);
                    setTotalRecords(res?.data?.count);
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        getGuestInspectionsInfo(0, 10);
    }, []);
    return (
        <div className="DTInspection max-w-full">
            <div className="header ">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        flexGrow: "3",
                        justifyContent: "flex-start",
                    }}
                    className="flex align-items-center org"
                >
                    <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
                        <i
                            className="pi pi-align-justify"
                            style={{
                                fontSize: "1rem",
                                color: "#ae9d81",
                                cursor: "pointer",
                            }}
                            onClick={() =>
                                dispatch(setNavVisibilty({ sideNavVisibility: true }))
                            }
                        ></i>
                    </div>

                    <div
                    // className="heading"
                    //  style={{ paddingLeft: "15px", marginLeft: '40px' }}
                    >
                        <p
                            style={{
                                color: "#2d6c8b",
                                fontSize: "18px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "normal",
                                letterSpacing: "-0.3px",
                                margin: 0,
                            }}
                        >
                            Guest Staff Inspection
                        </p>
                    </div>
                </div>
                <div
                    className="header md:absolute sm:relative lg:absolute"
                    style={{
                        // position: "absolute",
                        // top: "-34px",
                        right: "0",
                        // marginBottom:"10px",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <div className="flex align-items-center">
                        <div className="mx-2 ">
                            <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>Search</h4>
                            <div className='flex align-items-center mx-2' style={{ border: "solid 1px #AE9D81", borderRadius: "4px", height: "42px" }}>
                                <i className="pi pi-search px-2" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                <InputText value={globalFilterValue} onChange={(e) => { onSearchGuestStaffInspection(e) }} placeholder="Search" className='border-0 relative' style={{ width: "180px", height: "40px" }} />
                                <i className="pi pi-times px-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer' }} onClick={() => {
                                    setGlobalFilterValue("");
                                    onSearchGuestStaffInspection(null);
                                }} />
                            </div>
                        </div>
                        <div className="mx-2 align-self-end">
                            <Button
                                label="Export"
                                onClick={exportGuestStaffInspectionForDT}
                                icon={"pi pi-file-export"}
                                style={{
                                    backgroundColor: "transparent",
                                    color: "#ae9d81",
                                    border: "1px #ae9d81 solid",
                                    borderRadius: "20px",
                                    marginRight: "0",
                                    cursor: feedbackTotalCount > 0 ? "pointer" : "not-allowed",
                                    padding: "0.6rem",
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isLoading ?
                <div style={{ height: "100vh" }}>
                    <div className='spinner spinner-center' style={{ display: "flex", margin: "auto", justifyContent: "center", alignItems: "center" }}></div>
                </div>

                :
                <div style={{ margin: "auto", justifyContent: "center", width: "96%" }}>
                    <DataTable
                        value={guestInspectionsList}
                        tableStyle={{ width: '100%' }}
                        style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                        stripedRows
                        rows={10}
                        filters={filters}
                        globalFilterFields={["guestInspectionId", "inspectorName"]}
                        emptyMessage={
                            <>
                                <p style={{ textAlign: "center" }}>
                                    Inspection list is empty
                                </p>
                            </>
                        }
                    >
                        <Column field="code" header=" Inspection Id    "></Column>
                        <Column field="inspectorName" header="Inspector Name"></Column>
                        <Column
                            field="inspectedOn"
                            header="Inspected Date"
                            body={dateBodyTemplate}
                        ></Column>
                        {/* <Column field='status' header='Status' /> */}
                        <Column header="Action" body={statusBodyTemplateTab3} />
                    </DataTable>
                </div>}
            {isLoading || guestInspectionsList?.length == 0 ? (
                ""
            ) : (
                <div style={{ justifyContent: "center", display: "flex" }}>
                    <Paginator
                        first={first}
                        rows={10}
                        totalRecords={totalCount}
                        onPageChange={(e) => onPageChangeGuestInspection(e, 1)}
                        template={{
                            layout:
                                "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                        }}
                        className="justify-content-start"
                    />
                </div>
            )}
        </div>
    )
}

export default DTInspection
