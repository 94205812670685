import React from "react";
import { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import "./style.scss";
import {
  GetActiveFeedbackStatus,
  GetFeedbackList,
  GuestStaffSupervisorFeedbacks,
  GuestStaffSupervisorSearchedFeedbacks,
  getCategories,
  getSubCategories,
} from "../../Services/FeedbackService";
import { FilterMatchMode } from "primereact/api";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Paginator } from "primereact/paginator";

// ----------Assets Import------------
import editIcon from "../../../src/assets/images/editIcon.jpg";

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import {
  setGuestInspectionsInfo,
  setFeedbackList,
  setNavVisibilty,
} from "../../Redux/AppSlice";
import { Button } from "primereact/button";
import { exportDataInExcel } from "../../Services/ExportServices";
import UseCookies from "../../Helpers/UseCookies";
import { GetActiveOrganizationList } from "../../Services/OrganizationService";
import { GetActiveDepartments } from "../../Services/DepartmentService";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

function GuestServiceSupervisor() {
  const Context = useSelector((state) => state.AppContext);
  const dispatch = useDispatch();
  const [cookies, setCookie, removeCookie] = UseCookies();
  const [feedbackList, setFeedbackLists] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalRecords] = useState(0);

  // Filters
  const [IsFilters, setisFilters] = useState(false);
  const [dropdownObj, setDropdownObj] = useState({
    categoryList: [],
    subCategoryList: [],
    organizationList: [],
    activeDepartmentList: [],
    activeFeedbackStatus: []
  });
  const [exportObj, setExportObj] = useState({
    fromDate: "",
    toDate: "",
    searchTerm: "",
    category: "",
    subcategory: "",
    organization: "",
    isModalVisible: false,
    department: "",
    status: ""
  });
  // ----------------------------------------------------------------

  const onGlobalFilterChange = (e) => {
    setGlobalFilterValue(e ? e?.target?.value : "");
    if (!e) {
      getFeedbackList(0, 10);
      return;
    }
    getFeedbackList(0, 10, e?.target?.value);
    return;
    const value = e ? e.target.value : "";
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const actionButtonTemplate = (row) => {
    return (
      <Link to={`/guestservicesupervisorview/${row?.id}`}>
        <img src={editIcon} style={{ cursor: "pointer" }} />
      </Link>
    );
  };

  const statusBodyTemplate = (row) => {
    if (row.status == "Completed") {
      return <p style={{ color: "#3ee63e" }}>Completed</p>;
    } else if (row.status == "Rejected") {
      return <p style={{ color: "red" }}>Rejected</p>;
    } else if (row.status == "Resolved") {
      return <p style={{ color: "#f553e7" }}>Resolved</p>;
    } else if (row.status == "Out of control") {
      return <p style={{ color: "#6ecbfa" }}>Out of control</p>;
    } else if (row.status == "Yet to Ackowledge") {
      return <p style={{ color: "#964B00" }}>Yet to Acknowledge</p>;
    } else if (row.status == "Assigned") {
      return <p style={{ color: "#bd16f5" }}>Assigned</p>;
    } else if (row?.status == "Reassign") {
      return <p style={{ color: "#1512cc" }}>Reassign</p>;
    } else if (row?.status == "Closed") {
      return <p style={{ color: "#3ee63e" }}>Closed</p>;
    }
  };
  const dateBodyTemplateTwo = (row) => {
    let date = new Date(row?.createdDate);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  const getFeedbackList = (skip, take, searchValue = "", categoryId, subCategoryId, orgId, startDate, endDate, feedbackStatusId, departmentId) => {
    setIsLoading(true);
    const fromDate = !startDate ? "" : `${new Date(startDate)?.getFullYear()}-${(new Date(startDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(startDate)?.getDate()?.toString()?.padStart(2, "0")}`;
    const toDate = !endDate ? "" : `${new Date(endDate)?.getFullYear()}-${(new Date(endDate)?.getMonth() + 1)?.toString()?.padStart(2, "0")}-${new Date(endDate)?.getDate()?.toString()?.padStart(2, "0")}`;
    GuestStaffSupervisorSearchedFeedbacks(
      skip, take, searchValue,
      cookies()?.bearerToken, categoryId, subCategoryId, orgId, fromDate, toDate, feedbackStatusId, departmentId
    )
      .then((res) => {
        if (res?.status == 200) {
          //console.log('Fetching Feedback  List for GSSupervisor', res?.data);
          //console.log(res?.data);
          setFeedbackLists(res?.data?.guestStaffSupervisorFeedbacks);
          // dispatch(
          //   setFeedbackList({
          //     feedbackList: res?.data?.guestStaffSupervisorFeedbacks,
          //   })
          // );
          setTotalRecords(res?.data?.totalRecords);
        }
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });

  };

  const onPageChange = (e, index) => {
    setFirst(e.first);
    getFeedbackList(e.first, 10);
    //console.log(e);
  };

  const exportFeedbacksForGSupervisor = () => {
    if (globalFilterValue) {
      exportDataInExcel(
        `FeedbacksGSupervisor?searchTerm=${globalFilterValue}`,
        cookies()?.bearerToken
      );
      return;
    }
    exportDataInExcel("FeedbacksGSupervisor", cookies()?.bearerToken);
  };
  const getCategoriesList = async () => {
    await getCategories()
      .then((res) => {
        // console.log(res?.data);
        if (res?.status === 200) {
          setDropdownObj((prev) => ({ ...prev, categoryList: res?.data }));
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getSubcategoriesList = async (categoryId) => {
    await getSubCategories(categoryId)
      .then((res) => {
        // console.log(list?.data);
        if (res?.data) {
          setDropdownObj((prev) => ({ ...prev, subCategoryList: res?.data?.data }));
        }
      });
  };
  const getActiveDepartments = async () => {
    await GetActiveDepartments(cookies()?.bearerToken)
      .then((res) => {
        // console.log(res?.data);
        if (res?.status === 200) {
          setDropdownObj((prev) => ({ ...prev, activeDepartmentList: res?.data }));
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getActiveFeedbackStatus = async () => {
    await GetActiveFeedbackStatus(cookies()?.bearerToken)
      .then((res) => {
        // console.log(res?.data);
        if (res?.status === 200) {
          setDropdownObj((prev) => ({ ...prev, activeFeedbackStatus: res?.data }));
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };
  const getOrganizationList = () => {
    GetActiveOrganizationList(cookies()?.bearerToken).then(res => {
      if (res?.status == 200) {
        setDropdownObj((prev) => ({ ...prev, organizationList: res?.data }));
      }
    })
  }
  useEffect(() => {
    getOrganizationList();
    getCategoriesList();
    getSubcategoriesList();
    getFeedbackList(0, 10);
    getActiveDepartments();
    getActiveFeedbackStatus();
  }, []);

  return (
    <>
      <div className="guestservicesupervisor" style={{ width: "auto" }}>
        <div
          className="header"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 10px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: "3",
              justifyContent: "flex-start",
            }}
            className="flex align-items-center org"
          >
            <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>
            <div className="heading">
              <p>MG Care Completion</p>
            </div>
          </div>
          <div>
            <Button
              label="Export"
              onClick={totalCount > 0 ? exportFeedbacksForGSupervisor : null}
              icon={"pi pi-file-export"}
              style={{
                backgroundColor: "transparent",
                color: "#ae9d81",
                border: "1px #ae9d81 solid",
                borderRadius: "20px",
                marginRight: "20px",
                cursor: totalCount > 0 ? "pointer" : "not-allowed",
              }}
            />
          </div>
          {/* <div>
                        <Button label='Import' icon={"pi pi-file-import"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', marginRight: '20px' }} />
                    </div> */}
          {/* <div className="search">
            <span className="p-input-icon-left">
              <i
                className="pi pi-search"
                style={{
                  color: "black",
                  fontWeight: "700",
                  fontSize: "1rem",
                }}
              />
              <InputText
                value={globalFilterValue}
                onChange={onGlobalFilterChange}
                placeholder="Search"
              />
              <i
                className="pi pi-times"
                style={{ color: "black", fontWeight: "400" }}
                onClick={() => {
                  setGlobalFilterValue("");
                  onGlobalFilterChange(null);
                }}
              />
            </span>
          </div> */}
          <div className="mr-2 align-self-end">
            <p style={{ margin: "0", padding: "0", fontSize: "20px", color: "#ae9d81", fontWeight: "700", cursor: "pointer" }} onClick={() => { setisFilters(!IsFilters) }}>Filters</p>

          </div>
        </div>
        {IsFilters && <div className="filters" >
          <div className='mx-2'>
            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Category</h4>
            <Dropdown style={{ border: "solid 1px #ae9d81", width: 170 }} showClear={exportObj?.category} value={exportObj?.category} options={dropdownObj?.categoryList} optionLabel='categoryName' optionValue='id' onChange={(e) => { setExportObj((prev) => ({ ...prev, category: e.value })); setFirst(1); getFeedbackList(0, 10, globalFilterValue, e.value, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate, exportObj.status, exportObj.department) }} placeholder='Select' />
          </div>
          <div className='mx-2'>
            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Subcategory</h4>
            <Dropdown style={{ border: "solid 1px #ae9d81", width: 170 }} showClear={exportObj?.subcategory} value={exportObj?.subcategory} onFocus={() => { getSubcategoriesList(exportObj?.category) }} options={dropdownObj?.subCategoryList} optionLabel='subCategoryName' optionValue='subCategoryId' onChange={(e) => { setExportObj((prev) => ({ ...prev, subcategory: e.value })); setFirst(0); getFeedbackList(0, 10, globalFilterValue, exportObj?.category, e.value, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate, exportObj.status, exportObj.department) }} placeholder='Select' />
          </div>
          <div className='mx-2'>
            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Organization</h4>
            <Dropdown style={{ border: "solid 1px #ae9d81", width: 170 }} showClear={exportObj?.organization} value={exportObj?.organization} options={dropdownObj?.organizationList} optionLabel='name' optionValue='id' onChange={(e) => { setExportObj((prev) => ({ ...prev, organization: e.value })); setFirst(0); getFeedbackList(0, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, e.value, exportObj?.fromDate, exportObj?.toDate, exportObj.status, exportObj.department) }} placeholder='Select' />
          </div>
          <div className='mx-2'>
            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Department</h4>
            <Dropdown style={{ border: "solid 1px #ae9d81", width: 170 }} showClear={exportObj?.department} value={exportObj?.department} options={dropdownObj?.activeDepartmentList} optionLabel='name' optionValue='id' onChange={(e) => { setExportObj((prev) => ({ ...prev, department: e.value })); setFirst(0); getFeedbackList(0, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate, exportObj.status, e.value) }} placeholder='Select' />
          </div>
          <div className='mx-2'>
            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Status</h4>
            <Dropdown style={{ border: "solid 1px #ae9d81", width: 170 }} showClear={exportObj?.status} value={exportObj?.status} options={dropdownObj?.activeFeedbackStatus} optionLabel='name' optionValue='id' onChange={(e) => { setExportObj((prev) => ({ ...prev, status: e.value })); setFirst(0); getFeedbackList(0, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, exportObj?.toDate, e.value, exportObj.department) }} placeholder='Select' />
          </div>
          <div className='mx-2'>
            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>From Date</h4>
            <Calendar className='customeCalender' style={{ width: 170, }} value={exportObj?.fromDate} placeholder='Select' showButtonBar onChange={(e) => {
              setFirst(0);
              setExportObj((prev) => ({ ...prev, fromDate: e.value }));
              getFeedbackList(0, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, exportObj?.organization, e.value, exportObj?.toDate, exportObj.status, exportObj.department)
            }} />
          </div>
          <div className='mx-2'>
            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>To Date</h4>
            <Calendar className="customeCalender" style={{ width: 170 }} value={exportObj?.toDate} placeholder='Select ' showButtonBar onChange={(e) => {
              setFirst(0);
              setExportObj((prev) => ({ ...prev, toDate: e.value }));
              getFeedbackList(0, 10, globalFilterValue, exportObj?.category, exportObj?.subcategory, exportObj?.organization, exportObj?.fromDate, e.value, exportObj.status, exportObj.department)
            }} />
          </div>
          <div className='mx-2'>
            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
            <div className='search flex align-items-center' style={{ border: "solid 1px #ae9d81", borderRadius: "4px", height: "42px" }}>
              <i className='pi pi-search px-2' style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
              <InputText style={{ width: 120, border: 0, height: "38px" }} value={globalFilterValue} onChange={onGlobalFilterChange} placeholder='Search' />
              {globalFilterValue && <i className="pi pi-times px-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange(null) }} />}
            </div>
          </div>
        </div>}
        <div className="datatable" style={{ margin: "0px 48px" }}>
          {isLoading ? (
            <div className="spinner spinner-center"></div>
          ) : (
            <DataTable
              value={feedbackList}
              tableStyle={{ width: "90rem" }}
              style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
              stripedRows
              emptyMessage={
                <>
                  <p style={{ textAlign: "center" }}>Feedback list is empty</p>
                </>
              }
              selectionMode="single"
              rows={10}
              filters={filters}
              // selection={selectedFeedback}
              // onSelectionChange={handleRowClick}
              globalFilterFields={[
                "userName",
                "email",
                "category",
                "subCategory",
                "location",
                "mobileNo",
              ]}
            >
              <Column field="code" header="Feedback Id" style={{ width: '10%' }}></Column>
              <Column field="createdDate" header="Created Date" body={dateBodyTemplateTwo} style={{ width: '10%' }}></Column>
              <Column field="userName" header="Name" style={{ width: '10%' }}></Column>
              <Column field="mobileNumber" header="Mobile Number" style={{ width: '10%' }}></Column>
              <Column field="email" header="Email" style={{ width: '10%' }}></Column>
              <Column field="category" header="Category" style={{ width: '10%' }}></Column>
              <Column field="subCategory" header="Sub Category" style={{ width: '10%' }}></Column>
              <Column field="location" header="Location" style={{ width: '10%' }}></Column>
              <Column
                field="status"
                header="Status"
                body={statusBodyTemplate}
                style={{ width: '10%' }} />
              <Column
                field="action"
                header="Action"
                body={actionButtonTemplate}
                style={{ width: '10%' }}></Column>
            </DataTable>
          )}
        </div>
        {isLoading || feedbackList.length == 0 ? (
          ""
        ) : (
          <div style={{ justifyContent: "center", display: "flex" }}>
            <Paginator
              first={first}
              rows={10}
              totalRecords={totalCount}
              onPageChange={(e) => onPageChange(e, 1)}
              template={{
                layout:
                  "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
              }}
              className="justify-content-start"
            />
          </div>
        )}
      </div>
    </>
  );
}


export default GuestServiceSupervisor;

