import React, { useEffect, useMemo, useState } from "react";

import { Checkbox } from "primereact/checkbox";
import { GetInspectionDescriptionMaster } from "../../Services/InspectionService";
import {
    GetChatList,
    GetStatusList,
    GetTenantInspectionDetail,
    UpdateStatus,
} from "../../Services/MGTenentRelationshipViewService";
import { Link, useNavigate, useParams } from "react-router-dom";
import "./style.scss";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import editIcon from "../../../src/assets/images/editIcon.jpg";
import { Button } from "primereact/button";
import { useSearchParams } from "react-router-dom";
import {
    setFeedbackList,
    addDepartmentListContext,
    setNavVisibilty,
} from "../../Redux/AppSlice";
import { AddChatToTenantInspection } from "../../Services/InspectionService";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";


//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";

import { DownloadFile } from "../../Services/Files";
import { toast } from "react-toastify";
import { InputTextarea } from "primereact/inputtextarea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UseCookies from "../../Helpers/UseCookies";


const MGTenentRelationshipView = () => {
    const { id } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch();
    const Context = useSelector((state) => state.AppContext);
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [inspectionDetail, setInspectionDetail] = useState([]);
    const [descriptionList, setDescribtionList] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [statusCategory, setStatusCategory] = useState("");
    const [comment, setComment] = useState("");
    const [chatList, setChatList] = useState([]);
    const [chat, setChat] = useState("");
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const getInspectionDescriptionMaster = async () => {
        await GetInspectionDescriptionMaster(cookies()?.bearerToken)
            .then((res) => {
                if (res?.status == 200) {
                    setDescribtionList(res?.data);
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => { });
    };
    const getInspectionDetails = async () => {
        await GetTenantInspectionDetail(id, cookies()?.bearerToken)
            .then((res) => {
                if (res?.status == 200) {
                    setInspectionDetail(res?.data);
                    const currentStatus = statusList.find((s) => s.name === res?.data?.status);
                    setStatusCategory(currentStatus);

                }
            })
            .catch((error) => console.log(error));
    };

    const getStatusList = () => {
        GetStatusList(cookies()?.bearerToken)
            .then((res) => {
                if (res?.status) {
                    setStatusList(res.data);
                }
            })
            .catch((err) => {
                //console.log(err);
            });
    };

    const updatedStatus = () => {
        UpdateStatus(id, statusCategory.id, comment, cookies()?.bearerToken)
            .then((res) => {
                if (res?.status == 200) {
                    toast.success("Status successfully updated",
                        { position: "top-right" });
                    setSearchParams({ status: statusCategory.name });
                    navigate("/mgtenentrelationship");
                }
            })
            .catch((error) => {
                //console.log(error);
                toast.error("something went wrong");
            })
    };

    const formatDate = (timestamp) => {
        const timestampDate = new Date(timestamp);

        return timestampDate.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    };

    const getChatList = () => {
        setIsLoading(true);
        GetChatList(id, cookies()?.bearerToken)
            .then((res) => {
                if (res?.status == 200) {
                    setChatList(res?.data);
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const sendChat = async () => {
        if (chat) {
            await AddChatToTenantInspection(chat, id, cookies()?.bearerToken)
                .then((res) => {
                    if (res?.status == 200) {
                        getChatList();
                        setChat("");
                    }
                })
                .catch((error) => {
                    //console.log(error);
                })
                .finally(() => {
                    setChat("");
                });
        }
    };

    const back = () => {
        navigate("/mgtenentrelationship");
    };

    const downloadFile = async (fileName, blobUrl) => {
        DownloadFile(fileName, blobUrl, cookies()?.bearerToken);
    };

    useEffect(() => {
        getInspectionDescriptionMaster();
        getInspectionDetails();
        getStatusList();
        getChatList();
    }, []);

    // useEffect(() => {
    //     if (statusList.length > 0) {
    //         const status = searchParams.get("status");
    //         const currentStatus = statusList.find((s) => s.name === status);
    //         setStatusCategory(currentStatus);
    //     }
    // }, [statusList]);

    //console.log({ statusList });
    //console.log({ statusCategory });
    //console.log({ inspectionDetail });

    return (
        <div style={{ margin: "0px 0px" }}>
            <div className="header-section  align-items-center">
                <div className="header" style={{ height: "unset" }}>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexGrow: "3",
                            justifyContent: "flex-start",
                        }}
                        className="flex align-items-center org"
                    >

                        <i
                            className="pi pi-arrow-left nav-back-btn"
                            onClick={back}
                            style={{
                                marginLeft: "30px",
                                cursor: "pointer",
                                fontSize: "1.5rem",
                            }}
                        />
                        <div className="heading">
                            <p style={{
                                color: "#00488F",
                                fontSize: '18px',
                                fontStyle: 'normal',
                                fontWeight: '700',
                                lineHeight: 'normal',
                                letterSpacing: '-0.3px'
                            }}>
                                MG Tenant Inspection
                            </p>
                        </div>
                    </div>
                </div>

                <div className="status-dropdown-div  align-items-center">
                    <p className="header-section-status" style={{ fontWeight: 700, color: "#00488F" }}>Status</p>
                    {/* <Dropdown className='header-section-dropdown' options={statusList} value={statusCategory?.name} /> */}
                    <Dropdown
                        className="header-section-dropdown"
                        options={statusList}
                        value={statusCategory}
                        onChange={(e) => {
                            setStatusCategory(e.value);
                        }}
                        optionLabel="name"
                        placeholder="Select Status"
                    />
                    <div className="addbtn">
                        <Button
                            label="Update"
                            onClick={updatedStatus}
                            style={{ backgroundColor: "#ae9d81", border: "none" }}
                        />
                    </div>
                    {/* <Button label='Submit' className='btn' onClick={updatedStatus} /> */}
                </div>
            </div>
            <div className="body-section">
                <div style={{ width: "70%" }}>
                    {descriptionList?.map((_, index) => {
                        if (
                            inspectionDetail?.tenantInspectionQAs?.filter(
                                (ele) => ele?.name === _.name
                            ).length == 0
                        ) {
                            return <></>;
                        }
                        return (
                            <React.Fragment key={index}>
                                <div
                                    key={index}
                                    style={{
                                        display: "flex",
                                        padding: "6px 10px",
                                        backgroundColor: "#ae9d81",
                                        margin: "10px 10px",
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "50%",
                                            color: "#fff",
                                            padding: "0 10px",
                                            textAlign: "center",
                                        }}
                                    >
                                        {_?.name}
                                    </div>
                                    <div
                                        style={{
                                            width: "10%",
                                            color: "#fff",
                                            padding: "0 10px",
                                            textAlign: "center",
                                        }}
                                    >
                                        Satisfied
                                    </div>
                                    <div
                                        style={{
                                            width: "10%",
                                            color: "#fff",
                                            padding: "0 10px",
                                            textAlign: "center",
                                        }}
                                    >
                                        UnSatisfied
                                    </div>
                                    <div
                                        style={{
                                            width: "20%",
                                            color: "#fff",
                                            padding: "0 10px",
                                            textAlign: "center",
                                        }}
                                    >
                                        Comment
                                    </div>
                                    <div
                                        style={{
                                            width: "10%",
                                            color: "#fff",
                                            padding: "0 10px",
                                            textAlign: "center",
                                        }}
                                    >
                                        Attachment
                                    </div>
                                </div>
                                <>
                                    {inspectionDetail?.tenantInspectionQAs
                                        ?.filter((ele) => ele?.name === _.name)
                                        ?.map((list, index) => {
                                            return (
                                                <>
                                                    <div
                                                        key={index}
                                                        style={{ display: "flex", padding: "0 10px" }}
                                                    >
                                                        <div style={{ width: "50%", padding: "0 10px" }}>
                                                            {list?.question}
                                                        </div>
                                                        <div style={{ width: "10%", padding: "0 10px" }}>
                                                            <div style={{ textAlign: "center" }}>
                                                                <Checkbox
                                                                    checked={list?.isSatisfied}
                                                                    color="#ae9d81"
                                                                ></Checkbox>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: "10%", padding: "0 10px" }}>
                                                            <div style={{ textAlign: "center" }}>
                                                                <Checkbox
                                                                    checked={!list?.isSatisfied}
                                                                    color="#ae9d81"
                                                                ></Checkbox>
                                                            </div>
                                                        </div>
                                                        <div style={{ width: "20%", padding: "0 10px" }}>
                                                            {/* <textarea style={{ width: "100%" }} rows="4" cols="50" value={list?.comments}></textarea> */}
                                                            <InputText
                                                                style={{ width: "100%" }}
                                                                rows="4"
                                                                cols="50"
                                                                value={list?.comments}
                                                                readOnly
                                                            />
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: "10%",
                                                                padding: "0 10px",
                                                                textAlign: "center",
                                                            }}
                                                        >
                                                            {list?.attachment}
                                                            <i
                                                                className="pi pi-paperclip"
                                                                style={{
                                                                    color: "#708090",
                                                                    fontSize: "larger",
                                                                    marginLeft: "5px",
                                                                    cursor: "pointer",
                                                                }}
                                                                onClick={() =>
                                                                    downloadFile(list?.fileName, list?.blobURL)
                                                                }
                                                            ></i>
                                                        </div>
                                                    </div>
                                                    <hr style={{ width: "95%" }} />
                                                </>
                                            );
                                        })}
                                </>
                            </React.Fragment>
                        );
                    })}
                </div>
                <span className="vl"></span>
                <div
                    style={{
                        width: "30%",
                        margin: "5px",
                        // height: "600px",
                        // overflowY: "auto",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            padding: "6px 10px",
                            backgroundColor: "#ae9d81",
                            margin: "10px 10px",
                            justifyContent: "center",
                        }}
                    >
                        <p style={{ color: "#fff", margin: 0 }}>Chat</p>
                    </div>
                    <div style={{ margin: "5px" }}>
                        <InputTextarea
                            // type="text"
                            rows={2}
                            className="p-inputtext-lg"
                            value={chat}
                            style={{ width: "88%", height: "40px", fontSize: "15px", marginLeft: '5px' }}
                            placeholder="Comments"
                            onChange={(e) => {
                                setChat(e.target.value);
                            }}
                        />
                        <i
                            className="pi pi-send"
                            style={{
                                fontSize: "1.5rem",
                                color: "#AE9D81",
                                margin: "10px",
                                transform: "rotateZ(45deg)",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                sendChat();
                            }}
                        ></i>
                    </div>
                    {isLoading ? (
                        <div
                            className="spinner spinner-center"
                            style={{ marginLeft: "200px", width: "30px", height: "30px" }}
                        ></div>
                    ) : (
                        <div>
                            {chatList?.length !== 0 ? (
                                <div div className="flex-1 p-4 flexbox-3">
                                    <div style={{ overflow: "scroll", height: "755px", overflowX: "hidden" }}>
                                        {chatList.map((chat, index) => (
                                            <div
                                                key={chat.id}
                                                className={
                                                    localStorage.getItem("userName").toLowerCase() ===
                                                        chat.messageFrom
                                                        ? "user2"
                                                        : "user"
                                                }
                                            >
                                                <div
                                                    style={{
                                                        fontWeight: 200,
                                                        fontSize: "15px",
                                                        margin: "0px 0px 5px 0px",
                                                    }}
                                                >
                                                    {chat.messageFrom}
                                                </div>
                                                <p
                                                    style={{
                                                        fontWeight: 400,
                                                        margin: "0px",
                                                        wordBreak: "break-all",
                                                    }}
                                                >
                                                    {chat.message}
                                                </p>

                                                <span
                                                    style={{
                                                        fontWeight: 200,
                                                        fontSize: "15px",
                                                        margin: "0px 0px -5px 0px",
                                                    }}
                                                >

                                                </span>
                                                <span
                                                    style={{
                                                        fontWeight: 200,
                                                        fontSize: "15px",
                                                        margin: "0px 0px -5px 0px",
                                                        float: "right",
                                                    }}
                                                >
                                                    {formatDate(chat.messagedDate)}
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div style={{ padding: "100px 0" }}>
                                        <p style={{ textAlign: "center" }}>No Chats Found</p>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MGTenentRelationshipView;
