import { useState } from 'react';

const parseCookies = () => {
  return document.cookie.split(';').reduce((cookies, cookie) => {
    const [name, value] = cookie.trim().split('=');
    cookies[name] = value;
    return cookies;
  }, {});
};
const UseCookies = () => {
  const getCookies = () => {
    const cookies = parseCookies();  // Always parse cookies directly from document.cookie
    const decodedString = decodeURIComponent(cookies?.roles ? cookies?.roles : null);
    const roleArray = JSON.parse(decodedString || '[]');
    return {
      bearerToken: cookies?.bearerToken,
      roles: roleArray,
      organizationID: cookies?.organizationID,
      userId: cookies?.userId,
    };
  };

  const setCookie = (name, value, options = {}) => {
    const { path = '/', domain, maxAge, expires, secure } = options;
    let cookieString = "";

    if (name === 'roles') {
      const jsonString = JSON.stringify(value);
      const encodedString = encodeURIComponent(jsonString);
      cookieString = `${name}=${encodedString}; path=${path}`;
    } else {
      cookieString = `${name}=${value}; path=${path}`;
    }

    if (domain) {
      cookieString += `; domain=${domain}`;
    }
    if (maxAge) {
      cookieString += `; max-age=${maxAge}`;
    }
    if (expires) {
      cookieString += `; expires=${new Date(expires).toUTCString()}`;
    }
    if (secure) {
      cookieString += `; secure`;
    }

    document.cookie = cookieString;
  };


  const removeCookie = (name, options = {}) => {
    setCookie(name, '', { ...options, expires: new Date(0) });
  };

  return [getCookies, setCookie, removeCookie];
};

export default UseCookies;
