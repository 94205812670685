import Axios from '../Helpers/Axios';

export function GetFeedbackCategoryList(token) {
    return Axios('FeedbackCategory/GetCategories', 'get', null, token)
}
export function GetFeedbackCategoriesForList(searchTerm = "", status = "", isExport = false) {
    return Axios(`FeedbackCategory/GetCategoriesForList?searchTerm=${searchTerm}&status=${status}&forExport=${isExport}`, 'get', null)
}
export function AddFeedbackSubCategory(data, token) {
    return Axios('FeedbackCategory/AddCategory', 'post', data, token)
}
export function EditFeedbackCategory(data, token) {
    return Axios('FeedbackCategory/EditCategory', 'patch', data, token)
}
export function ImportFeedbackCategory(data, token) {
    return Axios(`Excel/BulkImportFeedbackCategory`, "post", data, token, true)
}
