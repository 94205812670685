import React, { useState, useEffect } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import style from './style.module.scss'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// -------------Imports---------------
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';

//-----------Context ---------------
import { useDispatch, useSelector } from 'react-redux'
import { addOrganizationList, setUserdata } from '../../Redux/AppSlice'
//----------------------------------------
import { GetOrganizationList } from '../../Services/OrganizationService';
import { GetRolesList } from '../../Services/RoleService';






function UserManagementView() {
    const Context = useSelector((state) => state.AppContext)
    //console.log("hi", Context)
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [organizationList, setOrganizationList] = useState([]);
    const [roleList, setRoleList] = useState([])



    const getOrganizationList = (() => {
        GetOrganizationList(cookies()?.bearerToken)
            .then((res) => {
                //console.log("Fetching Organization List");
                setOrganizationList(res?.data);
                dispatch(
                    addOrganizationList({
                        organizationList: res?.data
                    }))

            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                // //console.log(organizationList);
            })

    })

    // const getRoleList = (() => {
    //     GetRolesList()
    //         .then((res) => {
    //             //console.log("rolesss",res);
    //             setRoleList(res?.data)
    //             dispatch(
    //                 setUserdata({
    //                     roles: res?.data?.roles

    //                 })
    //             )
    //         })
    //         .catch((error) => {
    //             console.warn(error);
    //         })
    // })




    useEffect(() => {
        // //console.log("Cookies", cookies);
        //console.log("Context", Context);
        if (Context?.organizationList?.length == 0) {
            getOrganizationList();
        }
        else {
            setOrganizationList(Context.organizationList);
        }

    }, [])
    // useEffect(() => {
    //     // //console.log("Cookies", cookies);
    //     //console.log("Context", Context);
    //     if (Context?.userData?.roles?.length == 0) {
    //         getRoleList();
    //     } else {
    //         setUserdata(Context.userData.roles);
    //     }

    // }, [])
    return (
        <>
            {/* <Dialog className='popup' header={header} visible={true} style={{ width: '500vw' }} onHide={() => { setIsAddUser(false) }}>
                    <div className="popup" style={{ width: "1000px" }} >
                        <div className="heading">
                            <p>Add New Details</p>
                        </div>
                        <div className="body">
                            <div className="label grid">
                                <div className='col'>First Name</div>
                                <div className='col'>Last Name</div>
                                <div className='col'>Email</div>
                            </div>
                            <div className="input grid">
                                <div className="col">
                                    <InputText placeholder="Enter First Name" value={user.firstName} onChange={(e) => { setUser((prev) => ({ ...prev, firstName: e.target.value })) }} />
                                    {isUserValid && user.firstName.length == 0 &&
                                        // <div>
                                        <p style={{ color: "red" }}>First name is required</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col">
                                    <InputText placeholder="Enter Last Name" value={user.lastname} onChange={(e) => { setUser((prev) => ({ ...prev, lastname: e.target.value })) }} />
                                    {isUserValid && user.lastname.length == 0 &&
                                        // <div>
                                        <p style={{ color: "red" }}>Last Name is required</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col">
                                    <InputText placeholder="Enter Email" value={user.userEmail} onChange={(e) => { setUser((prev) => ({ ...prev, userEmail: e.target.value })) }} />
                                    {isUserValid && user.userEmail.length == 0 &&
                                        // <div>
                                        <p style={{ color: "red" }}>Email is required</p>
                                        // </div>
                                    }
                                </div>
                            </div>
                            <div className="label grid">
                                <div className='col'>Mobile Number</div>
                                <div className='col'>Nationality</div>
                                <div className='col'>Role</div>
                            </div>
                            <div className="input grid">
                                <div className="col">
                                    <InputText placeholder="Enter Mobile Number" value={user.userMobile} onChange={(e) => { setUser((prev) => ({ ...prev, userMobile: e.target.value })) }} />
                                    {isUserValid && user.userMobile.length == 0 &&
                                        // <div>
                                        <p style={{ color: "red" }}>Mobile number is required</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col">
                                    <Dropdown value={user.nationality} style={{ border: "1px solid #AE9D81", width: "100%", height: "50px", borderRadius: "5px", boxShadow: "none" }}
                                        onChange={(e) => {
                                            setUser((prev) => ({ ...prev, userOrg: e?.target?.value?.id }))

                                        }} options={nationalityList} optionLabel="name"
                                        placeholder="Select Nationality" />
                                    {isUserValid && user.nationality=="" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Nationality is required</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col">
                                    <Dropdown value={user.userRole} style={{ border: "1px solid #AE9D81", width: "100%", height: "50px", borderRadius: "5px", boxShadow: "none" }}
                                        onChange={(e) => {
                                            setUser((prev) => ({ ...prev, userRole: e?.target?.value?.id }))

                                        }} options={nationalityList} optionLabel="name"
                                        placeholder="Select Role" />
                                    {isUserValid && user.userRole=="" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Role is required</p>
                                        // </div>
                                    }
                                </div>
                            </div>
                            <div className="label grid">
                                <div className='col'>Organization</div>
                                <div className='col'>Status</div>
                                <div className='col'></div>
                            </div>
                            <div className="input grid">
                                <div className="col">
                                    <Dropdown value={user.userRole} style={{ border: "1px solid #AE9D81", width: "100%", height: "50px", borderRadius: "5px", boxShadow: "none" }}
                                        onChange={(e) => {
                                            setUser((prev) => ({ ...prev, userRole: e?.target?.value?.id }))

                                        }} options={nationalityList} optionLabel="name"
                                        placeholder="Select Organization" />
                                    {isUserValid && user.userRole=="" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Organization is required</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col">
                                    <InputText placeholder="Enter Mobile Number" value={user.userMobile} onChange={(e) => { setUser((prev) => ({ ...prev, userMobile: e.target.value })) }} />
                                    {isUserValid && user.userMobile.length == 0 &&
                                        // <div>
                                        <p style={{ color: "red" }}>First name is required</p>
                                        // </div>
                                    }
                                </div>
                                <div className="col"></div>
                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel" onClick={() => {
                                    setIsAddUser(false);
                                }} style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px" }} />
                                <Button label="Submit" onClick={() => { }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px" }} />
                            </div>
                        </div>
                    </div>
                </Dialog> */}
            <div className='head-text head'>
                {/* <FontAwesomeIcon icon={faArrowLeft} className='arrow-icon' /> */}
                Add Details
            </div>
            <div className={style.body}>
                <div className={style.firstCol}>
                    <div>
                        <p>First Name</p>
                        <InputText className={style.border} />
                    </div>
                    <div>
                        <p>Last Name</p>
                        <InputText className={style.border} />
                    </div>
                    <div>
                        <p>Email</p>
                        <InputText className={style.border} />
                    </div>
                </div>
                <div className={style.secondCol}>
                    <div>
                        <p>Mobile Number</p>
                        <InputText className={style.border} />
                    </div>
                    <div>
                        <p>Nationality</p>
                        <InputText className={style.border} />
                    </div>
                    <div>
                        <p>Role</p>
                        <Dropdown className={style.border} />
                        {/* <Dropdown options={roleList.roleName} className={style.border} /> */}
                        {/* <Dropdown options={Context?.userData?.roles.map(({ roleName }) => roleName)} className={style.border} /> */}

                    </div>
                </div>
                <div className={style.thirdCol}>
                    <div>
                        <p>Organization</p>
                        <Dropdown options={Context.organizationList.map(({ name }) => name)} placeholder='Select Organization' className={style.border} />
                    </div>
                    <div>
                        <p>Status</p>
                        <InputText className={style.border} />
                    </div>
                    <div>
                        <p>Status</p>
                        <InputText className={style.border} />
                    </div>
                </div>
                <div className={style.Button}>
                    <button className={style.cancelBtn}>Cancel</button>
                    <button className={style.submitBtn}>Submit</button>
                </div>
            </div>
        </>
    )

}

export default UserManagementView