import React, { useState, useEffect } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import './style.scss'

// -------------Prime Recat Imports---------------
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'




// -------------Service imports----------

import { AddQuestion, EditQuestion, GetQuestions } from '../../Services/TenantQuestionService'
import { GetAllDescriptions } from '../../Services/TenantDescriptionService';
// -----------------------------------


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------


//------Imports from other Components-----
//----------------------------------------


//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { addOrganizationList, setNavVisibilty } from '../../Redux/AppSlice';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
//----------------------------------------

function TenantQuestions() {
    const Context = useSelector((state) => state.AppContext);
    const dispatch = useDispatch();
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [isAdd, setIsAdd] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [isAddQuestion, setIsAddQuestion] = useState(false);
    const onGlobalFilterChange = (e) => {

        const value = e ? e.target.value : "";
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const [tenantDescriptionList, setTenantDescriptionList] = useState("");
    const [tenantQuestionList, setTenantQuestionList] = useState("");
    const [question, setQuestion] = useState({
        "descriptionId": 0,
        "question": "",
        "tenantQuestionId": 0,
        "isDeleted": true
    });
    const [isInvalid, setIsInvalid] = useState(false);
    const [isQuestionExist, setIsQuestionExists] = useState(false);






    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>{isAdd ? "Add" : "Edit"} Tenant Questions Details</p>
        </div>
    };
    const statusBodyTemplate = ((row) => {
        // ////console.log(row);
        if (row.isDeleted !== true) {
            return <p style={{ color: "#45AA08" }}  >Active</p>
        }
        else {
            return <p style={{ color: "#FF4700" }} >Inactive</p>
        }
    });
    const actionButtonTemplate = (row) => {
        // //console.log("filterdDescription", filterdDescription[0]);
        return (
            <div onClick={(e) => {
                console.log("row", row);
                let filterdDescription = tenantDescriptionList?.filter((item) => {
                    return item.name === row.inspectionDescriptionName;
                });
                setIsAdd(false);
                setIsAddQuestion(true);
                setQuestion((prev) => ({
                    ...prev,
                    question: row.question,
                    tenantQuestionId: row.id,
                    isDeleted: !row.isDeleted,
                    descriptionId: filterdDescription[0]?.id,
                }));


            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };
    const dateBodyTemplate = ((row) => {
        const monthAbbreviations = {
            Jan: '01',
            Feb: '02',
            Mar: '03',
            Apr: '04',
            May: '05',
            Jun: '06',
            Jul: '07',
            Aug: '08',
            Sep: '09',
            Oct: '10',
            Nov: '11',
            Dec: '12'
        };
        //console.log(row);
        const parts = row?.createDate.split('/');
        const day = parts[0];
        const monthAbbreviation = parts[1];
        const year = parts[2];

        const month = monthAbbreviations[monthAbbreviation];

        const outputDate = `${day}/${month}/${year}`;

        return outputDate;
    });
    const popup = (() => {
        return (
            <>
                <Dialog header={header} visible={isAddQuestion} style={{ width: '30vw' }}
                    closable={false} >
                    <div className="popup " >
                        {/* <div className="heading">
                            <p>Add New Details</p>
                        </div> */}
                        <div className="body"  >
                            <div className="label grid" >
                                <div className='col' >Description<span style={{ color: 'red' }}>*</span></div>
                                <div className='col' ></div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    {/* <InputText placeholder="Enter Question" value={question?.question} onChange={(e) => { setQuestion((prev) => ({ ...prev, question: e.target.value })) }} /> */}
                                    <Dropdown value={question?.descriptionId} options={tenantDescriptionList}
                                        onChange={(e) => { setQuestion((prev) => ({ ...prev, descriptionId: e.value })) }}
                                        optionLabel="name" optionValue='id' placeholder="Select Description" style={{ borderColor: '#ae9d81' }} />
                                    {/* {isQuestionExist &&
                                        <p style={{ color: "red" }}>Description is required</p>
                                    } */}
                                    {isInvalid && question.descriptionId == 0 &&
                                        <p style={{ color: "red" }}>Description is required</p>
                                    }
                                </div>



                            </div>
                            <div className="label grid" >
                                <div className='col' >Question<span style={{ color: 'red' }}>*</span></div>
                                <div className='col' style={{ position: 'absolute', left: '240px' }}>Status</div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    <InputText placeholder="Enter Question" value={question?.question} onChange={(e) => { setQuestion((prev) => ({ ...prev, question: e.target.value })) }} />
                                    {isQuestionExist &&
                                        <p style={{ color: "red" }}>Question is already exists</p>
                                    }
                                    {isInvalid && question.question == "" &&
                                        <p style={{ color: "red" }}>Question is required</p>
                                    }
                                </div>

                                <div className="col ">

                                    <label className="switch ">
                                        <input type="checkbox" checked={question?.isDeleted} onChange={(e) => {
                                            setQuestion((prev) => ({ ...prev, isDeleted: e.target.checked }))

                                        }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel"
                                    onClick={() => {
                                        setIsAddQuestion(false);
                                        setIsAdd(false);
                                        setIsInvalid(false);
                                        setIsQuestionExists(false);
                                        setQuestion({
                                            "descriptionId": 0,
                                            "question": "string",
                                            "tenantQuestionId": 0,
                                            "isDeleted": true
                                        })


                                    }}
                                    style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "Submit" : "Update"} onClick={() => { isAdd ? addTenantQuestion() : editTenantQuestion() }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog>

            </>
        )
    })

    const getTenantDescriptionList = async () => {
        setIsLoading(true);
        let take = 500;
        let skip = 0;
        await GetAllDescriptions(take, skip, cookies()?.bearerToken)
            .then((res) => {
                if (res?.status === 200) {
                    setTenantDescriptionList(res?.data?.data.filter((_) => _?.isDeleted == false));
                }
            })
            .catch((err) => {
                //console.log(err);

            })
            .finally(() => {
                setIsLoading(false);
            });

    };

    const getTenantQuestionList = async () => {
        let take = 500;
        let skip = 0;
        await GetQuestions(take, skip, cookies()?.bearerToken)
            .then((res) => {
                if (res?.status === 200) {
                    setTenantQuestionList(res?.data?.data);
                    setTenantQuestionList(res?.data?.data.map((_) => {
                        return {
                            "id": _?.id,
                            "question": _?.question,
                            "createDate": _?.createDate,
                            "isDeleted": _?.isDeleted,
                            "inspectionDescriptionName": _?.inspectionDescriptionName,
                            "status": _?.isDeleted ? "Inactive" : "Active"
                        }
                    }))
                }
            })
            .catch((err) => {
                //console.log(err);
            })
    };
    // Add Questions
    const addTenantQuestion = async () => {
        if (question.question == "" ||
            question.descriptionId == 0
        ) {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
        let filterdQuestion = tenantQuestionList.filter((item) => item.question == question.question);
        if (filterdQuestion.length > 0) {
            setIsQuestionExists(true);
            return;
        }
        setIsQuestionExists(false);
        let data = {
            "descriptionId": question?.descriptionId,
            "question": question?.question,
        }
        await AddQuestion(data, cookies()?.bearerToken)
            .then((res) => {
                if (res?.status === 200) {
                    toast.success("Tenant Question Added Successfully");
                    setIsAddQuestion(false);
                    setIsAdd(false);
                    setQuestion({
                        "descriptionId": 0,
                        "question": "",
                        "tenantQuestionId": 0,
                        "isDeleted": true
                    })
                    getTenantQuestionList();
                }
            })
            .catch((err) => {
                //console.log(err);
            })
    };

    // Edit Questions
    const editTenantQuestion = async () => {
        if (question.question == "" ||
            question.descriptionId == 0
        ) {
            setIsInvalid(true);
            return;
        }
        setIsInvalid(false);
        let filterdQuestion = tenantQuestionList.filter((item) => item.question == question.question && item.id != question.tenantQuestionId);
        if (filterdQuestion.length > 0) {
            setIsQuestionExists(true);
            return;
        }
        setIsQuestionExists(false);
        let data = {
            "descriptionId": question?.descriptionId,
            "question": question?.question,
            "tenantQuestionId": question?.tenantQuestionId,
            "isDeleted": !question?.isDeleted,
        }
        await EditQuestion(data, cookies()?.bearerToken)
            .then((res) => {
                if (res?.status === 200) {
                    toast.success("Tenant Question Updated Successfully");
                    setIsAddQuestion(false);
                    setIsAdd(false);
                    setQuestion({
                        "descriptionId": 0,
                        "question": "",
                        "tenantQuestionId": 0,
                        "isDeleted": true
                    });
                    getTenantQuestionList();
                }
            })
            .catch((err) => {
                //console.log(err);
            })
    };


    const back = () => {
        navigate('/dashboard');
    };




    useEffect(() => {
        getTenantDescriptionList();
        getTenantQuestionList();
    }, [])
    // Export Filter
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [exportDetails, setExportDetails] = useState({
        startDate: "",
        endDate: "",
        organization: "",
        desk: "",
        status: true
    });

    return (
        <div className='TenantQuestion' >
            <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                        </label>
                        <Calendar
                            id="StartDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.startDate}
                            placeholder="Choose start end"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.endDate}
                            placeholder="Choose end date"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <InputText
                            id="organization"
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                            placeholder="Enter organization" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="Status" className="font-bold block mb-2">Status
                        </label>
                        {/* <InputText
                        id="Status"
                        style={{ width: "95%" }}
                        value={exportDetails?.status}

                        placeholder="Enter organization" ></InputText> */}
                        <div className="input grid ">
                            <div className="col ">
                                <label className="switch ">
                                    <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                    }}
                >
                    <Button
                        label="Submit"
                        onClick={''}
                        // icon={"pi pi-file-export"}
                        style={{
                            backgroundColor: " #ae9d81",
                            color: "#fff",
                            border: "none",
                            // borderRadius: "20px",
                            marginRight: "0",
                            cursor: "pointer",
                            padding: "0.6rem",
                        }}
                    />
                </div>


            </Dialog>
            {/* <ToastContainer /> */}
            <div className="header">
                <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                    <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                    <div className="heading">
                        <p style={{
                            color: '#00488F',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: 'normal',
                            letterSpacing: '-0.3px'
                        }}>Tenant Questions</p>                    </div>
                </div>
                {/* <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                    <div className="menubtn" style={{ padding: '10px 10px 5px 20px' }}>
                        <i className="pi pi-align-justify" style={{ fontSize: '1rem', color: "#ae9d81", cursor: "pointer" }}
                            onClick={() => dispatch(setNavVisibilty({ sideNavVisibility: true }))}></i>
                    </div>
                    <div className="heading">
                        <p style={{
                            color: '#00488F',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '700',
                            lineHeight: 'normal',
                            letterSpacing: '-0.3px'
                        }}>Tenant Questions</p>
                    </div>
                </div> */}
                {/* <div className="search">
                    <span className="p-input-icon-left p-input-icon-right">
                        <i className="pi pi-search" style={{
                            color: 'black', fontWeight: '700', fontSize: '1rem',
                        }} />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                        <i className="pi pi-times" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: "pointer" }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} />
                    </span>
                </div> */}
                <div className='flex align-items-center'>
                    <div className="mx-2">
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                        <div className='flex align-items-center' style={{ border: "solid 1px #AE9D81", borderRadius: "4px" }}>
                            <i className="pi pi-search pl-2 " style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                            <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='border-0' />
                            <i className="pi pi-times pl-1 pr-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange(); }} />
                        </div>
                    </div>
                    <div className="mx-2 align-self-end">
                        <Button label="Add New" onClick={() => {
                            setIsAddQuestion(true); setIsAdd(true);
                            setQuestion({
                                "descriptionId": 0,
                                "question": "",
                                "tenantQuestionId": 0,
                                "isDeleted": true
                            })
                        }} style={{ backgroundColor: '#ae9d81', border: 'none' }} />
                    </div>
                </div>

            </div>

            <div className="datatable" style={{ margin: "0px 0px" }}>
                {isLoading ?
                    <div className='spinner spinner-center' style={{ marginLeft: '700px' }}></div>
                    :
                    <DataTable value={tenantQuestionList} paginator rows={10} filters={filters} tableStyle={{
                        width: '90rem',
                    }} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', marginLeft: '30px' }} stripedRows
                        emptyMessage={<><p style={{ textAlign: "center" }}>Tenant question list is empty</p></>}
                        globalFilterFields={['id', 'question', 'inspectionDescriptionName', "createdOn", "status"]}>
                        <Column field="id" header="Question Id"></Column>
                        <Column field="question" header="Question"></Column>
                        <Column field="inspectionDescriptionName" header="Description Name"></Column>
                        <Column field="createDate" header="Created Date" body={dateBodyTemplate}></Column>
                        <Column field="status" header="Status" body={statusBodyTemplate} ></Column>
                        <Column rowEditor header="Action" body={actionButtonTemplate}></Column>
                    </DataTable>}
            </div>


            <div>
                {isAddQuestion == true ? popup() : ""}
            </div>
        </div>
    )
}


export default TenantQuestions