import React, { useEffect, useState } from "react";
import "./TrackDeliveries.scss";
import ShopAndDropHeader from "../ShopAndDropHeader/ShopAndDropHeader";
import editIcon from "../../../assets/images/editIcon.jpg";
import {
  GetActivePickupLocation,
  GetActiveDropLocation,
  GetDeliveryTypes,
  GetCheckOutDeliveryData,
  GetStatus,
  GetCheckInIds,
  GetActiveStores,
  GetDeskLocation,
  GetActiveDeskLocation,
} from "../../../Services/ShopAndDropService";

// Prime React Components
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNavVisibilty } from "../../../Redux/AppSlice";
import { AutoComplete } from "primereact/autocomplete";
import { Badge } from "primereact/badge";
import { delivery, deliveryTypeNames } from "../../Common/Config";

import { status } from "../../Common/Config";
import { exportExcel } from "../../../Services/ExportServices";
import { GetActiveOrganizationList } from "../../../Services/OrganizationService";
import UseCookies from "../../../Helpers/UseCookies";
import Socket from "../../../Helpers/Sockket"
import { toast } from "react-toastify";

const TrackDeliveries = () => {
  //initial dropdown value
  const initialDropValue = {
    pickupLoacation: [],
    tenant: [],
    deliveryType: [],
    status: [],
    checkIn: [],
    desk: [],
  };

  const initialFilterDetails = {
    checkIn: null,
    phoneNumber: "",
    pickupLocation: null,
    status: null,
    deskLocation: null,
    tenantLocation: null,
    startDate: null,
    endDate: null,
    deliveryType: null,
  };

  const [isLoader, setIsLoader] = useState(false);
  const [isFilterView, setIsFilterView] = useState(false);
  const [cookies, setCookie, removeCookie] = UseCookies();
  const [dropdownValues, setDropdownValues] = useState({ ...initialDropValue });
  const [filterDetails, setFilterDetails] = useState({
    ...initialFilterDetails,
  });
  const [checkOutData, setCheckOutData] = useState([]);
  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  console.log("dropdownValues", dropdownValues);
  const [connect, close, send, socket] = Socket();


  //action template
  const actionButtonTemplate = (row) => {
    return (
      <>
        <div style={{ display: "flex", gap: "15px", justifyContent: "center" }}>
          <div
            onClick={(e) => {
              // setDetails{}
            }}
          >
            {row.status === status.delivered ? (
              <i
                className="pi pi-eye"
                onClick={() => {
                  handleNavigate(row);
                }}
                style={{ fontSize: "1rem", cursor: "pointer" }}
              />
            ) : (
              !cookies()?.roles.includes("GUESTSERVICESTAFF") ?
                <img
                  src={editIcon}
                  onClick={() => {
                    handleNavigate(row);
                  }}
                  style={{ cursor: "pointer", marginLeft: "10px" }}
                /> :
                <i
                  className="pi pi-eye"
                  onClick={() => {
                    handleNavigate(row);
                  }}
                  style={{ fontSize: "1rem", cursor: "pointer" }}
                />

            )}
          </div>
        </div>
      </>
    );
  };


  const dateBodytemplate = (row) => {
    const [day, month, year] = row?.createdDate?.includes("/") ? row?.createdDate.split('/') : row?.createdDate.split("-");
    return (
      <div>
        <p style={{ fontSize: '1rem', fontWeight: 400 }}>{day + "/" + month + "/" + year}</p>
      </div>
    );
  };

  // get status color
  const getStatusSeverity = (row) => {
    let statusSeverity = "";
    if (row.status === status.inDesk) {
      statusSeverity = "#70b1e1";
    } else if (row.status === status.outOfDelivery) {
      statusSeverity = "red";
    } else if (row.status === status.delivered) {
      statusSeverity = "#3eeb4b";

    } else if (row.status === status.collected) {
      statusSeverity = "#c8c047";
    } else if (row.status === status.instore) {
      statusSeverity = "#5967d0";
    }
    return statusSeverity;
  };

  //status template
  const statusTemplate = (row) => {
    return (
      <div className="delivery-status" style={{ justifyContent: "center", display: "flex", width: '150px' }}>
        <p style={{ color: "white", backgroundColor: getStatusSeverity(row), padding: '5px', borderRadius: '5px', width: '100%', justifyContent: "center", display: "flex" }} >{row.status}</p>
      </div>
    );
  };

  // navugate to next page
  const handleNavigate = (row) => {
    let isIndieMall = row.deliveryType === delivery.insideMall ? true : false;
    navigate(
      "/shop-and-drop-track-delivery-view/" +
      encodeURI("check out id " + row.id) +
      "/" +
      encodeURI("Is inside mall " + isIndieMall)
    );
  };

  //get dropdown values
  const getAllData = async (skip, take, searchTerm, getFunction, dropKey) => {
    await getFunction(skip, take, searchTerm, cookies()?.bearerToken)?.then(
      (res) => {
        if (res?.status == 200) {
          setDropdownValues((prev) => ({
            ...prev,
            [dropKey]: res?.data?.data,
          }));
        }
      }
    );
  };

  const getAllDeliveryTypes = async () => {
    await GetDeliveryTypes(cookies()?.bearerToken)?.then((res) => {
      if (res?.status == 200) {
        setDropdownValues((prev) => ({
          ...prev,
          deliveryType: res?.data,
        }));
      }
    });
  };

  const getAllDesks = async () => {
    await GetActiveDeskLocation(cookies()?.organizationID, cookies()?.bearerToken)?.then(
      (res) => {
        if (res?.status == 200) {
          setDropdownValues((prev) => ({
            ...prev,
            desk: res?.data,
          }));
        }
      }
    );
  };

  //input value change
  const handleInputValue = (key, value) => {
    setFilterDetails({ ...filterDetails, [key]: value });
  };

  //search items
  const searchItems = (event, getFunction, key) => {
    let query = event.query;
    getAllData(0, 100, query, getFunction, key);
    // setDropdownValues({ ...dropdownValues });
    // getAllStores(0, 100, query);
  };

  const getAllCheckOutData = async (
    skip,
    take,
    checkInNumber,
    phoneNumber,
    tenantLocation,
    deskLocation,
    pickupLocation,
    status,
    startDate,
    endDate,
    deliveryType
  ) => {
    setIsLoader(true);
    await GetCheckOutDeliveryData(
      cookies()?.bearerToken,
      skip,
      take,
      checkInNumber,
      phoneNumber,
      tenantLocation,
      deskLocation,
      pickupLocation,
      status,
      startDate,
      endDate,
      deliveryType
    )
      ?.then((res) => {
        if (res.status == 200) {
          setCheckOutData(res?.data?.data);
          setTotalCount(res?.data?.count);
        }
      })
      .catch((err) => console.log("err", err))
      .finally(() => setIsLoader(false));
  };

  //pagination
  const onPageChange = (e, index) => {
    setFirst(e.first);
    handleSearch(e.first, 10);
  };

  const handleSearch = (skip, take) => {
    let startDate = filterDetails.startDate
      ? filterDetails.startDate.getFullYear() +
      "/" +
      (filterDetails.startDate.getMonth() + 1) +
      "/" +
      filterDetails.startDate.getDate()
      : "";
    let endDate = filterDetails.endDate
      ? filterDetails.endDate.getFullYear() +
      "/" +
      (filterDetails.endDate.getMonth() + 1) +
      "/" +
      filterDetails.endDate.getDate()
      : "";

    getAllCheckOutData(
      skip,
      take,
      filterDetails?.checkIn?.code ? filterDetails?.checkIn?.code : "",
      filterDetails?.phoneNumber ? filterDetails?.phoneNumber : "",
      filterDetails?.tenantLocation?.id ? filterDetails?.tenantLocation?.id : 0,
      filterDetails?.deskLocation?.id ? filterDetails?.deskLocation?.id : 0,
      filterDetails?.pickupLocation?.id ? filterDetails?.pickupLocation?.id : 0,
      filterDetails?.status?.id ? filterDetails?.status?.id : 0,
      startDate,
      endDate,
      filterDetails?.deliveryType?.id ? filterDetails?.deliveryType?.id : 0
    );
  };

  const getIsFilterNotEmpty = () => {
    let isEmpty =
      filterDetails?.checkIn ||
      filterDetails?.phoneNumber ||
      filterDetails?.tenantLocation ||
      filterDetails?.deskLocation ||
      filterDetails?.pickupLocation ||
      filterDetails?.status ||
      filterDetails?.startDate ||
      filterDetails?.endDate ||
      filterDetails?.deliveryType;

    return isEmpty;
  };
  const onExportTrackDetails = () => {
    let startDate = filterDetails.startDate
      ? filterDetails.startDate.getFullYear() +
      "/" +
      (filterDetails.startDate.getMonth() + 1) +
      "/" +
      filterDetails.startDate.getDate()
      : "";
    let endDate = filterDetails.endDate
      ? filterDetails.endDate.getFullYear() +
      "/" +
      (filterDetails.endDate.getMonth() + 1) +
      "/" +
      filterDetails.endDate.getDate()
      : "";
    let urlRoute = `ExportHFTrackDeliveries?checkInNumber=${filterDetails?.checkIn?.code ? filterDetails?.checkIn?.code : ""}&phoneNumber=${filterDetails?.phoneNumber ? filterDetails?.phoneNumber : ""}&tenantId=${filterDetails?.tenantLocation?.id ? filterDetails?.tenantLocation?.id : 0}&deskId=${filterDetails?.deskLocation?.id ? filterDetails?.deskLocation?.id : 0}&pickupLocation=${filterDetails?.pickupLocation?.id ? filterDetails?.pickupLocation?.id : 0}&status=${filterDetails?.status?.id ? filterDetails?.status?.id : 0}&startDate=${startDate}&endDate=${endDate}&deliveryType=${filterDetails?.deliveryType?.id ? filterDetails?.deliveryType?.id : 0}&orgId=${cookies()?.organizationID}&isForHFRunner=${cookies()?.roles?.some(_ => _ == "SAD_DELIVERY_PARTNER")}`

    exportExcel(urlRoute);
  }

  useEffect(() => {
    setIsLoader(true);
    getAllData(0, 100, "", GetActivePickupLocation, "pickupLoacation");
    getAllData(0, 100, "", GetStatus, "status");
    getAllData(0, 100, "", GetCheckInIds, "checkIn");
    getAllData(0, 100, "", GetActiveStores, "tenant");
    getAllDesks();
    getAllDeliveryTypes();
    getAllCheckOutData(0, 10, "", "", 0, 0, 0, 0, "", "", 0);
    connect();
    socket.onmessage = function (event) {
      let receivedMessage = event.data;
      if (receivedMessage == "Checkin")
        toast.success(`New Check-In received`, { position: 'top-right' });
      getAllCheckOutData(0, 10, "", "", 0, 0, 0, 0, "", "", 0);
    };
  }, []);
  return (
    <>
      {isLoader ? (
        <div
          className="spinner spinner-center"
          style={{ marginLeft: "750px" }}
        ></div>
      ) : (
        <div className="track-deliveries">
          <div className="track-deliveries-header my-2">
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

              <div className="menubtn" style={{ padding: "20px" }}>
                <i
                  className="pi pi-align-justify"
                  style={{
                    fontSize: "1rem",
                    color: "#ae9d81",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    dispatch(setNavVisibilty({ sideNavVisibility: true }))
                  }
                ></i>
              </div>


              <h2>HF Track Deliveries</h2>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="mx-2">
                <Button
                  label="Export"
                  icon={"pi pi-file-export"}
                  style={{
                    backgroundColor: "transparent",
                    color: "#ae9d81",
                    border: "1px #ae9d81 solid",
                    borderRadius: "20px",
                    marginRight: "5px",
                    cursor: "pointer",
                    padding: "8px 14px 8px 14px"
                  }}
                  onClick={() => { onExportTrackDetails() }}
                />
              </div>
              <h2
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsFilterView(!isFilterView);
                }}
              >
                Filter{" "}
              </h2>
              <i
                onClick={() => {
                  setIsFilterView(!isFilterView);
                }}
                className="pi pi-angle-up"
                style={{
                  cursor: "pointer",
                  transform: isFilterView ? "rotate(180deg)" : "",
                  color: "#ae9d81",
                }}
              />
            </div>
          </div>
          <div className="track-deliveries-content ">
            {isFilterView && (
              <div className="track-deliveries-content-filters">
                <div className="input">
                  <div className="input-box">
                    <p>Check-In Number</p>
                    <AutoComplete
                      value={filterDetails?.checkIn}
                      suggestions={dropdownValues.checkIn}
                      completeMethod={(e) =>
                        searchItems(e, GetCheckInIds, "checkIn")
                      }
                      placeholder="Select here"
                      virtualScrollerOptions={{ itemSize: 38 }}
                      field="code"
                      dropdown
                      onChange={(e) => {
                        handleInputValue("checkIn", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p>Mobile Number</p>
                    <InputText
                      placeholder="Enter here"
                      value={filterDetails?.phoneNumber}
                      onChange={(e) =>
                        handleInputValue("phoneNumber", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p>Tenant Location</p>
                    <AutoComplete
                      value={filterDetails?.tenantLocation}
                      suggestions={dropdownValues.tenant}
                      completeMethod={(e) =>
                        searchItems(e, GetActiveStores, "tenant")
                      }
                      virtualScrollerOptions={{ itemSize: 38 }}
                      field="name"
                      placeholder="Select here"
                      dropdown
                      onChange={(e) => {
                        handleInputValue("tenantLocation", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p>Desk Location</p>
                    <Dropdown
                      options={dropdownValues?.desk}
                      value={filterDetails?.deskLocation}
                      optionLabel="name"
                      onChange={(e) =>
                        handleInputValue("deskLocation", e.target.value)
                      }
                      placeholder="Select here"
                    />
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p>Pickup Location</p>
                    <AutoComplete
                      value={filterDetails?.pickupLocation}
                      suggestions={dropdownValues.pickupLoacation}
                      completeMethod={(e) =>
                        searchItems(
                          e,
                          GetActivePickupLocation,
                          "pickupLoacation"
                        )
                      }
                      virtualScrollerOptions={{ itemSize: 38 }}
                      field="name"
                      placeholder="Select here"
                      dropdown
                      onChange={(e) => {
                        handleInputValue("pickupLocation", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p>Status</p>
                    <Dropdown
                      options={dropdownValues.status}
                      value={filterDetails?.status}
                      optionLabel="name"
                      onChange={(e) =>
                        handleInputValue("status", e.target.value)
                      }
                      placeholder="Select here"
                    />
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p>Start Date</p>
                    <Calendar
                      placeholder="Select date"
                      value={filterDetails?.startDate}
                      onChange={(e) =>
                        handleInputValue("startDate", e.target.value)
                      }
                      showIcon
                    />
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p>End Date</p>
                    <Calendar
                      placeholder="Select date"
                      value={filterDetails?.endDate}
                      onChange={(e) =>
                        handleInputValue("endDate", e.target.value)
                      }
                      showIcon
                    />
                  </div>
                </div>

                <div className="input">
                  <div className="input-box">
                    <p>Delivery Type</p>
                    <Dropdown
                      options={dropdownValues.deliveryType}
                      optionLabel="type"
                      value={filterDetails?.deliveryType}
                      onChange={(e) =>
                        handleInputValue("deliveryType", e.target.value)
                      }
                      placeholder="Select here"
                    />
                  </div>
                </div>
                <div className="input-btn">
                  <div className="input-box-search-btn">
                    <Button
                      label={"Search"}
                      style={{
                        backgroundColor: "#ae9d81",
                        border: "none",
                        borderRadius: "3px",
                        width: "120px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        if (getIsFilterNotEmpty()) {
                          handleSearch(0, 10);
                        }
                      }}
                    />
                    <Button
                      label={"Clear"}
                      style={{
                        backgroundColor: "#8e8e8e",
                        border: "none",
                        borderRadius: "3px",
                        width: "120px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        if (getIsFilterNotEmpty()) {
                          setFilterDetails({ ...initialFilterDetails });
                          getAllCheckOutData(
                            0,
                            10,
                            "",
                            "",
                            0,
                            0,
                            0,
                            0,
                            "",
                            "",
                            0
                          );
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            <div className="track-deliveries-table">
              <DataTable
                value={checkOutData}
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                tableStyle={{ width: "100%" }}
                stripedRows
                rows={10}
                emptyMessage={
                  <>
                    <p style={{ textAlign: "center" }}>List is empty</p>
                  </>
                }
              >
                <Column field="checkInNumber" header="Check-In Number" style={{ width: "10%" }}></Column>
                <Column field="username" header="Customer Name" style={{ width: "10%" }}></Column>
                <Column
                  field="deskOrTenant_Location"
                  header="Desk/Tenant Location"
                  style={{ width: "5%" }}
                ></Column>
                <Column
                  field="pickupLocation"
                  header="Pickup Location"
                  style={{ width: "10%" }}
                ></Column>
                <Column field="bagTagNumber" header="Baggage Tag" style={{ width: "10%" }}></Column>
                <Column field="totalBags" header="Number of Bags" style={{ width: "5%" }}></Column>
                <Column field="checkInType" header="Check-In Type" style={{ width: "5%" }}></Column>
                < Column
                  field="createdDate"
                  header="Created Date"
                  body={dateBodytemplate}
                  style={{ width: "10%" }}
                ></Column>
                <Column
                  field="status"
                  body={statusTemplate}
                  header="Status"
                  style={{ width: "5%" }}
                ></Column>
                < Column
                  // field="nationality"
                  header="Action"
                  body={actionButtonTemplate}
                  style={{ width: "5%" }}
                ></Column>
              </DataTable>
            </div>
            {checkOutData?.length ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <Paginator
                  first={first}
                  rows={10}
                  totalRecords={totalCount}
                  onPageChange={(e) => onPageChange(e, 1)}
                  template={{
                    layout:
                      "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                  }}
                  className="justify-content-start"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </div >
      )}
    </>
  );
};

export default TrackDeliveries;
