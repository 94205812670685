import React, { useEffect, useState } from "react";
import "./ChangePickup.scss";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { faSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { InputOtp } from "primereact/inputotp";
import {
  GetUpdateUserData,
  GetActiveAnmsPickupLocation,
  UpdatePickupDetails,
  GetAnmsFiles,
  DownloadAnmsFiles,
} from "../../../Services/ShopAndDropService";
import { status } from "../../Common/Config";
import { toast } from "react-toastify";
import CustomWebcam from "../../Camera/Camera";
import otpIcon from "../../../assets/images/OTP new.jpg";
import proofIcon from "../../../assets/images/id-card.png";
import { UploadBase64Files } from "../../../Services/Files";
import { AutoComplete } from "primereact/autocomplete";
import { Calendar } from "primereact/calendar";
import logo from "../../../assets/images/logo.jpg";
import UseCookies from "../../../Helpers/UseCookies";

const ChangePickup = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [isDelieveredConformationOpen, setIsDelieveredConformationOpen] =
    useState(false);
  const [checkoutDetails, setCheckoutDetails] = useState(null);
  const [cookies, setCookie, removeCookie] = UseCookies();
  const [imageUrl, setImageUrl] = useState("");
  const [btnLoading, setIsBtnLoading] = useState(false);
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [imgSrc, setImgSrc] = useState("");
  const [proofImageUrl, setProofImageUrl] = useState("");
  const [previewModal, setPreviewModal] = useState({
    isOpen: false,
    imageUrl: "",
  });
  const [pickupLocations, setPickupLocations] = useState([]);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [pickupDetails, setPickupDetails] = useState({
    location: null,
    time: null,
  });
  const [isErrmsgView, setIsErrMsgView] = useState(false);

  let encID = localStorage.getItem("encCheckIndetailsId");
  let encOrgId = localStorage.getItem("encOrgId");
  let encCheckInNumber = localStorage.getItem("encCheckInCode");

  //currStatus
  const currStatus = checkoutDetails?.statusInformation[0]?.name;

  // const isOutOfDelievry = checkoutDetails?.statusInformation?[0].?

  const params = useParams();

  let isInsideMall = params?.isInsidemall?.split(" ")?.pop() == "true";

  const navigate = useNavigate();

  const customInput = ({ events, props }) => (
    <input
      key={props?.key}
      {...events}
      {...props}
      type="text"
      className="custom-otp-input"
    />
  );

  const getCheckOutData = async () => {
    await GetUpdateUserData(encCheckInNumber, encOrgId)
      ?.then((res) => {
        if (res.status == 200) {
          setCheckoutDetails({ ...res?.data?.response });
          let time = res?.data?.response?.deliveryInformation?.pickupTime;
          let hour = time ? time?.split(":")[0] : "";
          let minute = time ? time?.split(":")[1] : "";

          const initialTime = new Date();
          initialTime.setHours(hour, minute, 0, 0); // Set the time to 12:00:00.000
          console.log("initialTime", initialTime);
          setPickupDetails({
            location: {
              id: res?.data?.response?.deliveryInformation?.pickupLocationId,
              name: res?.data?.response?.deliveryInformation?.pickupLocation,
            },
            time: initialTime,
          });
        }
      })
      .catch((err) => console.log("err", err))
      .finally(() => setIsLoader(false));
  };

  const getSignature = async () => {
    await GetAnmsFiles(encID, "VIPSignature")?.then(async (res) => {
      if (res.status == 200) {
        let base64Url = res?.data[0]?.blobUrl;
        if (base64Url) {
          await DownloadAnmsFiles(res?.data[0]?.fileName, base64Url)
            ?.then((response) => {
              console.log("response", response);
              if (response.status == 200) {
                setImageUrl(
                  "data:" +
                  res?.data[0]?.fileName +
                  ";base64," +
                  response?.data?.fileBuffer
                );
              }
            })
            .finally(() => {
              getCheckOutData();
            });
        } else {
          getCheckOutData();
        }
      } else {
        getCheckOutData();
      }
    });
  };

  const handleDownload = async (moduleName) => {
    let data = {
      id: encID,
      module: moduleName,
    };

    await GetAnmsFiles(encID, moduleName)?.then(async (res) => {
      if (res.status == 200) {
        let base64Url = res?.data[0]?.blobUrl;
        if (base64Url) {
          await DownloadAnmsFiles(res?.data[0]?.fileName, base64Url)?.then(
            (response) => {
              if (res.status == 200) {
                const link = document.createElement("a");
                link.href =
                  "data:" +
                  res?.data[0]?.fileName +
                  ";base64," +
                  response?.data?.fileBuffer;
                link.setAttribute("download", res?.data[0]?.fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
              }
            }
          );
        }
      }
    });
  };

  const getImage = (imageUrl) => {
    setProofImageUrl(imageUrl);
    // handleAddProof(imageUrl);
  };

  const handleViewPdf = async (moduleName) => {
    setIsLoader(true);
    await GetAnmsFiles(encID, moduleName)?.then(async (res) => {
      if (res.status == 200) {
        let base64Url = res?.data[0]?.blobUrl;
        if (base64Url) {
          await DownloadAnmsFiles(res?.data[0]?.fileName, base64Url)?.then(
            (response) => {
              if (res.status == 200) {
                setPreviewModal({
                  isOpen: true,
                  imageUrl:
                    "data:" +
                    res?.data[0]?.fileName +
                    ";base64," +
                    response?.data?.fileBuffer,
                });
                setIsLoader(false);
              } else {
                setIsLoader(false);
              }
            }
          );
        } else {
          setIsLoader(false);
        }
      } else {
        setIsLoader(false);
      }
    });
  };

  const searchItems = (event) => {
    let query = event ? event?.query : "";
    getAllPickLoactions(query);
  };

  const getAllPickLoactions = async (searchTerm) => {
    await GetActiveAnmsPickupLocation(0, 100, searchTerm, encOrgId)?.then(
      (res) => {
        if (res?.status == 200) {
          setPickupLocations(res?.data?.data);
        }
      }
    );
  };

  const handleUpdate = async () => {
    setIsLoader(true);

    let encPickupLocationID = btoa(pickupDetails?.location?.id);
    let enctPickupTime = pickupDetails?.time
      ? btoa(
        new Date(pickupDetails?.time).getHours() +
        ":" +
        new Date(pickupDetails?.time).getMinutes()
      )
      : "";

    let data = {
      encCheckInId: encID,
      encPickupLocationID: encPickupLocationID,
      encPickupTime: enctPickupTime,
    };

    await UpdatePickupDetails(data)
      .then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          localStorage.removeItem("checkInDetailsId");
          localStorage.removeItem("orgId");
          localStorage.removeItem("encCheckinDetailsid");
          localStorage.removeItem("encOrgId");
          localStorage.removeItem("encCheckInCode");
          toast.success("Pickup details updated successfully");
          navigate("/guest-login");
        } else {
          toast.error(
            "There is issue occured update, could you please try again",
            {
              position: "top-right",
            }
          );
        }
      })
      .finally((res) => {
        setIsBtnLoading(false);
        setIsLoader(false);
      });
  };

  useEffect(() => {
    setIsLoader(true);
    getAllPickLoactions("");
    getSignature();
  }, []);

  return (
    <>
      {isLoader ? (
        <div
          className="spinner spinner-center"
          style={{ marginLeft: "750px" }}
        ></div>
      ) : (
        <>
          <div
            className="sm:col-4 md:col-4 lg:col-2"
            style={{
              //  width: '14%',
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          // className="logo"
          >
            <div className="image-log-mg">
              <img src={logo} alt="logo" style={{ width: "100%" }} />
            </div>
          </div>
          <div className="track-delivery-view change-pickup">
            <div className="track-delivery-view-heading"></div>
            <div className="track-delivery-content">
              <div className="track-delivery-user-details">
                <div
                  className="header"
                  style={{ height: "auto", paddingBottom: "10px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexGrow: "3",
                      justifyContent: "flex-start",
                      color: "#ae9d81",
                    }}
                    className="flex align-items-center org"
                  >
                    {/* <i
                      className="pi pi-arrow-circle-left nav-back-btn"
                      onClick={() => {
                        navigate("/shop-and-drop-track-deliveries");
                      }}
                      style={{
                        cursor: "pointer",
                        fontSize: "1.5rem",
                      }}
                    /> */}
                    <div className="heading" style={{ paddingLeft: "10px" }}>
                      <h2 className="text-center">Delivery details</h2>
                    </div>
                  </div>
                </div>
                <div className="input">
                  <div className="input-label">
                    <p>First name</p>
                  </div>
                  <div className="input-text">
                    <p>: {checkoutDetails?.firstName}</p>
                  </div>
                </div>
                <div className="input">
                  <div className="input-label">
                    <p>Last name</p>
                  </div>
                  <div className="input-text">
                    <p>: {checkoutDetails?.lastName}</p>
                  </div>
                </div>
                <div className="input">
                  <div className="input-label">
                    <p>Mobile number</p>
                  </div>
                  <div className="input-text">
                    <p>: {checkoutDetails?.phoneNumber}</p>
                  </div>
                </div>
                <div className="input">
                  <div className="input-label">
                    <p>Email id</p>
                  </div>
                  <div className="input-text">
                    <p>: {checkoutDetails?.emailId}</p>
                  </div>
                </div>
                {checkoutDetails?.deliveryInformation?.tenantId && (
                  <div className="input">
                    <div className="input-label">
                      <p>Tenent location</p>
                    </div>
                    <div className="input-text">
                      <p>: {checkoutDetails?.deliveryInformation?.tenant}</p>
                    </div>
                  </div>
                )}
                {checkoutDetails?.deliveryInformation?.deskId && (
                  <div className="input">
                    <div className="input-label">
                      <p>Desk location</p>
                    </div>
                    <div className="input-text">
                      <p>: {checkoutDetails?.deliveryInformation?.desk}</p>
                    </div>
                  </div>
                )}

                {/* <div className="input">
                  <div className="input-label">
                    <p>Number of bags </p>
                  </div>
                  <div className="input-text">
                    <p>: {checkoutDetails?.bagInformation?.length}</p>
                  </div>
                </div> */}
                <div className="input">
                  <div className="input-label">
                    <p>Baggage tag </p>
                  </div>
                  <div className="input-text">
                    <p>: {checkoutDetails?.baggageTag}</p>
                  </div>
                </div>

                <div className="input">
                  <div className="input-label">
                    <p>Status </p>
                  </div>
                  <div className="input-text">
                    <p>: {currStatus}</p>
                  </div>
                </div>
                <div className="input">
                  <div className="input-label">
                    <p>Proof </p>
                  </div>
                  <div className="input-text">
                    <p
                      onClick={() => { }}
                      style={{ display: "flex", alignItems: "flex-start" }}
                    >
                      : {checkoutDetails?.checkInProofs[0]?.proofType}{" "}
                      <i
                        className="pi pi-eye"
                        onClick={() => {
                          // handleDownload();
                          handleViewPdf("VIPCheckInProof");
                        }}
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5rem",
                          paddingLeft: "5px",
                        }}
                      />
                      <i
                        className="pi pi-download"
                        onClick={() => {
                          handleDownload("VIPCheckInProof");
                        }}
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5rem",
                          paddingLeft: "5px",
                        }}
                      />
                    </p>
                  </div>
                </div>
                {checkoutDetails?.deliveryInformation?.isDeliveredByOTP ==
                  false && (
                    <div className="input">
                      <div className="input-label">
                        <p>Delivery proof </p>
                      </div>
                      <div className="input-text">
                        <p
                          onClick={() => { }}
                          style={{ display: "flex", alignItems: "flex-start" }}
                        >
                          :
                          <i
                            className="pi pi-eye"
                            onClick={() => {
                              // handleDownload();
                              handleViewPdf("VIPInsideMallDeliveryProof");
                            }}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.5rem",
                              paddingLeft: "5px",
                            }}
                          />
                          <i
                            className="pi pi-download"
                            onClick={() => {
                              // handleDownload();
                              handleDownload("VIPInsideMallDeliveryProof");
                            }}
                            style={{
                              cursor: "pointer",
                              fontSize: "1.5rem",
                              paddingLeft: "5px",
                            }}
                          />
                        </p>
                      </div>
                    </div>
                  )}
              </div>
              <div className="track-delivery-view-bag-list-table">
                <div
                  className="header"
                  style={{
                    height: "auto",
                    marginBottom: "10px",
                    color: "#ae9d81",
                  }}
                >
                  <h2 className="text-center">Bag list</h2>
                </div>
                <div className="track-delivery-view-bag-list-table-data">
                  <div className="bag-list-table" style={{ height: "280px" }}>
                    <DataTable
                      value={checkoutDetails?.bagInformation}
                      tableStyle={{ width: "100%" }}
                      stripedRows
                      rows={10}
                      emptyMessage={
                        <>
                          <p style={{ textAlign: "center" }}></p>
                        </>
                      }
                    >
                      <Column
                        field="name"
                        header="Store name"
                        style={{ width: "35%" }}
                      ></Column>
                      <Column
                        field="receiptAmount"
                        header="Receipt amount"
                        style={{ width: "35%" }}
                      ></Column>
                      <Column
                        field="numberOfBags"
                        header="Number of bags"
                        style={{ width: "30%" }}
                      ></Column>
                    </DataTable>
                  </div>
                  <div className="bag-list-amount">
                    <div>
                      <span style={{ color: "#ae9d81", fontWeight: 700 }}>
                        Total amount :{" "}
                      </span>
                      <span>{checkoutDetails?.totalAmount}</span>
                    </div>
                    <div>
                      <span style={{ color: "#ae9d81", fontWeight: 700 }}>
                        Total bags :{" "}
                      </span>
                      <span>{checkoutDetails?.totalBags}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="track-delivery-view-bag-list"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{ display: "flex" }}
                className="track-delivery-view-bag-list-input"
              >
                <div className="input">
                  <div className="input-box">
                    <p>Pickup location</p>

                    <AutoComplete
                      placeholder="Select here"
                      value={pickupDetails?.location}
                      suggestions={pickupLocations}
                      completeMethod={(e) => searchItems(e)}
                      virtualScrollerOptions={{ itemSize: 38 }}
                      field="name"
                      dropdown
                      onChange={(e) => {
                        setPickupDetails({
                          ...pickupDetails,
                          location: e?.value,
                        });
                      }}
                    />
                    {isErrmsgView && !pickupDetails?.location && (
                      <p className="err-msg">Pickup location is required</p>
                    )}
                  </div>
                </div>
                <div className="input">
                  <div className="input-box">
                    <p>Pickup time</p>
                    <Calendar
                      value={pickupDetails?.time}
                      onChange={(e) => {
                        setPickupDetails({
                          ...pickupDetails,
                          time: e?.value,
                        });
                      }}
                      showIcon
                      timeOnly
                      icon={() => <i className="pi pi-clock" />}
                    />
                    {isErrmsgView && !pickupDetails?.time && (
                      <p className="err-msg">Pickup time is required</p>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="track-delivery-view-bag-list-delievry"
                style={{ width: "30%" }}
              >
                <div className="track-delivery-view-bag-list-delievry-content">
                  {/* {!isInsideMall && (
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <p>
                        <span style={{ color: "#ae9d81", fontWeight: 700 }}>
                          Paid amount as :
                        </span>{" "}
                        {checkoutDetails?.deliveryInformation?.payableAmount}{" "}
                        BHD
                      </p>
                    </div>
                  )} */}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      margin: "10px 0px",
                    }}
                  >
                    <div style={{ width: "100px", height: "50px" }}>
                      <img
                        style={{ width: "100%", height: "100%" }}
                        src={imageUrl}
                      ></img>
                    </div>
                  </div>
                  <div className="track-delivery-view-bag-list-delievryBtn">
                    <Button
                      label={"Update"}
                      style={{
                        backgroundColor: "#ae9d81",
                        border: "none",
                        borderRadius: "3px",
                        width: "120px",
                        height: "40px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onClick={() => {
                        let isValidation =
                          pickupDetails?.location && pickupDetails?.time;
                        setIsErrMsgView(!isValidation);
                        if (isValidation) {
                          setIsConfirmOpen(true);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Confirmation
              </h3>
            }
            visible={isConfirmOpen}
            style={{ width: "30%" }}
            onHide={() => {
              setIsConfirmOpen(false);
            }}
          >
            <div className="track-delivery-view-success-modal">
              <div className="success-modal-content">
                <p style={{ fontSize: "18px", textAlign: "center" }}>
                  Are you sure want to change the pickup location and time?
                </p>
              </div>
              <div className="success-modal-btn" style={{ gap: "10px" }}>
                <Button
                  label={"No"}
                  style={{
                    backgroundColor: "#8e8e8e",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsConfirmOpen(false);
                  }}
                />
                <Button
                  label={"Yes"}
                  loading={btnLoading}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsBtnLoading(true);
                    handleUpdate();
                  }}
                />
              </div>
            </div>
          </Dialog>
          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Preview
              </h3>
            }
            visible={previewModal?.isOpen}
            style={{ width: "50%" }}
            onHide={() => {
              setPreviewModal({
                isOpen: false,
                imageUrl: "",
              });
            }}
          >
            <div className="success-modal">
              <div className="success-modal-content">
                <div
                  style={{
                    width: "85%",
                    margin: "auto",
                    height: "410px",
                    overflowY: "auto",
                  }}
                >
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={previewModal?.imageUrl}
                  ></img>
                </div>
              </div>
            </div>
          </Dialog>
          {isCameraVisible && (
            <CustomWebcam
              setIsVisible={setIsCameraVisible}
              setImage={setImgSrc}
              getImage={getImage}
            />
          )}
        </>
      )}
    </>
  );
};

export default ChangePickup;
