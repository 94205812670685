import React, { useEffect, useState } from 'react'
import UseCookies from './UseCookies';
import { SocketConnection as socket } from '../App';
import { toast } from 'react-toastify';
export default function Socket() {

    const [cookies, setCookie, removeCookie] = UseCookies();
    const [sockketState, setSockketState] = useState(false);



    const connectSockket = () => {
        console.log(socket);

        socket.onopen = function (socketResult) {
            console.log(socketResult);
            setSockketState(true);
            sendMessage(atob(cookies()?.userId));
            toast.success(`Sockket connected `, { position: "top-right" });

        };
    };
    const sendMessage = (msg) => {
        socket.send(msg);

    }

    const closeSockket = () => {
        // socket.onclose = function () {
        //     //console.log("WebSocket connection closed with socket.");
        // }
    };
    useEffect(() => {

        // socket.onmessage = function (event) {
        //     let receivedMessage = event.data; // This will receive the socket ID
        //     // console.log("Received Socket ID from server: " + socketId);
        //     toast.success(`${receivedMessage}`, { position: 'top-right' });
        // };

    }, [sockketState])

    return [connectSockket, closeSockket, sendMessage, socket]
}
