import React, { useRef, useState, useEffect } from "react";
import "./GuestInforMation.scss";
import { useDispatch, useSelector } from "react-redux";
import UseCookies from "../../../Helpers/UseCookies";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import { delivery, stepperNames } from "../../Common/Config";
//prime react
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";

//camera
import CustomWebcam from "../../Camera/Camera";

//redux

import {
  GetProofTypes,
  GetGuestInfo,
} from "../../../Services/ShopAndDropService";
import { current } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

const GuestInforMation = (props) => {
  //states

  const navigation = useNavigate();
  const [imgSrc, setImgSrc] = useState(
    props.shopAndDropDeliveryDetails.proofImageUrl
  );
  const localizationValue = "en";
  const [isCameraVisible, setIsCameraVisible] = useState(false);
  const [proofTypes, setProofTypes] = useState([]);
  const [cookies, setCookie, removeCookie] = UseCookies();
  const [isErrMsgView, setIsErrMsgView] = useState(false);
  const dispatch = useDispatch();
  const [isModelOpen, setIsModalOpen] = useState(false);
  const inputRef = useRef(null);
  const [isTermsAndConditions, setIsTermAndCondition] = useState(false);
  const [isConformationOpen, setIsConformationOpen] = useState(false);
  // const [isExisitingCustomer, setIsExisitingCustomer] = useState(false);

  // const initialGuestInformation = {
  //   phoneNumber: shopAndDropDeliveryDetails.phoneNumber,
  //   firstName: shopAndDropDeliveryDetails.firstName,
  //   lastName: shopAndDropDeliveryDetails.lastName,
  //   emailId: shopAndDropDeliveryDetails.emailId,
  //   proof: shopAndDropDeliveryDetails.proof,
  //   receiveEmaarEmails: shopAndDropDeliveryDetails.receiveEmaarEmails,
  //   isTermsAndConditions: false,
  //   proofImageUrl: shopAndDropDeliveryDetails.proofImageUrl,
  // };

  const userInformations = props.shopAndDropDeliveryDetails;

  // mail validation

  const emailValidation = () => {
    var emailFormat = /^([^\s@]+)@([A-Za-z])+\.([A-Za-z])+$/;
    let isValidEmail = emailFormat.test(userInformations.emailId);
    return isValidEmail;
  };

  //continue

  const handleContinue = () => {
    let isValidation =
      userInformations.firstName &&
      userInformations.lastName &&
      // userInformations.emailId &&
      userInformations.phoneNumber &&
      userInformations.proof &&
      userInformations.proofImageUrl &&
      userInformations.isTermsAndConditions &&
      (userInformations.emailId ? emailValidation() : true);

    setIsErrMsgView(!isValidation);
    if (isValidation) {
      props.handleStepChange({
        stepLabel: stepperNames.bagInformation,
        index: 1,
      });
    }
  };

  // input values

  const handleInputValue = (key, value) => {
    props.handleInputValue(key, value);
  };

  const getAllProofTypes = async () => {
    await GetProofTypes(cookies()?.bearerToken)?.then((res) => {
      if (res?.status == 200) {
        setProofTypes(res?.data);
      }
    });
  };
  const getImage = (imageSrc) => {
    props.handleInputValue("proofImageUrl", imageSrc);
  };

  const handlePhoneChange = (value, countryData) => {
    if (
      value?.length <= 12 &&
      /^[0-9]+$|^$/.test(value) &&
      value !== userInformations?.phoneNumber
    ) {
      props.setShopAndDropDeliveryDetails({
        ...userInformations,
        phoneNumber: value,
        phoneNumberCode: countryData?.dialCode,
      });
    }
    // setPhoneNumber(value);
  };

  const getGuestInfo = async (mobileNumber) => {
    let isExsists = false;

    await GetGuestInfo(cookies()?.bearerToken, userInformations?.phoneNumber)
      ?.then((res) => {
        if (res?.status == 200) {
          let guestInfo = res?.data?.response;
          console.log("guestInfo", guestInfo);
          if (guestInfo) {
            isExsists = true;
            props.setShopAndDropDeliveryDetails({
              ...userInformations,
              firstName: guestInfo?.firstName,
              lastName: guestInfo?.lastName,
              emailId: guestInfo?.emailId,
              phoneNumberCode: guestInfo?.countryCode,
              proof: null,
              proofImageUrl: "",
            });
          } else {
            props.setShopAndDropDeliveryDetails({
              ...userInformations,
              firstName: "",
              lastName: "",
              emailId: "",
              proof: null,
              proofImageUrl: "",
            });
          }
        } else {
          props.setShopAndDropDeliveryDetails({
            ...userInformations,
            firstName: "",
            lastName: "",
            emailId: "",
            proof: null,
            proofImageUrl: "",
          });
        }
      })
      .finally(() => {
        props.setIsExistingUser(isExsists);
      });
  };

  const getSelectedCountryName = () => {

    //    console.log("geteeeee",userInformations);



    let selectedCountry = "bh";
    if (inputRef?.current?.countries && userInformations?.phoneNumberCode) {
      if (userInformations?.phoneNumberCode?.split("+")?.length > 1) {
        selectedCountry = [...inputRef?.current?.countries]?.find(
          (val) => val.dialCode === userInformations?.phoneNumberCode
        )?.iso2;
      } else {
        selectedCountry = [...inputRef?.current?.countries]?.find(
          (val) => val.dialCode === userInformations?.phoneNumberCode.split("+")[1]
        )?.iso2;
      }
    }
    console.log("selectedCountry", selectedCountry);
    return selectedCountry ? selectedCountry : "bh";
  };

  useEffect(() => {
    const element = document.getElementById("top");
    element.scrollIntoView();
    getAllProofTypes();
  }, []);

  // useEffect(() => {
  //   if (userInformations?.phoneNumber) {
  //     handleInputValue(
  //       "phoneNumberCode",
  //       inputRef?.current?.selectedCountryData?.dialCode
  //     );
  //   }
  // }, [inputRef?.current?.selectedCountryData?.dialCode]);

  return (
    <>
      <Dialog visible={isTermsAndConditions} style={{ width: "100%" }} onHide={() => setIsTermAndCondition(false)} >
        <iframe src="https://marassigalleria.bh/en/hands-free-service/" style={{ width: "100%", height: 500 }}></iframe>
      </Dialog>

      <Dialog
        header={
          <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0, paddingBottom: "0px" }}>
            Confirmation
          </h3>
        }
        visible={isConformationOpen}
        style={{ width: "30%" }}
        onHide={() => {
          setIsConformationOpen(false);
        }}
      >
        <div className="track-delivery-view-success-modal">
          <div className="success-modal-content">
            <p style={{ fontSize: "18px", marginBottom: "24px", marginTop: "0px" }}>
              Are you sure want to cancel check-in process?
            </p>
          </div>
          <div className="success-modal-btn" style={{ gap: "10px" }}>
            <Button
              label={"No"}
              style={{
                backgroundColor: "#8e8e8e",
                border: "none",
                borderRadius: "3px",
                width: "120px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setIsConformationOpen(false);
              }}
            />
            <Button
              label={"Yes"}
              style={{
                backgroundColor: "#ae9d81",
                border: "none",
                borderRadius: "3px",
                width: "120px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                navigation("/checkin-request");
              }}
            />
          </div>
        </div>
      </Dialog>
      <div className="guest-information" id="top">
        <div className="guest-information-heading">
          <h2>Guest Information</h2>
        </div>
        <div className="guest-information-content">
          <div className="input">
            <div className="input-box mobile-number">
              <p>
                Mobile number
                <span style={{ color: "red" }}>*</span>
              </p>
              {/* <InputText
                type="number"
                placeholder="Enter here"
                value={userInformations?.phoneNumber}
                onChange={(e) =>
                  handleInputValue("phoneNumber", e.target.value)
                }
              /> */}
              <IntlTelInput
                // className='mg_care__input'
                style={{ width: "100%" }}
                value={userInformations.phoneNumber}
                noCountryDataHandler={(e) => console.log(e)}
                disabled={props.isExisitingUser || props.isExisitingGuestUser}
                containerClassName="intl-tel-input phoneInputContainerStyle"
                inputClassName={""}
                onSelectFlag={(value, countryData) => {
                  console.log(countryData);
                  handleInputValue("phoneNumberCode", "+" + countryData?.dialCode);
                }}
                onPhoneNumberChange={(
                  valid,
                  value,
                  countryData,
                  number,
                  id
                ) => {
                  if (!props.isCheckedIn) {
                    handlePhoneChange(value, countryData);
                  }
                  if (!userInformations?.phoneNumber) {
                    props.setIsCheckedIn(false);
                  }
                }}
                // countryData={getSelectedCountryData()}
                // initialCountry={userInformations?.phoneNumberCode}
                defaultCountry={getSelectedCountryName()}
                // tempCountry="bh"
                preferredCountries={["bh", "ae"]}
                ref={inputRef}
                onPhoneNumberBlur={() => getGuestInfo()}
              />
              {isErrMsgView && !userInformations?.phoneNumber && (
                <p className="err-msg">Mobile number is required</p>
              )}
            </div>
          </div>
          <div className="input">
            <div className="input-box">
              <p>
                First name
                <span style={{ color: "red" }}>*</span>
              </p>
              <InputText
                placeholder="Enter here"
                value={userInformations?.firstName}
                disabled={props.isExisitingUser || props.isExisitingGuestUser}
                onChange={(e) => {
                  if (e.target.value.length <= 30) {
                    handleInputValue("firstName", e.target.value);
                  }
                }}
              />
              {isErrMsgView && !userInformations?.firstName && (
                <p className="err-msg">First name is required</p>
              )}
            </div>
          </div>
          <div className="input">
            <div className="input-box">
              <p>
                Last name
                <span style={{ color: "red" }}>*</span>
              </p>
              <InputText
                placeholder="Enter here"
                disabled={props.isExisitingUser || props.isExisitingGuestUser}
                value={userInformations?.lastName}
                onChange={(e) => {
                  if (e.target.value.length <= 30) {
                    handleInputValue("lastName", e.target.value);
                  }
                }}
              />
              {isErrMsgView && !userInformations?.lastName && (
                <p className="err-msg">Last name is required</p>
              )}
            </div>
          </div>
          <div className="input">
            <div className="input-box">
              <p>
                Email id
                {/* <span style={{ color: "red" }}>*</span> */}
              </p>
              <InputText
                placeholder="Enter here"
                disabled={props.isExisitingUser || props.isExisitingGuestUser}
                value={userInformations?.emailId}
                onChange={(e) => {
                  if (e.target.value.length <= 30) {
                    handleInputValue("emailId", e.target.value);
                  }
                }}
              />
              {isErrMsgView && (
                <p className="err-msg">
                  {userInformations?.emailId
                    ? !emailValidation()
                      ? "Enter a valid email id"
                      : "" : ""}
                </p>
              )}
            </div>
          </div>

          <div className="input">
            <div className="input-box">
              <p>
                Proof type
                <span style={{ color: "red" }}>*</span>
              </p>
              <Dropdown
                value={userInformations?.proof}
                onChange={(e) => { handleInputValue("proof", e.target.value); if (userInformations.proofImageUrl) { handleInputValue("proofImageUrl", "") } }}
                options={proofTypes}
                optionLabel="name"
                placeholder="Select here"
              />
              {isErrMsgView && !userInformations?.proof && (
                <p className="err-msg">Proof type is required</p>
              )}
            </div>
          </div>
          {userInformations?.proof && (
            <>
              {userInformations.proofImageUrl ? (
                <div className="input">
                  <div className="input-box">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      {/* {userInformations?.proof?.name} */}

                      <i
                        className="pi pi-paperclip"
                        style={{
                          color: "#ae9d81",
                          cursor: "pointer",
                          fontSize: "20px",
                        }}
                        onClick={() => {
                          setIsModalOpen(true);
                        }}
                      ></i>
                      Attachment
                      <i
                        className="pi pi-times"
                        style={{
                          color: "red",
                          cursor: "pointer",
                          fontSize: "15px",
                        }}
                        onClick={() => {
                          handleInputValue("proofImageUrl", "");
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="input">
                  <div className="input-box">
                    <i
                      className="pi pi-camera"
                      style={{
                        color: "#ae9d81",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                      onClick={() => {
                        setIsCameraVisible(true);
                      }}
                    ></i>
                    {isErrMsgView && !userInformations?.proofImageUrl && (
                      <p className="err-msg">Proof image is required</p>
                    )}
                  </div>
                </div>
              )}
            </>
          )}

          <div className="check">
            <div className="check-box">
              <Checkbox
                inputId="EmmarEmails"
                checked={userInformations?.receiveEmaarEmails}
                onChange={(e) =>
                  handleInputValue("receiveEmaarEmails", e.target.checked)
                }
              />
              <label style={{ cursor: "pointer" }} htmlFor="EmmarEmails">
                I'd like to receive Marassi Galleria Mall emails (optional)
              </label>
            </div>
          </div>
          <div className="check">
            <div className="check-box">
              <Checkbox
                inputId="TermsAndConditions"
                checked={userInformations?.isTermsAndConditions}
                onChange={(e) =>
                  handleInputValue("isTermsAndConditions", e.target.checked)
                }
              />
              <p style={{ cursor: "pointer" }} htmlFor="TermsAndConditions">
                By using this service you acknowledge and agree that you have
                read understood and consent to these{" "}
                <span style={{ color: "blue" }} onClick={() => setIsTermAndCondition(true)}> Terms and Conditions</span> agree to be bound by them.
              </p>
            </div>
            {isErrMsgView && !userInformations?.isTermsAndConditions && (
              <p className="err-msg" style={{ color: "red" }}>
                Terms and conditions is required
              </p>
            )}
          </div>
        </div>
        <div className="bag-btn">
          {props?.isBackActive && (
            <Button
              label="Cancel"
              style={{
                backgroundColor: "#ccc",
                border: "none",
                borderRadius: "3px",
                width: "120px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "10px",
                marginRight: "20px"
              }}
              onClick={() => {
                setIsConformationOpen(true);
              }}
            />
          )
          }
          <Button
            label="Next"
            style={{
              backgroundColor: "#ae9d81",
              border: "none",
              borderRadius: "3px",
              width: "120px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "10px",
            }}
            onClick={() => {
              handleContinue();
            }}
          />
        </div>
      </div>
      {isCameraVisible && (
        <CustomWebcam
          setIsVisible={setIsCameraVisible}
          setImage={setImgSrc}
          getImage={getImage}
        />
      )}
      <Dialog
        header={
          <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
            Proof preview
          </h3>
        }
        visible={isModelOpen}
        style={{ width: "50%" }}
        onHide={() => {
          setIsModalOpen(false);
        }}
      >
        <div className="success-modal">
          <div className="success-modal-content">
            <div
              style={{
                width: "85%",
                margin: "auto",
                height: "410px",
                overflowY: "auto",
              }}
            >
              <img
                style={{ width: "100%", height: "auto" }}
                src={userInformations.proofImageUrl}
              ></img>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default GuestInforMation;
