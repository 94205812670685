import Axios from '../Helpers/Axios';

export function GetDepartmentList(take, skip, token, searchTerm, isActiveDepartments, showAll, status) {
    // return Axios(`Department/GetDepartments?skip=${skip}&take=${take}&searchTerm=${searchTerm||""}${isActiveDepartments?`&isActiveDepartments=${isActiveDepartments||""}`:""}${(status||isActiveDepartments||searchTerm)?`&showAll=${showAll}`:""}${status?`&status=${status||""}`:""}&forExpot=false`, 'get', null, token)
    return Axios(`Department/GetDepartments?skip=${skip}&take=${take}&searchTerm=${searchTerm || ""}${status ? `&showAll=${showAll}&status=${status || ""}` : ""}&forExpot=false`, 'get', null, token)
}

export function GetDepartmentSearchedList(take, skip, searchValue, token) {
    return Axios(`Department/GetDepartments?skip=${skip}&take=${take}&searchTerm=${searchValue}`, 'get', null, token)
}
export function AddDepartment(data, token) {
    return Axios(`Department/CreateDepartment`, 'post', data, token)
}
export function EditDepartment(data, token) {
    return Axios(`Department/UpdateDepartment`, 'patch', data, token)
}
export function GetDepartmentListByOrganization(id, token) {
    return Axios(`Master/Desk?organizationId=${id}`, "get", null, token)
}
export function GetActiveDepartments(token) {
    return Axios(`Department/GetActiveDepartments`, "get", null, token)
}
export function ImportDepartment(data, token) {
    return Axios(`Excel/BulkImportDepartment`, "post", data, token, true)
}
export function ImportDepartmentCategory(data, token) {
    return Axios(`Excel/BulkImportCategory`, "post", data, token, true)
}