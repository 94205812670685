import React, { useState, useEffect } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import { AddItemList, GetItemList, EditItemList, EditSubItem, ImportItems, GetItemPriceList, GetActiveItems } from '../../Services/ItemsService';
// import './style.scss'


// -------------Imports---------------
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import style from './style.module.scss';
import classNames from 'classnames';
import { ToastContainer, toast } from 'react-toastify'
import { FileUpload } from 'primereact/fileupload';
import { Paginator } from 'primereact/paginator';


// --------------File Import------------------
import Itemcsv from '../../assets/files/Item.csv'


import { useNavigate } from 'react-router-dom'

// -----------------------------------


// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------


//-----------------Services Imports----------
import { GetOrganizationList } from '../../Services/OrganizationService';
import { GetDeskBasedOnOrganization, GetDeskList } from '../../Services/DeskService';
import { GetSubItemsForItems, AddPriceItem, EditPriceItem } from '../../Services/ItemsService';
//----------------------------------------------

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { addOrganizationList, addDeskList, setNavVisibilty } from '../../Redux/AppSlice';
import { set } from 'immutable';
import { TabPanel, TabView } from 'primereact/tabview';
import { Calendar } from 'primereact/calendar';
import { Value } from 'sass';
import { exportDataInExcel, exportExcel, exportFilterdExcel, isShow } from '../../Services/ExportServices';
import Modalinfo from '../utili/Modalinfo';
import { status } from '../Common/Config';
//----------------------------------------
function Items() {

    const [cookies, setCookie, removeCookie] = UseCookies();
    const [itemsList, setItemList] = useState([]);
    const [activeItemsList, setActiveItemsLIst] = useState([]);
    const [itemsPriceList, setItemPriceList] = useState([]);
    const Context = useSelector((state) => state.AppContext);
    const dispatch = useDispatch();
    const [item, setItem] = useState({
        itemName: "",
        itemStatus: true,
        itemQuantity: 1,
        itemId: "",
        itemOrganization: "",
        itemDesk: "",
        itemCodes: [
            {
                "code": "",
                "isDeleted": true
            }
        ]
    });

    const [organizationList, setOrganizationList] = useState();
    const [deskList, setDeskList] = useState();
    const [isAddItem, setIsAddItem] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const navigate = useNavigate();
    const [activeIndex, setActiveIndex] = useState(0);
    const [isInvalid, setIsInvalid] = useState(false);
    const [isPriceInvalid, setIsPriceInvalid] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [pricingFilterValue, setPricingFilterValue] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [price, setPrice] = useState("")
    const [itemCategory, setItemCatogory] = useState({
        "id": 0,
        "name": ""
    })
    const [isImportInvalid, setIsImportInvalid] = useState(false);
    const [isImportInvalidFile, setIsImportInvalidFile] = useState(false);
    const [isImport, setIsImport] = useState(false);
    const [isFileValidating, setFileValidating] = useState(false);


    // Pagination
    const [first, setFirst] = useState(0)
    const [totalCount, setTotalRecords] = useState(0)
    const [firstPricing, setFirstPricing] = useState(0)
    const [totalPriceCount, setTotalRPriceecords] = useState(0)


    // Export Filter
    const [exportFilterDialog, setExportFilterDialog] = useState(false);
    const [itemPricingExportFilterDialog, setItemPricingExportFilterDialog] = useState(false);
    const [exportDetails, setExportDetails] = useState({
        startDate: "",
        endDate: "",
        organization: "",
        desk: "",
        status: true,
        searchTerm: "",
    })

    const [filterObj, setFilterObj] = useState({
        status: "",
        organization: ""
    })




    const [priceList, setPriceList] = useState([]);
    const [isAddPrice, setIsAddPrice] = useState(false);
    const [activeCategory, setActiveCategory] = useState({
    })
    const [priceEffectiveDate, setPriceEffectiveDate] = useState("")
    const [editPriceEffectiveDate, seteditPriceEffectiveDate] = useState("")
    const onCancelClick = () => {
        setShowConfirmation(true);
    };

    const onHide = () => {
        setShowConfirmation(false);
        setIsAddItem(false);
        setItem({
            itemName: "",
            itemStatus: true,
            itemQuantity: 1,
            itemId: "",
            itemOrganization: "",
            itemDesk: "",
            itemCodes: [
                {
                    "code": "",
                    "isDeleted": true
                }
            ]
        });
    };

    const handleConfirmation = (confirmed) => {
        if (confirmed) {
            onHide();
        } else {
            setShowConfirmation(false);
        }
    };

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // status: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const [pricingFilters, setPricingFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // userName: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // status: { value: null, matchMode: FilterMatchMode.CONTAINS }
    });
    const onGlobalFilterChange = (e) => {
        //  
        const value = e ? e.target.value : "";
        getItemList(0, 10, value, filterObj?.organization, filterObj?.status);

        // let _filters = { ...filters };
        // _filters['global'].value = value;
        // setFilters(_filters);
        setGlobalFilterValue(value);


    };
    const onPricingFilterChange = (e) => {
        const value = e ? e?.target?.value : "";
        getItemPriceList(0, 10, value);
        //  
        // let _filters = { ...pricingFilters };
        // _filters['global'].value = value;
        // setPricingFilters(_filters);
        setPricingFilterValue(value);


    };

    const statusBodyTemplate = ((row) => {
        // //console.log(row);
        if (row.status === false) {
            return <p style={{ color: "green" }}  >Active</p>
        }
        else {
            return <p style={{ color: "red" }} >Inactive</p>
        }
    });

    const removeCode = async (index, subitem) => {
        // //console.log("Delete SubItem", subitem);
        // //console.log(item);
        if (!Object.keys(subitem).includes("id")) {
            setItem((prev) => ({
                ...prev,
                itemQuantity: prev?.itemQuantity <= prev?.itemCodes.length ? prev?.itemCodes.length - 1 : prev?.itemQuantity,
                //  itemQuantity: prev.itemQuantity - 1,
                itemCodes: prev.itemCodes.filter((code, i) => i !== index)
            }));
            return;
        }
        await EditSubItem(subitem?.id, cookies()?.bearerToken)
            .then((res) => {
                //console.log(res);
                setItem((prev) => ({
                    ...prev,
                    itemCodes: prev.itemCodes.filter((code, i) => i !== index)
                }));
            })
            .catch((err) => {
                //console.log(err);
            })
        // //console.log("Items", item.itemCodes.length);
        // if (item.itemCodes.length == 1) {
        //     toast.success("Sub Item Deleted")
        //     setItem((prev) =>
        //     ({
        //         ...prev,
        //         "itemCodes": [{
        //             "code": "",
        //             "isDeleted": true
        //         }]
        //     })
        //     )
        // }
        // getItemList(0, 10);
        getSubItem(item.itemOrganization.id, item.itemId);

    };
    const exportItems = () => {
        //test

        let urlRoute = `Item?searchTerm=${exportDetails?.searchTerm}&organizationId=${exportDetails?.organization}&status=${exportDetails?.status}`
        exportExcel(urlRoute);
        //
        // if (globalFilterValue) {
        //     exportDataInExcel(`Item?searchTerm=${globalFilterValue}`, cookies()?.bearerToken);
        //     return
        // }
        // exportDataInExcel('Item', cookies()?.bearerToken);

    }
    const exportItemPricing = () => {
        if (pricingFilterValue) {
            exportDataInExcel(`ItemPricing?searchTerm=${pricingFilterValue}`, cookies()?.bearerToken);
            return
        }
        exportDataInExcel('ItemPricing', cookies()?.bearerToken);

    }
    const actionButtonTemplate = (row) => {
        return (
            <div onClick={async (e) => {
                console.log("Row", row);
                setIsAdd(false);
                setIsInvalid(false);
                let filteredOrg = organizationList?.filter((org) => row.organizationId == org.id);
                let filteredDesk = [];
                await GetDeskBasedOnOrganization(filteredOrg[0]?.id, cookies()?.bearerToken)
                    .then((res) => {
                        if (res.status === 200) {
                            setDeskList(res?.data?.data)
                            //console.log(res?.data?.data);
                            filteredDesk = res?.data?.data?.filter((desk) => row.deskName == desk.name);
                        }
                    })
                    .catch((err) => {
                        //console.log(err);
                    })
                setItem((prev) => ({ ...prev, itemOrganization: filteredOrg[0] }))
                await getSubItem(filteredOrg[0]?.id, row?.id, row?.deskId, cookies()?.bearerToken);
                //console.log("Org List", organizationList);
                //console.log("Desk List", deskList);
                //console.log(filteredOrg, filteredDesk);
                setIsAddItem(true);
                setItem((prev) => ({
                    ...prev,
                    itemName: row?.name,
                    itemStatus: !row?.status,
                    itemId: row?.id,
                    itemOrganization: filteredOrg[0],
                    itemDesk: filteredDesk[0],
                    itemQuantity: row?.quantity || 1
                }))
                //console.log(item);

            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };
    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "25px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>{isAdd ? "Add" : "Edit"} Item {isAddPrice ? "Price" : ""} Details</p>
        </div>
    }
    const popup = (() => {
        return (
            <>

                <Dialog header={header} closable={false}
                    visible={true} style={{ width: '80vw', height: '30vw' }} onHide={() => {
                        setIsAddItem(false); setIsInvalid(false); setItem({
                            itemName: "",
                            itemStatus: true,
                            itemQuantity: 1,
                            itemId: "",
                            itemOrganization: "",
                            itemDesk: "",
                            itemCodes: [{
                                "code": "",
                                "isDeleted": true
                            }]
                        });
                    }}>

                    <div className=" popup " >
                        {/* <div className="heading">
                        <p>Add New Details</p>
                    </div> */}
                        <div className="body">
                            <div className="label grid" >
                                <div className='col-2' style={{ marginRight: '20px' }}>Item Name<span style={{ color: 'red' }}>*</span>
                                    <InputText placeholder="Enter Name" style={{ width: "200px", border: "1px solid #ae9d81", boxShadow: 'none' }} value={item?.itemName}
                                        onChange={
                                            (e) => {
                                                setItem((prev) => ({ ...prev, itemName: e.target.value }));
                                            }
                                        } />
                                    {isInvalid && item?.itemName == "" &&
                                        <p style={{ color: "red" }}>Item name is required</p>
                                    }
                                </div>
                                <div className='col-2' style={{ marginRight: '20px' }} >Quantity<span style={{ color: 'red' }}>*</span>
                                    <InputText placeholder="Enter Quantity" type='number' style={{ width: "200px", border: "1px solid #ae9d81", boxShadow: 'none' }} value={item?.itemQuantity}
                                        onChange={
                                            (e) => {
                                                setItem((prev) => ({ ...prev, itemQuantity: e.target.value }));
                                            }
                                        } />
                                    {isInvalid && item?.itemQuantity == "" &&
                                        <p style={{ color: "red" }}>Item quantity is required</p>

                                    }</div>
                                <div className='col-2' style={{ marginRight: '20px' }} >Organization Name<span style={{ color: 'red' }}>*</span>
                                    <Dropdown value={item?.itemOrganization} style={{ border: "1px solid #AE9D81", width: "225px", borderRadius: "5px", boxShadow: "none" }}
                                        options={organizationList} optionLabel="name"
                                        placeholder="Choose Organization" disabled={!isAdd}
                                        onChange={
                                            (e) => {
                                                setItem((prev) => ({ ...prev, itemOrganization: e.target.value }));
                                            }
                                        } />
                                    {isInvalid && item?.itemOrganization == "" &&
                                        <p style={{ color: "red" }}>Organization name is required</p>

                                    }</div>
                                <div className='col-2' style={{ marginRight: '20px', marginLeft: "20px" }} >Desk Name<span style={{ color: 'red' }}>*</span>
                                    <Dropdown value={item?.itemDesk} style={{ border: "1px solid #AE9D81", width: "200px", borderRadius: "5px", boxShadow: "none" }}
                                        options={deskList} optionLabel="name"
                                        placeholder="Choose Desk"
                                        onFocus={() => getDeskList(item?.itemOrganization?.id)}
                                        disabled={item?.itemOrganization == ""}
                                        onChange={
                                            (e) => {
                                                setItem((prev) => ({ ...prev, itemDesk: e.target.value }));
                                            }
                                        }
                                    />
                                    {isInvalid && item?.itemDesk == "" &&
                                        <p style={{ color: "red" }}>Desk name is required</p>

                                    }
                                </div>
                                <div className='col-2' style={{ marginRight: '20px' }} >Status
                                    <div>
                                        <label className={style.switch}>
                                            <input type="checkbox" checked={item?.itemStatus} onChange={(e) => { setItem((prev) => ({ ...prev, itemStatus: e.target.checked })); }} />
                                            <span className={classNames(style.slider, style.round)}></span>
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div className="label grid">
                                {item?.itemCodes?.map((item, index, array) => {
                                    return (
                                        <div className="col-4" key={index} style={{ marginTop: '30px' }}>
                                            <div className="grid">
                                                <div className="col-6">Sub Item Code<span style={{ color: 'red' }}>*</span>
                                                    <InputText placeholder="Enter Name" style={{ width: "200px", border: "1px solid #ae9d81", boxShadow: 'none' }} value={item?.code}
                                                        onChange={
                                                            (e) => {
                                                                setItem((prev) => ({
                                                                    ...prev, itemCodes: prev.itemCodes.map((innerItem, innerIndex) => {
                                                                        if (index == innerIndex) {
                                                                            return { ...item, code: e.target.value }
                                                                        }
                                                                        else {
                                                                            return innerItem;
                                                                        }
                                                                    })
                                                                }))
                                                            }}
                                                    />
                                                    {isInvalid && (item.code.length == 0 || item.code == "") &&
                                                        <p style={{ color: "red" }}>Item code is required</p>

                                                    }
                                                </div>
                                                <div className="col-3" style={{ marginLeft: '30px', width: 'unset' }} >Status
                                                    <label className={style.switch} style={{ display: "block" }}>
                                                        <input type="checkbox" checked={item.isDeleted}
                                                            onChange={(e) => {
                                                                setItem((prev) => ({
                                                                    ...prev, itemCodes: prev.itemCodes.map((innerItem, innerIndex) => {
                                                                        if (innerIndex == index) {
                                                                            return { ...item, isDeleted: e.target.checked }
                                                                        }
                                                                        else {
                                                                            return innerItem;
                                                                        }
                                                                    })
                                                                }))
                                                            }}
                                                        />
                                                        <span className={classNames(style.slider, style.round)}></span>
                                                    </label>
                                                </div>

                                                {array.length == index + 1 &&
                                                    <div className="col-1" style={{ display: "flex", alignItems: "center" }}>
                                                        <div className="additembtn">
                                                            <i className="pi pi-plus" style={{ fontSize: '1rem' }}
                                                                onClick={() => {
                                                                    setItem((prev) => ({
                                                                        ...prev,
                                                                        itemQuantity: prev?.itemQuantity <= prev?.itemCodes.length ? prev?.itemCodes.length + 1 : prev?.itemQuantity,
                                                                        itemCodes: [...prev.itemCodes, { "code": "", "isDeleted": true }]
                                                                    }))

                                                                }}
                                                            ></i>
                                                        </div>
                                                    </div>}
                                                {array.length > 1 && !item?.id ? (
                                                    <div div className="col-1" style={{ display: "flex", alignItems: "center" }}>

                                                        <div className="removeitembtn"
                                                        // style={{
                                                        //     textAlign: 'center',
                                                        //     width: '20px',
                                                        //     height: '20px',
                                                        //     backgroundColor: '#ae9d81',
                                                        //     color: 'white',
                                                        //     fontSize: '10px',
                                                        //     marginTop: '4px'
                                                        // }}
                                                        >
                                                            <i
                                                                className="pi pi-trash"
                                                                style={{ fontSize: '1rem' }}
                                                                onClick={() => {
                                                                    removeCode(index, item);

                                                                }}
                                                            ></i>
                                                        </div>
                                                    </div>
                                                ) : ""}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel" onClick={() => {
                                    setIsAddItem(false);
                                    setIsInvalid(false);
                                    setItem({
                                        itemName: "",
                                        itemStatus: true,
                                        itemQuantity: 1,
                                        itemId: "",
                                        itemOrganization: "",
                                        itemDesk: "",
                                        itemCodes: [
                                            {
                                                "code": "",
                                                "isDeleted": true
                                            }
                                        ]
                                    });
                                }} style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px" }} />
                                <Button label={isAdd ? "Submit" : "Update"} onClick={() => { isAdd ? addItem() : editItem(); }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px" }} />
                            </div>
                        </div>
                    </div>
                </Dialog >

            </>
        )
    })
    const onUpload = (e) => {
        //console.log(e?.files[0]);
        setFileValidating(true);
        if (e.files.length == 0) {
            setIsImportInvalid(true);
        }
        setIsImportInvalid(false);
        // if (e.files[0].name !== "Item.csv") {
        //     // alert("Please upload Tenantcsv file")
        //     setIsImportInvalidFile(true);
        //     e.files.pop()
        // }
        // else {
        setIsImportInvalidFile(false);
        const formData = new FormData();
        formData.append('file', e.files[0]);
        ImportItems(formData, cookies()?.bearerToken)
            .then((res) => {
                //console.log(res);
                if (res?.response?.status === 400) {
                    toast.info(res?.response?.data);
                    return
                }

                if (res.status === 200) {
                    toast.success("Items imported successfully");
                    setIsImport(false);
                    setFileValidating(false);
                    getItemList(0, 10);
                    setGlobalFilterValue("");
                }
            })
            .catch((err) => {
                //console.log(err);
                setFileValidating(false);
                if (err.response.status === 400) {
                    toast.error(err.response.data.message);
                } else {
                    toast.error("Something went wrong");
                }
            });
        // }
    };
    const downloadFile = () => {
        // Path to your file
        const filePath = '%PUBLIC_URL%/src/assets/files/Tenantcsv.csv';

        // Fetch the file content
        fetch(filePath)
            .then((response) => response.blob())
            .then((blob) => {
                // Create a blob URL for the file content
                const url = URL.createObjectURL(blob);

                // Create a hidden anchor element
                const a = document.createElement('a');
                a.href = url;
                a.download = 'Tenantcsv.csv'; // Specify the desired file name

                // Trigger a click on the anchor element to start the download
                document.body.appendChild(a);
                a.click();

                // Remove the anchor element
                document.body.removeChild(a);

                // Revoke the blob URL to free up resources
                URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Error downloading file:', error);
            });
    };


    const importHeader = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "23px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>Import Items Details</p>
        </div>
    }
    const importPopup = (() => {
        return (<>
            <Dialog header={importHeader} visible={true} style={{ width: '50vw' }} closable={true} onHide={() => { setIsImport(false) }}>
                <div className="popup " >
                    <div className="body">
                        <div className="col">
                            <div className="label grid">
                                <div className='col'>
                                    Sample Template {" "}
                                    <i className="pi pi-download" style={{ color: 'slateblue', marginLeft: "7px", cursor: 'pointer' }}
                                        // on click i need to download a file 
                                        onClick={() => {
                                            // window.open(Itemcsv)
                                            var downloadLink = document.createElement("a");
                                            downloadLink.href = Itemcsv;
                                            downloadLink.download = "Itemcsv.csv";
                                            document.body.appendChild(downloadLink);
                                            downloadLink.click();
                                            document.body.removeChild(downloadLink);
                                        }}
                                    ></i>
                                </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="label grid">
                                <div className='col' style={{ display: "flex", alignItems: "center" }}>Select File<span style={{ color: 'red' }}>*</span> <Modalinfo /></div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    {/* <FileUpload mode="basic" name="demo[]" accept="*.csv" maxFileSize={1000000} onUpload={onUpload} chooseLabel="Browse" /> */}
                                    <FileUpload name="demo[]" accept=".csv" mode='basic' multiple={false} maxFileSize={1000000} customUpload uploadHandler={onUpload} emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                                    {/* {isFileValidating && <i className="pi pi-spin pi-spinner" style={{ fontSize: '2rem' }}></i>} */}
                                    {isImportInvalid &&
                                        // <div>
                                        <p style={{ color: "red" }}>Please choose a file</p>
                                        // </div>
                                    }
                                    {isImportInvalidFile &&
                                        // <div>
                                        <p style={{ color: "red" }}>Name of the uploaded file should be same as the sample file.</p>
                                        // </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog>
        </>)
    })

    const getItemList = (async (skip, take, searchValue = "", orgId = "", status = "",) => {
        const obj = {
            take: take,
            skip: skip,
            searchValue: searchValue,
            orgId: orgId,
            status: status
        }
        const showAll = isShow(obj);

        await GetItemList(skip, take, cookies()?.bearerToken, searchValue, orgId, status, showAll)
            .then((res) => {
                if (res?.status == 200) {
                    // //console.log(res);
                    setItemList([...res.data.data]);
                    setTotalRecords(res.data.count);
                    // let priceList = [];
                    // res?.data?.data.forEach((_) => {
                    //     if (_?.priceInformation) {
                    //         //console.log(Object.keys(_?.priceInformation).length);
                    //         priceList.push(_);
                    //     }
                    // });
                    // //console.log("priceList", priceList);
                    // setPriceList(priceList);
                }

            })
            .catch((error) => {
                //console.log(error);

            })
            .finally(() => {
                setIsLoading(false);
            })

    })
    const getActiveItemsList = async () => {
        await GetActiveItems(cookies()?.bearerToken).then(res => {
            if (res?.status == 200) {
                setActiveItemsLIst(res.data);
                // setTotalRecords(res.data.count);
            }
        }).catch((error) => {
            //console.log(error);

        })
            .finally(() => {
                setIsLoading(false);
            })
    }
    const back = () => {
        navigate('/dashboard');
    };

    /*--------------item price list integration -------------*/

    const getItemPriceList = (async (skip, take, searchValue = "") => {
        await GetItemPriceList(skip, take, cookies()?.bearerToken, searchValue)
            .then((res) => {
                if (res?.status == 200) {
                    // //console.log(res);
                    setPriceList(res?.data?.data);
                    setTotalRPriceecords(res.data.count);
                    // let priceList = [];
                    // res?.data?.data.forEach((_) => {
                    //     if (_?.priceInformation) {
                    //         //console.log(Object.keys(_?.priceInformation).length);
                    //         priceList.push(_);
                    //     }
                    // });
                    // //console.log("priceList", priceList);
                    // setPriceList(priceList);
                }

            })
            .catch((error) => {
                //console.log(error);

            })
            .finally(() => {
                setIsLoading(false);
            })
    })


    /*-------------------------------------------------------*/


    const isPresentDuplicate = () => {
        const seen = {};
        let hasDuplicates = false;

        item?.itemCodes?.forEach(item => {
            const code = item.code;
            if (seen[code]) {
                hasDuplicates = true;
                return;
            } else {
                seen[code] = true;
            }
        });
        return hasDuplicates
    }

    const addItem = (() => {

        //console.log("Add Item", item);
        if (item?.itemName == "" || item?.itemQuantity == "" || item?.itemOrganization == "" || item?.itemDesk == "") {
            setIsInvalid(true);
            return;
        }
        if (item?.itemCodes.length == 0) {
            setIsInvalid(true);
            return;
        }

        if (isPresentDuplicate()) {
            toast?.warn("Sub items has an duplicates");
            return
        }

        let filterAddSubItems = item?.itemCodes.filter((subitem) => subitem?.code == null || subitem?.code == undefined || subitem?.code == "");
        if (filterAddSubItems.length > 0) {
            setIsInvalid(true);
            return;
        }
        else {
            setIsInvalid(false);
            setIsAddItem(false);
            let data = {
                "deskId": item?.itemDesk?.id,
                "name": item?.itemName,
                "organizationId": item?.itemOrganization?.id,
                "quantity": item?.itemQuantity >= item?.itemCodes.length ? item?.itemQuantity : item?.itemCodes.length,
                "isDeleted": !item?.itemStatus,
                "itemCodes": [...item?.itemCodes.map((subitem) => {
                    return {
                        "code": subitem?.code,
                        "isDeleted": !subitem?.isDeleted
                    }

                })]
            }
            //console.log("Data", data);
            AddItemList(data, cookies()?.bearerToken)
                .then((res) => {
                    //console.log(res);
                    getItemList(first, 10);
                    setIsAddItem(false);
                    setIsInvalid(false);
                    setItem({
                        itemName: "",
                        itemStatus: true,
                        itemQuantity: 1,
                        itemId: "",
                        itemOrganization: "",
                        itemDesk: "",
                        itemCodes: [{
                            "code": "",
                            "isDeleted": true
                        }]
                    });
                    toast.success("New item is added successfully", { position: "top-right" });
                })
                .catch((error) => {
                    //console.log(error);
                })
        }
    });

    const editItem = (() => {
        //console.log("Edit Item", item);
        if (item?.itemName == "" || item?.itemQuantity == "" || item?.itemOrganization == "" || item?.itemDesk == "") {
            setIsInvalid(true);
            return;
        }
        let filterEditSubItems = item?.itemCodes.filter((subitem) => subitem?.id === 0);
        let filterAddSubItems = item?.itemCodes.filter((subitem) => subitem?.code == null || subitem?.code == undefined || subitem?.code == "");
        //console.log(filterAddSubItems, filterEditSubItems);
        let EditSubItemsCodes = item?.itemCodes.filter((subitem) => subitem?.id > 0);
        let AddSubItemsCodes = item?.itemCodes.filter((subitem) => !subitem?.id);

        if (filterAddSubItems.length > 0 || filterEditSubItems.length > 0) {
            setIsInvalid(true);
            return;
        }
        if (isPresentDuplicate()) {
            toast?.warn("Sub items has an duplicates");
            return
        }
        setIsInvalid(false);
        let data = {
            "deskId": item?.itemDesk?.id,
            "name": item?.itemName,
            "organizationId": item?.itemOrganization?.id,
            "isDeleted": !item?.itemStatus,
            "id": item?.itemId,
            "quantity": item?.itemQuantity >= item?.itemCodes.length ? item?.itemQuantity : item?.itemCodes.length,
            "subItemEditModels": EditSubItemsCodes.map((subitem) => {
                return {
                    "code": subitem?.code,
                    "isDeleted": !subitem?.isDeleted,
                    "subItemId": subitem?.id
                }
            }),
            "subItemAddModels": AddSubItemsCodes.map((subitem) => {
                return {
                    "code": subitem?.code,
                    "isDeleted": !subitem?.isDeleted,
                }
            })
        }
        // //console.log("AddSubItemData", addSubitemData);
        //console.log("Data", data);
        EditItemList(data, cookies()?.bearerToken)
            .then((res) => {
                //console.log(res);
                if (res?.status !== 200) {
                    return;
                };
                //console.log("status", res?.status);
                getItemList(first, 10);
                setIsAddItem(false);
                setIsInvalid(false);
                setItem({
                    itemName: "",
                    itemStatus: true,
                    itemQuantity: 1,
                    itemId: "",
                    itemOrganization: "",
                    itemDesk: "",
                    itemCodes: [{
                        "subItemId": "",
                        "code": "",
                        "isDeleted": true
                    }],
                })
                toast.success("Item is updated successfully", { position: "top-right" });
            })
            .catch((error) => {
                //console.log(error);
            });
    })

    //.....price.....//

    const addItemPrice = (() => {
        //console.log("Date", priceEffectiveDate, "Id", activeCategory.id, "Price", price);
        if (activeCategory?.id == "" ||
            price == 0 ||
            priceEffectiveDate == "") {
            setIsPriceInvalid(true);
            return;
        }
        setIsPriceInvalid(false);

        let data = {
            "itemId": activeCategory?.id,
            "price": parseFloat(price),
            "effectiveDate": priceEffectiveDate
        }
        // return
        AddPriceItem(data, cookies()?.bearerToken)
            .then(res => {
                //console.log(res);
                getItemList(0, 10);
                setIsAdd(false);
                setPrice("");
                setPriceEffectiveDate("");
                setActiveCategory({});
                setPrice("");
                setPriceEffectiveDate('');
                if (res?.response?.status === 400) {
                    toast.warn(res.response.data, { position: "top-right" });
                }
                else {
                    toast.success("Item pricing is added successfully", { position: "top-right" });
                    getItemPriceList(0, 10, '');

                }

            })
            .catch(err => {
                //console.log(err);
                toast.success(err, { position: "top-right" });

            })
    });

    const editItemPrice = (() => {

        let data = {
            "itemId": price?.itemId,
            "price": parseFloat(price?.price),
            "effectiveDate": editPriceEffectiveDate
        };
        EditPriceItem(data, cookies()?.bearerToken, price?.itemId)
            .then((res) => {
                if (res?.status == 200) {

                    getItemList(0, 10);
                    setIsAdd(false);
                    setIsAddPrice(false);
                    setPrice({
                        itemId: 0,
                        price: 0,
                        effectiveDate: ""
                    });
                    toast.success("Item pricing is updated successfully", { position: "top-right" });
                    getItemPriceList(0, 10, '');

                }
            })
            .catch((err) => {
                //console.log(err);
            })
    });


    const actionButtonTemplateForItemPricing = (row) => {
        return (
            <div onClick={(e) => {
                setIsAddPrice(true);
                setIsAdd(false);
                //console.log("Row", row);
                setPrice((prev) => ({
                    ...prev,
                    itemId: row?.itemId,
                    price: row?.price,
                    effectiveDate: row?.effectiveDate,
                    itemQuantity: row?.quantity
                }))

                let date = row?.effectiveDate.split("/");
                let year = date[2];
                let month = date[1];
                let day = date[0];
                //console.log(year + '-' + month + '-' + day);
                seteditPriceEffectiveDate(year + '-' + month + '-' + day);
                // seteditPriceEffectiveDate('2024-01-19');

            }}>
                <img src={editIcon} style={{ cursor: 'pointer' }} />
            </div>
        );
    };
    //.....price.....//


    const itemPricingPopup = (() => {
        return (
            <>
                <Dialog header={header} visible={isAddPrice} style={{ width: '30vw' }}
                    closable={false} onHide={() => {
                        setIsAddPrice(false); setPrice({
                            itemId: 0, price: 0, effectiveDate: ""
                        });
                    }}>
                    <div className="popup " >
                        {/* <div className="heading">
                            <p>Add New Details</p>
                        </div> */}
                        <div className="body"  >
                            <div className="label grid" >
                                <div className='col' >Price<span style={{ color: 'red' }}>*</span></div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    <InputText placeholder="Enter Name" value={price?.price} onChange={(e) => { setPrice((prev) => ({ ...prev, price: e.target.value })) }} />
                                </div>
                            </div>
                            <div className="label grid" >
                                <div className='col' >Effective Date<span style={{ color: 'red' }}>*</span></div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    <Calendar style={{ background: 'transparent' }} dateFormat='dd/mm/yy' className="p-inputtext-sm"
                                        placeholder='Effective Date' showIcon
                                        value={new Date(editPriceEffectiveDate)}
                                        // value={price?.effectiveDate}
                                        onChange={(e) => seteditPriceEffectiveDate(e.target.value)} minDate={new Date()} />
                                </div>
                            </div>

                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel"
                                    onClick={() => {
                                        setIsAddPrice(false); setPrice({
                                            itemId: 0, price: 0, effectiveDate: ""
                                        });
                                        seteditPriceEffectiveDate("");
                                    }}
                                    style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label="Update" onClick={editItemPrice} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog>

            </>
        )
    })

    const getDeskList = (async (id) => {
        if (!id) {
            return
        }
        // await GetDeskList(take, skip, cookies()?.bearerToken)
        await GetDeskBasedOnOrganization(id, cookies()?.bearerToken)
            .then((res) => {
                //console.log(res);
                if (res?.status === 200) {
                    //console.log("Fetching Desk", res?.data?.data);
                    setDeskList(res?.data?.data);
                }
                else {
                    setDeskList([]);
                    toast.error("Fetching Desk failed");
                }
                // dispatch(
                //     addDeskList({ deskList: res?.data })
                // )

            })
            .catch((error) => {
                //console.log(error);
            })
    });
    const getOrganizationList = (async () => {
        await GetOrganizationList(cookies()?.bearerToken)
            .then((res) => {
                //console.log("Fetching Organization List", res.data.data);
                // dispatch(
                //     addOrganizationList({
                //         organizationList: res?.data
                //     }))
                if (res.status === 200) {
                    setOrganizationList(res?.data?.data.filter((org) => org?.status === "Active"));
                }
            })
            .catch((error) => {
                //console.log(error);
            })


    });
    const getSubItem = (async (orginazationId, itemid, deskId) => {
        await GetSubItemsForItems(orginazationId, itemid, deskId, cookies()?.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log(res);
                    setItem((prev) => {
                        return {
                            ...prev,
                            itemCodes: res?.data?.length == 0 ?
                                [{
                                    code: "",
                                    isDeleted: true,
                                    id: ""
                                }]
                                : res?.data?.map(d => {
                                    return {
                                        code: d.code,
                                        isDeleted: !d.isDeleted,
                                        id: d.id
                                    }
                                })
                        }
                    })
                }
            })
            .catch((error) => {
                //console.log(error);
            })

    })
    // const getSubItem=(()=>{

    // })

    const handleTabChange = (index) => {
        if (index === 0) {
            getItemList(0, 10);
            setActiveIndex(index);
            return;
        }
        if (index === 1) {
            getActiveItemsList();
            setActiveIndex(index);
            return;
        }

    }


    const formatDate = (timestamp) => {
        // //console.log(timestamp, "minDate={new Date()}");
        // const timestampDate = new Date(timestamp);
        // return timestampDate.toLocaleString('en-US', {
        //     day: 'numeric',
        //     month: '2-digit',
        //     year: 'numeric',
        // },);
        const timestampDate = new Date(timestamp);
        const convertedDate = timestampDate.toLocaleString('en-US', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
        },)?.split('/');
        if (convertedDate?.length == 0 || !convertedDate) {
            return "-"
        }
        return `${convertedDate[1] > 9 ? convertedDate[1] : '0' + convertedDate[1]}/${convertedDate[0] > 9 ? convertedDate[0] : '0' + convertedDate[0]}/${convertedDate[2]}`
    }
    const dateFormatTemplate = (row) => {
        // //console.log(("Date", row));
        return <p style={{ fontWeight: "400" }}>{formatDate(row?.priceInformation?.effectiveDate)}</p>
    }


    // const getPage = (event) => {
    //     //console.log("Page change", event);
    //     // setPage(event.page);
    //     setTake(10)
    //     setSkip(event.first);
    //     getItemList();
    // }
    const onPageChange = (e, index) => {
        getItemList(e.first, 10, globalFilterValue);
        setFirst(e.first);
    };
    const onPricePageChange = (e, index) => {
        getItemPriceList(e.first, 10, globalFilterValue);
        setFirstPricing(e.first);
        //console.log(e);
    };
    const toOpenExportItems = () => {
        // setExportFilterDialog(true)
        // setExportDetails((prev)=>({...prev,organization:"",searchTerm:"",status:true}));
        const urlRoute = `Master/AdminItemList?orgId=${filterObj?.organization || ""}${filterObj?.status ? `&showAll=${isShow({ ...filterObj, search: globalFilterValue })}&status=${filterObj?.status == "In Active"}` : ""}&searchTerm=${globalFilterValue || ""}&forExport=true`;
        exportFilterdExcel(urlRoute, cookies()?.bearerToken);
    }
    const onExportItemPricing = () => {
        // setItemPricingExportFilterDialog(true)
        let routeParams = `ItemPricing?searchTerm=${pricingFilterValue}`
        exportExcel(routeParams);
    }
    useEffect(() => {
        getOrganizationList();
        getItemList(0, 10);
        getItemPriceList(0, 10);
        getActiveItemsList();
    }, [])
    //console.log(item, "itemd");
    // //console.log(priceList, "price");
    return (
        <div className='itemMaster'>
            <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setExportFilterDialog(false)} visible={exportFilterDialog} >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {/* <div style={{ width: "50%" }}>
                        <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                        </label>
                        <Calendar
                            id="StartDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.startDate}
                            placeholder="Choose start end"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.endDate}
                            placeholder="Choose end date"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                        ></Calendar>
                    </div> */}
                    <div style={{ width: "50%" }} className='mb-2'>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <Dropdown
                            options={organizationList}
                            optionLabel='name'
                            optionValue='id'
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((e) => setExportDetails(() => ({ ...exportDetails, organization: e?.value })))}
                            placeholder='Select' />
                    </div>
                    <div style={{ width: "50%" }} className='mb-2'>

                        <label htmlFor="Searchterm" className="font-bold block mb-2">Search Term
                        </label>
                        <InputText
                            id="Searchterm"
                            style={{ width: "95%" }}
                            value={exportDetails?.searchTerm}
                            onChange={((e) => setExportDetails(() => ({ ...exportDetails, searchTerm: e?.target?.value })))}
                            placeholder="Search term" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="Status" className="font-bold block mb-2">Status
                        </label>
                        {/* <InputText
                                id="Status"
                                style={{ width: "95%" }}
                                value={exportDetails?.status}

                                placeholder="Enter organization" ></InputText> */}
                        <div className="input grid ">
                            <div className="col ">
                                <label className="switch ">
                                    <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                    }}
                >
                    <Button
                        label="Submit"
                        onClick={exportItems}
                        // icon={"pi pi-file-export"}
                        style={{
                            backgroundColor: " #ae9d81",
                            color: "#fff",
                            border: "none",
                            // borderRadius: "20px",
                            marginRight: "0",
                            cursor: totalCount > 0 ? "pointer" : "not-allowed",
                            padding: "0.6rem",
                        }}
                    />
                </div>


            </Dialog>
            <Dialog header={<h3>Export Filter</h3>} draggable={false} style={{ width: '50vw', height: "max-content" }} closable={true} onHide={() => setItemPricingExportFilterDialog(false)} visible={itemPricingExportFilterDialog} >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {/* <div style={{ width: "50%" }}>
                        <label htmlFor="StartDate" className="font-bold block mb-2">Start Date
                        </label>
                        <Calendar
                            id="StartDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.startDate}
                            placeholder="Choose start end"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, startDate: value })))}
                        ></Calendar>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="EndDate" className="font-bold block mb-2">End Date
                        </label>
                        <Calendar
                            id="EndDate"
                            style={{ width: "95%" }}
                            value={exportDetails?.endDate}
                            placeholder="Choose end date"
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, endDate: value })))}
                        ></Calendar>
                    </div> */}
                    <div style={{ width: "50%" }}>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <InputText
                            id="organization"
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                            placeholder="Enter organization" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="organization" className="font-bold block mb-2">Organization
                        </label>
                        <InputText
                            id="organization"
                            style={{ width: "95%" }}
                            value={exportDetails?.organization}
                            onChange={((value) => setExportDetails(() => ({ ...exportDetails, organization: value })))}
                            placeholder="Enter organization" ></InputText>
                    </div>
                    <div style={{ width: "50%" }}>
                        <label htmlFor="Status" className="font-bold block mb-2">Status
                        </label>
                        {/* <InputText
                                id="Status"
                                style={{ width: "95%" }}
                                value={exportDetails?.status}

                                placeholder="Enter organization" ></InputText> */}
                        <div className="input grid ">
                            <div className="col ">
                                <label className="switch ">
                                    <input type="checkbox" value={exportDetails?.status} checked={exportDetails?.status} onChange={((e) => setExportDetails(() => ({ ...exportDetails, status: e.target.checked })))} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "25px",
                    }}
                >
                    <Button
                        label="Submit"
                        onClick={exportItemPricing}
                        // icon={"pi pi-file-export"}
                        style={{
                            backgroundColor: " #ae9d81",
                            color: "#fff",
                            border: "none",
                            // borderRadius: "20px",
                            marginRight: "0",
                            cursor: totalCount > 0 ? "pointer" : "not-allowed",
                            padding: "0.6rem",
                        }}
                    />
                </div>


            </Dialog>
            <div className="card" style={{ padding: "0,  10px", display: "flex", justifyContent: 'space-between' }}>
                <div style={{ display: 'flex' }}>
                    <i className="pi pi-arrow-circle-left" style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} onClick={back}></i>
                    <div className={style.heading}>
                        <p style={{ margin: '0px', paddingLeft: '20px', color: '#00488F', fontSize: '18px', fontStyle: 'normal', fontWeight: '700', lineHeight: 'normal', letterSpacing: '-0.3px' }}>Items </p>
                    </div>
                </div>
            </div>
            <div style={{ marginTop: '15px', marginLeft: '10px' }}>
                <TabView activeIndex={activeIndex} onTabChange={(e) => { handleTabChange(e.index); setGlobalFilterValue(""); setPricingFilterValue(""); onGlobalFilterChange(); onPricingFilterChange(); if (activeIndex === 0) { getItemList(0, 10); } }} style={{ border: 'none', height: '42px' }}>
                    <TabPanel header="Items" className={`${style.tab_view} ${activeIndex === 0 ? style.active_tab : ''}`} >
                        <div className='header mb-3' style={{ gap: 10 }}>
                            <div className='mx-2 align-self-start'>
                                <Button label='Import' icon={"pi pi-file-import"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: 'pointer' }} onClick={() => { setIsImport(true) }} />
                            </div>
                            <div className='mx-2 align-self-start'>
                                <Button onClick={() => totalCount > 0 ? toOpenExportItems() : null} label='Export' icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: totalCount > 0 ? 'pointer' : 'not-allowed' }} />
                            </div>
                            <div className="mx-2 align-self-start mt-1">
                                <Button label="Add New" onClick={() => {
                                    setIsAddItem(true); setIsAdd(true); setItem({
                                        itemName: "",
                                        itemStatus: true,
                                        itemQuantity: 1,
                                        itemId: "",
                                        itemOrganization: "",
                                        itemDesk: "",
                                        itemCodes: [
                                            {
                                                "code": "",
                                                "isDeleted": true
                                            }
                                        ]
                                    });
                                }} style={{ backgroundColor: '#ae9d81', border: 'none', margin: 0 }} />
                            </div>
                            <div className='mx-2'>
                                <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                                <div className="search">
                                    <span className="p-input-icon-left">
                                        <i
                                            className="pi pi-search"
                                            style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" />
                                        <i className="pi pi-times" style={{ color: 'black', fontWeight: '400' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange(); getItemList(0, 10, "", filterObj?.organization, filterObj?.status) }} />
                                    </span>
                                </div>
                            </div>


                            <div className='mx-2'>
                                <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Status</h4>
                                <Dropdown value={filterObj?.status} options={[{ name: "Active" }, { name: "In Active" }]} optionLabel='name' optionValue='name' onChange={(e) => { setFilterObj((prev) => ({ ...prev, status: e.value })); getItemList(0, 10, globalFilterValue, filterObj?.organization, e.value) }} placeholder='Select' style={{ border: "solid 1px #ae9d81", width: 180 }} showClear={filterObj?.status ? true : false} />
                            </div>
                            <div className='mx-2'>
                                <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Organization</h4>
                                <Dropdown value={filterObj?.organization} options={organizationList} optionLabel='name' optionValue='id' onChange={(e) => { setFilterObj((prev) => ({ ...prev, organization: e.value })); getItemList(0, 10, globalFilterValue, e.value, filterObj?.status) }} placeholder='Select' style={{ border: "solid 1px #ae9d81", width: 180 }} showClear={filterObj?.organization ? true : false} />
                            </div>
                            <div style={{ flexGrow: 3 }}></div>
                        </div>
                        {isLoading ?
                            <div className='spinner spinner-center' style={{ marginLeft: '750px' }}></div>
                            : <>
                                <DataTable
                                    value={itemsList}
                                    tableStyle={{ minWidth: '50rem' }}
                                    style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    stripedRows
                                    rows={10}
                                    // onPage={(e) => getPage(e)}
                                    // first={first}
                                    // totalRecords={totalCount}
                                    emptyMessage={<><p style={{ textAlign: "center" }}>Items list is empty</p></>}
                                    filters={filters}
                                    globalFilterFields={['name', 'organizationName', 'deskName']}
                                // template={{ layout: 'FirstPageLink PrevPageLink CurrentPageReport NextPageLink  LastPageLink' }}
                                // paginator
                                // selection={selectedFeedback}
                                // onSelectionChange={handleRowClick}
                                >
                                    <Column field='name' header='Item Name' style={{ width: '25%' }} ></Column>
                                    <Column field='organizationName' header='Organization ' style={{ width: '25%' }}></Column>
                                    <Column field='deskName' header='Desk' style={{ width: '25%' }}></Column>
                                    <Column field='status' header='Status' body={statusBodyTemplate} style={{ width: '25%' }}></Column>
                                    <Column header="Action" body={actionButtonTemplate} style={{ width: '25%' }}> </Column>

                                </DataTable>
                                <div style={{ justifyContent: "center", display: 'flex' }} >
                                    <Paginator first={first} rows={10} totalRecords={totalCount} onPageChange={(e) => onPageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
                                </div>
                            </>

                        }
                    </TabPanel>
                    <TabPanel header="Item Pricing" className={`${style.tab_view} ${activeIndex === 1 ? style.active_tab : ''}`} >
                        <div style={{ display: "flex", justifyContent: "space-evenly" }} className='mb-3'>
                            <div style={{ display: 'flex' }}>
                                <div className='mx-2'>
                                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Items</h4>
                                    <Dropdown style={{ margin: '0px 2px 2px 2px', width: '13rem', }} options={activeItemsList} optionLabel='name' value={activeCategory} onChange={(e) => {
                                        setActiveCategory(e.value);
                                        //console.log(e.target.value, itemCategory); setItemCatogory((prev) => ({ ...prev, name: e.target.value }))
                                    }} placeholder='Select' className={isPriceInvalid && Object.keys(activeCategory).length == 0 ? "p-invalid" : ""} />
                                </div>

                                <div className='mx-2'>
                                    {isPriceInvalid && price == 0 ? (
                                        <>
                                            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Items</h4>
                                            <InputText type='number' style={{ border: "1px solid red", }} min={0} placeholder='Enter Price' value={price} onChange={(e) => setPrice(e.target.value)} />
                                        </>
                                    ) : (
                                        <>
                                            <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Items Price</h4>
                                            <InputText type='number' min={0} style={{}} placeholder='Enter Price' value={price} onChange={(e) => setPrice(e.target.value)} />
                                        </>
                                    )}
                                </div>
                                <div className='mx-2'>
                                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Effective Date</h4>
                                    <Calendar
                                        style={{ background: 'transparent', border: isPriceInvalid && priceEffectiveDate == "" ? "1px solid red" : "", borderRadius: '6px' }}
                                        value={priceEffectiveDate == "" ? "" : new Date(priceEffectiveDate)}
                                        placeholder='Select'
                                        showIcon
                                        dateFormat='dd/mm/yy'
                                        onChange={(e) => {
                                            setPriceEffectiveDate(e.target.value)
                                        }}
                                        minDate={new Date()}

                                    />
                                </div>
                                <div className='mx-2'>
                                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                                    <div className="search">
                                        <div className='flex align-items-center' style={{ border: "solid 1px #AE9D81", borderRadius: "4px" }}>
                                            <i className="pi pi-search pl-2 pr-1" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                            <InputText value={pricingFilterValue} onChange={onPricingFilterChange} placeholder="Search" className='border-0' style={{ height: "s40px" }} />
                                            <i className="pi pi-times pl-1 pr-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer' }} onClick={() => { setPricingFilterValue(""); onPricingFilterChange(); getItemPriceList(0, 10) }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='mx-2 align-self-center mt-3'>
                                    <Button style={{ backgroundColor: '#AE9D81', border: 'none', height: '40px', borderRadius: '6px', width: 120 }} onClick={addItemPrice} label='Add' />
                                </div>

                                <div className='mx-2 align-self-start mt-3'>
                                    <Button onClick={() => onExportItemPricing()} label='Export' icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', cursor: totalCount > 0 ? 'pointer' : 'not-allowed' }} />
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>


                            </div>
                        </div>
                        {isLoading ?
                            <div className='spinner spinner-center' style={{ marginLeft: '750px' }}></div>
                            :
                            <>
                                <DataTable
                                    value={priceList}
                                    tableStyle={{ minWidth: '50rem' }}
                                    style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}
                                    stripedRows rows={10}
                                    emptyMessage={<><p style={{ textAlign: "center" }}>Items list is empty</p></>}
                                    selectionMode="single"
                                    filters={pricingFilters}
                                    globalFilterFields={['itemName', 'itemId', 'price', 'effectiveDate']}

                                >
                                    <Column field='itemId' style={{ width: '25%' }} header='Item Id'></Column>
                                    <Column field='itemName' style={{ width: '25%' }} header='Item Name'></Column>
                                    <Column field='price' style={{ width: '25%' }} header='Price'></Column>
                                    <Column field='effectiveDate' style={{ width: '25%' }}
                                        // body={dateFormatTemplate} 
                                        header='Effective Date'></Column>
                                    <Column field='' style={{ width: '25%' }} header='Action' body={actionButtonTemplateForItemPricing} rowEditor></Column>
                                </DataTable>
                                <div style={{ justifyContent: "center", display: 'flex' }} >
                                    <Paginator first={firstPricing} rows={10} totalRecords={totalPriceCount} onPageChange={(e) => onPricePageChange(e, 1)} template={{ layout: 'RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport' }} className="justify-content-start" />
                                </div>
                            </>
                        }

                    </TabPanel>
                </TabView>
            </div>
            <div>
                {isAddPrice == true ? itemPricingPopup() : ""}
                {isAddItem == true ? popup() : ""}
                {isImport == true ? importPopup() : ""}
            </div>


        </div >
    )
}

export default Items

