import Axios from "../Helpers/Axios";

export function GetAllFiles(
  { id, module, isForInspection, inspectionId },
  token
) {
  return Axios(
    `File/GetAllFiles?id=${id}&module=${module}&isForInspection=${isForInspection}&inspectionId=${inspectionId}`,
    "get",
    null,
    token
  );
}

function base64ToArrayBuffer(base64) {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}

export async function DownloadFile(fileName, blobUrl, token) {
  try {
    const res = await Axios(
      `File/DownloadFile?fileName=${fileName}&azureBlobUrl=${blobUrl}`,
      "get",
      null,
      token
    );
    if (res?.status == 200) {
      var fileformat =
        res.data.fileName.split(".")[res.data.fileName.split(".").length - 1];
      var arrrayBuffer = base64ToArrayBuffer(res.data.fileBuffer);
      var blob = new Blob([arrrayBuffer], {
        type: "application/" + fileformat,
      });
      var link = window.URL.createObjectURL(blob);
      const aTag = document.createElement("a");
      aTag.href = link;
      aTag.download = res.data.fileName;
      document.body.append(aTag);
      aTag.click();
      aTag.remove();
      URL.revokeObjectURL(aTag.href);
    }
  } catch (error) {
    return;
  }
}

export function BulkUploadBase64Files(data, token) {  // completed
  return Axios(`File/UploadBase64File`, "post", data, token);
}
export function getAttachmentFile(data, token) {
  return Axios(`File/GetImageBase64`, "post", data, token);
}
export function UploadBase64Files(data, token) {  //completed
  return Axios(`File/BulkUploadBase64Files`, "post", data, token);
}
export function DownloadBase64File(azureBlobUrl, token) {
  return Axios(
    `File/DownloadBase64File?azureBlobUrl=${azureBlobUrl}`,
    "get",
    null,
    token
  );
}
export function GetAllImageFiles(data, token) {
  return Axios(
    `File/GetAllFiles?id=${data?.id}&module=${data?.module}`,
    "get",
    null,
    token
  );
}
