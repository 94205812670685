import Axios from '../Helpers/Axios';
import axios from 'axios';

export function GetUserList(take, skip, token, searchValue, roleId, departmentId, orgId, status) {
    return Axios(`User/GetSystemUsers?skip=${skip}&take=${take}&searchTerm=${searchValue || ""}&roleId=${roleId || ""}&orgId=${orgId || ""}&departmentId=${departmentId || ""}${status ? `&status=${status}&showAll=false` : ""}&forExport=false`, 'get', null, token)
}
export function getUserListWithSearch(take, skip, token, searchTerm) {
    return Axios(`User/GetSystemUsers?skip=${skip}&take=${take}&searchTerm=${searchTerm}`, 'get', null, token)
}
export function GetDepartmentList(token) {
    return Axios(`Master/Department`, 'get', null, token)
}

export function AddUser(data, token) {
    return Axios(`User/Add`, 'post', data, token)
}
export function EditUser(data, token) {
    return Axios(`User/Edit`, 'post', data, token)
}
export function IsUserNameExist(username, token) {
    return Axios(`User/IsUserNameExist?userName=${username}`, 'get', null, token)
}
export function IsEmailExist(email, token) {
    return Axios(`User/IsEmailExist?email=${email}`, 'get', null, token)
}
export const getNationality = async () => {
    return await axios.get("https://restcountries.com/v3.1/all?fields=name,capital,currencies").then(res => {
        if (res?.status == 200) {
            return { nationalityList: res?.data, status: "200" };
        }
    }).catch(error => console.log(error))
}

export function GetAllSystemUsers(token) {
    return Axios(`User/GetAllSystemUsers`, 'get', null, token)
}