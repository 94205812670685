import style from "./style.module.scss";
import React, { useEffect, useState } from "react";
// -------------Imports---------------
// import { Button } from 'primereact/button'
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

// -----------------------------------

// -----------------------------------

//------Imports from other Components-----
import { GetChatList, GetFeedbackList } from "../../Services/FeedbackService";
import { NewChat } from "../../Services/FeedbackService";
//----------------------------------------

//-----------Context ---------------
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { GetAllFiles } from "../../Services/Files";
import { CiImageOn } from "react-icons/ci";
import { ScrollPanel } from "primereact/scrollpanel";

import { DownloadFile } from "../../Services/Files";
import { InputTextarea } from "primereact/inputtextarea";
import UseCookies from "../../Helpers/UseCookies";

function FeedbackView() {
    const { id } = useParams();
    const Context = useSelector((state) => state.AppContext);
    const [isAddOrganization, setIsAddOrganization] = useState(false);
    const [selectedFeedback, setSelectedFeedback] = useState([]);
    const [chatList, setChatList] = useState([]);
    const [chat, setChat] = useState("");
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [chatError, setChatError] = useState("");
    const navigate = useNavigate();
    const [feedbackData, setFeedbackData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    //console.log(Context);
    const [fileList, setFileList] = useState([]);
    const formatDate = (timestamp) => {
        const timestampDate = new Date(timestamp);

        return timestampDate.toLocaleString("en-US", {
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        });
    };

    const getChatList = () => {
        setIsLoading(true);
        GetChatList(cookies()?.bearerToken, id)
            .then((res) => {
                //console.log("Fetching Chat list", res?.data);
                if (res?.status == 200) {
                    setChatList(res?.data);
                }
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };
    const sendChat = () => {
        if (!chat.trim()) {
            setChatError("No messages found");
            return;
        }
        const data = {
            feedbackId: id,
            message: chat.trim(),
        };
        NewChat(data, cookies()?.bearerToken)
            .then((res) => {
                //console.log(res);
                setChatList((prev) => {
                    return [
                        ...prev,
                        {
                            id: -1,
                            messageFrom: localStorage.getItem("userName"),
                            message: chat.trim(),
                            messageDate: new Date().toString(),
                        },
                    ];
                });

                setChat("");

                // dispatch(
                //     addChatList({
                //         chatList: res?.data
                //     }))
            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                // //console.log(chatList);
            });
    };
    const closePopup = () => {
        setIsAddOrganization(false);
        navigate("/feedbackdetails");
    };

    const downloadFile = (_) => {
        DownloadFile(_.fileName, _.blobUrl, cookies()?.bearerToken);
    };

    const header = () => {
        return (
            <div
                className="heading"
                style={{ fontWeight: "700", fontSize: "25px", color: "#ae9d81" }}
            ></div>
        );
    };
    const getAllFiles = async () => {
        await GetAllFiles(
            { id: id, inspectionId: "0", isForInspection: false, module: "Feedback" },
            cookies()?.bearerToken
        )
            .then((res) => {
                if (res?.status == 200) {
                    setFileList(res?.data);
                }
            })
            .catch((error) =>
                console.log(error)
            );
    };
    useEffect(() => {
        getChatList();
        getAllFiles();
    }, []);
    useEffect(() => {
        if (Context.feedbackList?.length > 0) {
            setFeedbackData(Context.feedbackList?.filter((_) => _?.id == id));
        }
    }, [Context.feedbackList, id]);
    return (
        <div>
            <div
                header={header}
                visible={true}
                style={{}}
                onHide={() => {
                    setIsAddOrganization(false);
                }}
            >
                <div className={style.head}>
                    <FontAwesomeIcon
                        icon={faArrowLeft}
                        className={style.arrowIcon}
                        style={{ cursor: "pointer" }}
                        onClick={closePopup}
                    />
                    View Feedback Details
                </div>
                <div className={classNames(style.flex, style.forMargin)}>
                    <div className="flex-1 p-4 flexbox-1" style={{ width: "33.33%" }}>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Customer Name{Context.name}</p>
                            <InputText
                                className={style.border}
                                value={`${feedbackData[0]?.firstName} ${feedbackData[0]?.lastName}`}
                                // value={`${selectedFeedback?.firstName} ${selectedFeedback?.lastName}`}
                                readOnly
                            />
                        </div>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Mobile Number</p>
                            <InputText
                                className={style.border}
                                value={feedbackData[0]?.mobileNumber}
                                readOnly
                            />
                        </div>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Email</p>
                            <InputText
                                className={style.border}
                                value={feedbackData[0]?.email}
                                readOnly
                            />
                        </div>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Nationality</p>
                            <InputText
                                className={style.border}
                                value={feedbackData[0]?.nationality}
                                readOnly
                            />
                        </div>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Attachments</p>
                            <div
                                style={{
                                    height: "100px",
                                    overflow: "auto",
                                    width: "350px",
                                    border: "solid 1px #AE9D81",
                                    borderRadius: "4px",
                                    padding: "10px",
                                }}
                            >
                                {fileList?.map((_) => {
                                    return (
                                        <div style={{ display: "flex", alignItems: "center" }}>
                                            <CiImageOn style={{ fontSize: 20 }} />
                                            <p
                                                style={{
                                                    fontSize: "12px",
                                                    padding: "0 10px",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    downloadFile(_);
                                                }}
                                            >
                                                {_.fileName}
                                            </p>
                                        </div>
                                    );
                                })}
                            </div>
                            {/* <InputText className={classNames(style.border, style.attachment)} /> */}
                        </div>
                    </div>
                    <div className="flex-1  p-4 flexbox-2" style={{ width: "33.33%" }}>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Category</p>
                            <InputText
                                className={style.border}
                                value={feedbackData[0]?.category}
                                readOnly
                            />
                        </div>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Sub Category</p>
                            <InputText
                                className={style.border}
                                value={feedbackData[0]?.subCategory}
                                readOnly
                            />
                        </div>
                        <div className="defaultWidth">
                            <p className={style.defaultStyle}>Location</p>
                            <InputText
                                className={style.border}
                                value={feedbackData[0]?.location}
                                readOnly
                            />
                        </div>
                        <div className={style.feedbackBox}>
                            <p className={style.defaultStyle} style={{ marginBottom: '0px' }}>Feedback Comments</p>
                            <p
                                className={classNames(style.border, style.feedback)}
                                style={{
                                    fontWeight: "100",
                                    border: "1px solid #ae9d81",
                                    width: "350px",
                                    padding: "10px",
                                    borderRadius: "5px",
                                    wordWrap: 'break-word',
                                    overflow: 'scroll',
                                    overflowX: 'hidden',
                                    marginTop: '0px'
                                }}
                            >
                                {feedbackData[0]?.comment}

                            </p>
                        </div>
                    </div>
                    <div className="vl" style={{ marginLeft: "0px" }}></div>
                    <div style={{ width: "33.33%", margin: "5px" }}>
                        <div
                            style={{
                                display: "flex",
                                padding: "6px 10px",
                                backgroundColor: "#ae9d81",
                                margin: "10px 10px",
                                justifyContent: "center",
                            }}
                        >
                            <p style={{ color: "#fff", margin: 0 }}>Chat Box</p>
                        </div>
                        <div
                            style={{ margin: "5px", display: "flex", alignItems: "center" }}
                        >
                            <InputTextarea
                                // type="text"
                                className="p-inputtext-lg"
                                rows={2}
                                style={{
                                    width: "88%", height: "40px", fontSize: "15px", marginLeft: '5px', overflowWrap: 'break-word', overflow: 'scroll', overflowX: 'hidden', overflowY: 'hidden'
                                }}
                                value={chat}
                                placeholder="Comments"
                                multiple
                                onChange={(e) => {
                                    setChat(e.target.value);
                                }}
                            />
                            <i
                                className="pi pi-send"
                                style={{
                                    fontSize: "1.5rem",
                                    color: "#AE9D81",
                                    margin: "10px",
                                    transform: "rotateZ(45deg)",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    sendChat();
                                }}
                            ></i>
                        </div>
                        {isLoading ? (
                            <div
                                className="spinner spinner-center"
                                style={{ marginLeft: "200px", width: "30px", height: "30px" }}
                            ></div>
                        ) : (
                            <div>
                                {chatList?.length !== 0 ? (
                                    <ScrollPanel
                                        style={{ width: "100%", height: "500px" }}
                                        className="custombar1"
                                    >
                                        <div
                                            className="flex-1 p-4 flexbox-3"
                                            style={{ overflowY: "scroll" }}
                                        >
                                            <div>
                                                {chatList.map((chat, index) => (
                                                    <div
                                                        key={chat.id}
                                                        className={
                                                            localStorage.getItem("userName") === chat.messageFrom
                                                                ? "user2"
                                                                : "user"
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                fontWeight: 200,
                                                                fontSize: "15px",
                                                                margin: "0px 0px 5px 0px",
                                                            }}
                                                        >
                                                            {chat?.messageFrom}
                                                        </div>
                                                        <div>
                                                            <p
                                                                style={{
                                                                    fontWeight: 400,
                                                                    margin: "0px",
                                                                    wordWrap: "break-word",
                                                                    width: "250px",
                                                                }}
                                                            >
                                                                {chat?.message}
                                                            </p>
                                                        </div>

                                                        <span
                                                            style={{
                                                                fontWeight: 200,
                                                                fontSize: "15px",
                                                                margin: "0px 0px -5px 0px",
                                                            }}
                                                        ></span>
                                                        <span
                                                            style={{
                                                                fontWeight: 200,
                                                                fontSize: "15px",
                                                                margin: "0px 0px -5px 0px",
                                                                float: "right",
                                                            }}
                                                        >
                                                            {formatDate(chat.messageDate)}
                                                        </span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </ScrollPanel>
                                ) : (
                                    <div>
                                        <div style={{ padding: "100px 0" }}>
                                            <p style={{ textAlign: "center" }}>No Chats Found</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                <div
                    className="flex justify-content-center"
                    style={{ margin: "0px 0px 30px " }}
                >
                    <button
                        className={style.cancelBtn}
                        onClick={closePopup}
                        style={{ cursor: "pointer" }}
                    >
                        Cancel
                    </button>
                    {/* <button className={style.submitBtn} >Submit</button> */}
                </div>
            </div>
        </div>
    );
}
export default FeedbackView;
