import React, { useState, useEffect } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import './style.scss'
// -------------Imports---------------
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Tag } from 'primereact/tag';
import { GetRolesList, AddRole, EditRole } from '../../Services/RoleService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'

// -----------------------------------

// ----------Assets Import------------
import editIcon from '../../../src/assets/images/editIcon.jpg';
// -----------------------------------

//-----------Context ---------------
import { useDispatch, useSelector } from "react-redux";
import { addOrganizationList, setNavVisibilty } from '../../Redux/AppSlice';
import { exportDataInExcel, exportExcel } from '../../Services/ExportServices';
import { Dropdown } from 'primereact/dropdown';
import { GetOrganizationList } from '../../Services/OrganizationService';
//----------------------------------------

function Role() {
    const Context = useSelector((state) => state.AppContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isAddRole, setIsAddRole] = useState(false);
    const [isAdd, setIsAdd] = useState(false);
    const [roleExists, setRoleExists] = useState(false)
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [organizationList, setOrganizationList] = useState([]);
    const [roleList, setRoleList] = useState([]);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [role, setRole] = useState({
        roleName: "", roleStatus: true, roleLabel: "", roleId: 0,
    })
    const [roleInvalid, setRoleInvalid] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalCount, setTotalRecords] = useState(0)
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [filterObj, setFilterObj] = useState({
        status: "",
        organization: 0
    });
    const onCancelClick = () => {
        setShowConfirmation(true);
    };

    const onHide = () => {
        setShowConfirmation(false);
        setIsAddRole(false);
        setRole({
            roleName: "", roleStatus: true, roleLabel: "", roleId: 0,
        });
    };

    const handleConfirmation = (confirmed) => {
        if (confirmed) {
            onHide();
        } else {
            setShowConfirmation(false);
        }
    };

    const onGlobalFilterChange = (e) => {

        const value = e ? e.target.value : "";
        let _filters = { ...filters };
        _filters['global'].value = value;
        setFilters(_filters);
        setGlobalFilterValue(value);
    };
    const statusBodyTemplate = ((row) => {
        // //console.log(row);
        if (row.isDeleted === false) {
            return <p style={{ color: "green" }}  >Active</p>
        }
        else {
            return <p style={{ color: "red" }} >Inactive</p>
        }
    });
    const organizationBodyTemplate = ((row) => {

        return organizationList?.filter(organization => organization?.id === row.organizationId)[0]?.name;
    });

    const header = () => {
        return <div className="heading" style={{ fontWeight: "700", fontSize: "25px", color: "#ae9d81", textAlign: 'center' }}>
            <p style={{ margin: '0px' }}>{isAdd ? "Add" : "Edit"} Role Details</p>
        </div>
    }

    const popup = (() => {
        return (
            <>

                <Dialog header={header} visible={isAddRole} style={{ width: '30vw' }}
                    closable={false} onHide={() => {
                        setIsAddRole(false); setRole({
                            roleName: "", roleLabel: "", roleStatus: true, roleId: 0
                        });
                    }}>
                    <div className="popup " >
                        {/* <div className="heading">
                            <p>Add New Details</p>
                        </div> */}
                        <div className="body"  >
                            <div className="label grid" >
                                <div className='col' >Role name<span style={{ color: 'red' }}>*</span></div>
                                <div className='col' style={{ position: 'absolute', left: '280px' }}>Status</div>
                            </div>
                            <div className="input grid ">
                                <div className="col">
                                    <InputText placeholder="Enter Name" value={role?.roleName} onChange={(e) => { setRoleExists(false); setRole((prev) => ({ ...prev, roleName: e.target.value })) }} />
                                    {roleInvalid && role?.roleName == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Role name is required</p>
                                        // </div>
                                    }
                                    {roleExists &&
                                        // <div>
                                        <p style={{ color: "red" }}>Role name is already exists</p>
                                        // </div>
                                    }
                                    {/* {roleInvalid && role?.roleName == "" &&
                                        // <div>
                                        <p style={{ color: "red" }}>Role name is required</p>
                                        // </div>
                                    } */}
                                </div>

                                <div className="col ">
                                    <label className="switch ">
                                        <input type="checkbox" checked={role?.roleStatus} onChange={(e) => { setRole((prev) => ({ ...prev, roleStatus: e.target.checked })); }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="footer flex  grid">
                            <div className="col flex justify-content-center ">
                                <Button label="Cancel" onClick={() => {
                                    setRoleExists(false);
                                    setIsAddRole(false);
                                    setRoleInvalid(false);
                                    setRole({
                                        roleName: "", roleStatus: true, roleLabel: "", roleId: 0,
                                    });
                                }} style={{ backgroundColor: '#8E8E8E', border: "none", margin: "10px", height: '40px' }} />
                                <Button label={isAdd ? "SUbmit" : "Update"} onClick={() => { isAdd ? addRole() : editRole() }} style={{ backgroundColor: '#ae9d81', border: "none", margin: "10px", height: '40px' }} />
                            </div>
                        </div>
                    </div>
                </Dialog>

            </>
        )
    })

    const addRole = (() => {
        let data = {
            "roleName": role?.roleName,
            "roleLabel": role?.roleName.toUpperCase().replace(/\s/g, ''),
            "isDeleted": !role?.roleStatus,

        };
        setIsAdd(true);
        if (role?.roleName == "") {
            setRoleInvalid(true);
            return;
        }
        // setRoleInvalid(false);
        setIsAdd(true);
        let roleExist = roleList.filter((r) => r.roleName == role?.roleName)
        //console.log('relelist', roleList)
        //console.log(roleExist);
        if (roleExist.length > 0) {
            setRoleExists(true);
            return;
        }
        setRoleExists(false);
        let token = cookies()?.bearerToken
        //console.log(role);
        setIsAddRole(false);
        AddRole(data, token)
            .then((res) => {
                //console.log(res);
                getRoleList();
                setRole({
                    roleName: "", roleLabel: "",
                    roleStatus: true,
                    roleId: 0
                });
                toast.success("New Role is added successfully", { position: "top-right" });
            })
            .catch((error) => {
                //console.log(error);
            })
    })

    const editRole = (() => {
        let data = {
            "roleName": role?.roleName,
            "roleLabel": role?.roleName.toUpperCase().replace(/\s/g, ''),
            "isDeleted": !role?.roleStatus,
            "id": role?.roleId
        };
        if (role?.roleName == "") {
            setRoleInvalid(true);
            return;
        }
        setRoleInvalid(false);
        //console.log("Edit role Data", data);
        let roleExist = roleList.filter((rol) => rol?.roleName == role?.roleName && rol?.id != role?.roleId)
        //console.log(roleExist);
        if (roleExist.length > 0) {
            setRoleExists(true);
            return;
        }
        setRoleExists(false);
        let token = cookies()?.bearerToken
        // //console.log(data, token);
        EditRole(data, token, role?.roleId)
            .then((res) => {
                getRoleList();
                setIsAddRole(false);
                setRole({
                    roleName: "", roleLabel: "", roleStatus: true, roleId: 0
                })
                toast.success("Role is updated successfully", { position: "top-right" });

            })
            .catch((error) => {
                //console.log(error);
            })

    });

    const back = () => {
        navigate('/dashboard');
    };

    const getRoleList = ((organization = 0) => {
        setIsLoading(true);
        GetRolesList(cookies()?.bearerToken, organization)
            .then((res) => {
                //console.log(res);
                setRoleList(res?.data?.map((role) => {
                    return {
                        roleName: role?.roleName,
                        roleLabel: role?.roleLabel,
                        roleStatus: !role?.isDeleted,
                        roleId: role?.id,
                        status: role?.isDeleted ? "Inactive" : "Active",
                        isDeleted: role?.isDeleted,
                        organizationId: role?.organizationId
                    }
                }));
                setTotalRecords(res?.data?.count);


            })
            .catch((error) => {
                console.warn(error);
            })
            .finally(() => {
                setIsLoading(false);
                // //console.log(organizationList);
            })
    });
    const exportRoleInfo = () => {
        let urlRoute = `Role?searchTerm=${globalFilterValue}&organizationId=${filterObj?.organization}`;
        exportExcel(urlRoute);
    };
    const getOrganizationList = (async () => {
        setIsLoading(true);
        await GetOrganizationList(cookies()?.bearerToken)
            .then((res) => {
                //console.log("Res", res);
                //console.log("status", res.status);
                //console.log("status", res?.data);
                if (res?.status === 200) {
                    //console.log("Fetching Organization List", res?.data);
                    setOrganizationList(res?.data?.data.map((_) => {
                        return {
                            "id": _?.id,
                            "name": _?.name,
                            "code": _?.code,
                            "createdOn": _?.createdOn,
                            "userName": _?.userName,
                            "status": _?.status,
                            "orgStatus": _?.status ? "Inactive" : "Active",

                        }
                    }
                    ));
                    setTotalRecords(res?.data?.count);
                }

            })
            .catch((error) => {
                //console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
                // //console.log(organizationList);
            })
    });
    useEffect(() => {
        getRoleList();
        getOrganizationList();
    }, [])

    return (
        <>
            <div className="roleMaster">
                <div className="header">
                    <div style={{ display: "flex", flexDirection: "row", flexGrow: "3", justifyContent: "flex-start" }} className='flex align-items-center org'>
                        <i className="pi pi-arrow-circle-left nav-back-btn" onClick={back} style={{ marginLeft: '30px', cursor: 'pointer', fontSize: '1.5rem' }} />
                        <div className="heading">
                            <p>Role</p>
                        </div>
                    </div>
                    {/* <div>
                        <Button label='Import' icon={"pi pi-file-import"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', marginRight: '20px' }} />
                    </div> */}
                    <div className='mx-2'>
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                        <div className="">
                            <div className='flex align-items-center' style={{ border: "solid 1px #AE9D81", borderRadius: "4px" }}>
                                <i className="pi pi-search pl-2 pr-1" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                                <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Search" className='border-0' style={{ height: "40px", width: 180 }} />
                                <i className="pi pi-times pl-1 pr-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer' }} onClick={() => { setGlobalFilterValue(""); onGlobalFilterChange() }} />
                            </div>
                        </div>
                    </div>
                    <div className='mx-2'>
                        <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Organization</h4>
                        <div className="">
                            <Dropdown value={filterObj?.organization} options={organizationList} optionLabel='name' optionValue='id' onChange={(e) => { setFilterObj((prev) => ({ ...prev, organization: e.value })); getRoleList(e.value); }} placeholder='Select' style={{ border: "solid 1px #ae9d81", width: 180 }} showClear={filterObj?.organization ? true : false} />
                        </div>
                    </div>
                    <div className='mx-2 align-self-start'>
                        <Button label='Export' onClick={roleList && roleList?.length > 0 ? exportRoleInfo : null} icon={"pi pi-file-export"} style={{ backgroundColor: 'transparent', color: '#ae9d81', border: '1px #ae9d81 solid', borderRadius: '20px', marginRight: '20px', cursor: roleList.length > 0 ? 'pointer' : 'not-allowed' }} />
                    </div>
                </div>

                <div className="datatable">
                    {isLoading ?
                        <div className='spinner spinner-center'></div>
                        : <DataTable value={roleList} style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }} tableStyle={{ width: '90rem' }} stripedRows
                            paginator rows={10}
                            globalFilterFields={['roleName', "status"]} filters={filters}
                            emptyMessage={<><p style={{ textAlign: "center" }}>No user roles found</p></>}>
                            <Column field="roleName" header="Role" style={{ width: '33.33%' }}></Column>
                            <Column field="organizationId" header="Organization" body={organizationBodyTemplate} style={{ width: '33.33%' }}></Column>
                            <Column field="roleStatus" header="Status" body={statusBodyTemplate} style={{ width: '33.33%' }}></Column>
                            {/* <Column header="Action" body={actionButtonTemplate} style={{ width: '33.33%' }}></Column> */}
                        </DataTable>}
                </div>
            </div >

            <div>
                {isAddRole == true ? popup() : ""}
            </div>
        </>
    )
}

export default Role