import React, { useEffect, useState } from "react";
import "./GuestInforMation.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setNavVisibilty, updateIsReceivedNotificationState } from "../../../Redux/AppSlice";
import UseCookies from "../../../Helpers/UseCookies";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Paginator } from "primereact/paginator";
import { CancelCheckInRequest, GetCheckInRequests } from "../../../Services/ShopAndDropService";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { toast } from "react-toastify";
import { HttpClient, HttpResponse } from "@microsoft/signalr";

const GuestInfoList = () => {
  const isReceivedNotification = useSelector((state) => state?.AppContext?.isReceivedNotification);
  const [isLoader, setIsLoader] = useState(false);
  const [cookies, setCookie, removeCookie] = UseCookies();
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [checkInLists, setCheckInLists] = useState([]);
  const [isConformationOpen, setIsConformationOpen] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [checkinId, setCheckinId] = useState("");
  console.log(checkInLists);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const getAllCheckInrequest = async (skip, take) => {
    setIsLoader(true);
    await GetCheckInRequests(
      skip,
      take,
      globalFilterValue,
      cookies()?.bearerToken
    )
      ?.then((res) => {
        if (res?.status == 200) {
          setCheckInLists(res?.data?.data);
          setTotalCount(res?.data?.count);
          setIsLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      }).finally(() => {
        dispatch(updateIsReceivedNotificationState(false));
      });
    setIsLoader(false);
  };

  const handleNavigate = (row) => {
    console.log(row);
    navigate("/shop-and-drop?id=" + encodeURI(row.id));
  };

  const onPageChange = (e, index) => {
    setFirst(e.first);
    getAllCheckInrequest(e.first, 10);
  };

  const globalFilterFunction = async (skip, take, value) => {
    // setIsLoader(true);
    await GetCheckInRequests(skip, take, value, cookies()?.bearerToken)
      ?.then((res) => {
        if (res?.status == 200) {
          setCheckInLists(res?.data?.data);
          setTotalCount(res?.data?.count);
          // setIsLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const fullNameBodyTemplate = (row) => {
    return (
      <>
        <div style={{ display: "flex", gap: "15px" }}>
          {row.firstName + " " + row.lastName}
        </div>
      </>
    );
  };

  const actionButtonTemplate = (row) => {
    return (
      <>
        <div style={{ display: "flex", gap: "25px", justifyContent: "center" }}>
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <i
              className="pi pi-pencil"
              onClick={() => {
                handleNavigate(row);
              }}
              style={{ fontSize: "1rem", cursor: "pointer" }}
            />
            <i
              className="pi pi-times"
              onClick={() => {
                setIsConformationOpen(true);
                setCheckinId(row?.id);
              }}
              style={{ fontSize: "1rem", cursor: "pointer", color: "red" }}
            />
          </div>
        </div>
      </>
    );
  };

  const cancelCheckinRequest = async (checkInRequestId) => {
    await CancelCheckInRequest(checkInRequestId, cookies()?.bearerToken)
      .then((res) => {
        if (res?.status === 200) {
          console.log(res);
          toast.success("Check-in request cancelled successfully")
        }
      })
      .catch(() => {

      })
      .finally(() => {
        getAllCheckInrequest(0, 10);
      })
  }
  useEffect(() => {
    getAllCheckInrequest(0, 10);
  }, []);

  useEffect(() => {
    // Receiver for real time notifiaction
    if (!isReceivedNotification) return
    getAllCheckInrequest(0, 10);
    setGlobalFilterValue("");
    //
  }, [isReceivedNotification])

  return (
    <>
      {isLoader ? (
        <div
          className="spinner spinner-center"
          style={{ marginLeft: "750px" }}
        ></div>
      ) : (
        <div className="track-deliveries ">
          <div className="track-deliveries-header">
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>

              <div className="menubtn" style={{ padding: "20px" }}>
                <i
                  className="pi pi-align-justify"
                  style={{
                    fontSize: "1rem",
                    color: "#ae9d81",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    dispatch(setNavVisibilty({ sideNavVisibility: true }))
                  }
                ></i>
              </div>


              <h2>HF Check-in Request</h2>
            </div>
            <div className="mx-2">
              <h4 className='mb-1 mt-0 relative' style={{ color: "#ae9d81" }}>Search</h4>
              <div className='flex align-items-center mx-2' style={{ border: "solid 1px #AE9D81", borderRadius: "4px", height: "42px" }}>
                <i className="pi pi-search px-2" style={{ color: 'black', fontWeight: '700', fontSize: '1rem' }} />
                <InputText value={globalFilterValue} onChange={(e) => {
                  if (e?.target?.value?.length <= 30) {
                    globalFilterFunction(0, 10, e.target.value);
                    setGlobalFilterValue(e.target.value);
                  }
                }} placeholder="Search" className='border-0' style={{ width: "180px" }} />
                <i className="pi pi-times px-2" style={{ color: 'black', fontWeight: '400', fontSize: '1rem', cursor: 'pointer' }} onClick={() => {
                  globalFilterFunction(0, 10, "");
                  setGlobalFilterValue("");
                }} />
              </div>
              {/* <div
                  className="search guestInfoList-search"
                >
                  <span className="p-input-icon-left">
                    <i
                      className="pi pi-search"
                      style={{
                        color: "black",
                        fontWeight: "700",
                        fontSize: "1rem",
                        margin: "-7px",
                        padding: "0px 10px 0px 14px",
                      }}
                    />
                    <InputText
                      style={{ padding: "10px 30px" }}
                      value={globalFilterValue}
                      onChange={(e) => {
                        if (e?.target?.value?.length <= 30) {
                          globalFilterFunction(0, 10, e.target.value);
                          setGlobalFilterValue(e.target.value);
                        }
                      }}
                      placeholder="Search"
                    />
                    {globalFilterValue && (
                      <i
                        className="pi pi-times"
                        style={{
                          color: "black",
                          fontSize: "1rem",
                          fontWeight: "400",
                          margin: "-7px 5px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          globalFilterFunction(0, 10, "");
                          setGlobalFilterValue("");
                        }}
                      />
                    )}
                  </span>
                </div> */}
            </div>

          </div>
          <div className="track-deliveries-content ">
            <div className="track-deliveries-table">
              <DataTable
                value={checkInLists}
                style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                tableStyle={{ width: "100%" }}
                stripedRows
                rows={10}
                emptyMessage={
                  <>
                    <p style={{ textAlign: "center" }}>List is empty</p>
                  </>
                }
              >
                {/* <Column field="guestID" header="Guest ID"></Column> */}
                <Column
                  field="firstName"
                  header="Guest Name"
                  body={fullNameBodyTemplate}
                ></Column>
                <Column field="emailId" header="Email ID"></Column>
                <Column field="phoneNumber" header="Mobile Number"></Column>
                <Column field="createdOn" header="Requested Date"></Column>
                {!cookies()?.roles.includes("HF_STORE") && <Column
                  field="createdOn"
                  header="Action"
                  body={actionButtonTemplate}
                  style={{ width: "5%" }}
                ></Column>}
              </DataTable>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {checkInLists.length > 0 ? (
                <Paginator
                  first={first}
                  rows={10}
                  totalRecords={totalCount}
                  onPageChange={(e) => onPageChange(e, 1)}
                  template={{
                    layout:
                      "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                  }}
                  className="justify-content-start"
                />
              ) : null}
            </div>
          </div>
        </div>
      )}



      <Dialog
        header={
          <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
            Confirmation
          </h3>
        }
        visible={isConformationOpen}
        style={{ width: "30%" }}
        onHide={() => {
          setIsConformationOpen(false);
        }}
      >
        <div className="track-delivery-view-success-modal">
          <div className="success-modal-content">
            <p style={{ fontSize: "18px" }}>
              Are you sure want to cancel the check-in request?
            </p>
          </div>
          <div className="success-modal-btn" style={{ gap: "10px" }}>
            <Button
              label={"No"}
              style={{
                backgroundColor: "#8e8e8e",
                border: "none",
                borderRadius: "3px",
                width: "120px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setIsConformationOpen(false);
              }}
            />
            <Button
              label={"Yes"}
              style={{
                backgroundColor: "#ae9d81",
                border: "none",
                borderRadius: "3px",
                width: "120px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => {
                setIsConformationOpen(false);
                cancelCheckinRequest(checkinId);
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default GuestInfoList;
