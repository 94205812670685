import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import UseCookies from "../../Helpers/UseCookies";
import "./style.scss";
import { toast } from "react-toastify";

// Assets
import editIcon from "../../assets/images/editIcon.jpg";
// -----------------------------

// PrimeReact Components
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar";
// -------------------------------------------------------------

// Context
import { useDispatch, useSelector } from "react-redux";
import { setNavVisibilty } from "../../Redux/AppSlice";
import {
  AddAndEditLostForm,
  GetLostItemListForGSR,
  GetLostItemList,
  GetFoundItemList,
  GetActiveStorageLocation,
  ExportFoundItems,
  GetAllFoundedItems,
} from "../../Services/LostandFoundService";
import { exportDataInLandD, exportFilterdExcel } from "../../Services/ExportServices";
import FileView from "../utili/FileView";
import { Dialog } from "primereact/dialog";
import { GetAllFiles, GetAllImageFiles } from "../../Services/Files";
// -----------------------------------------------

function LostAndFound() {
  const { tab } = useParams();
  const [cookies, setCookie, removeCookie] = UseCookies();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalCount, setTotalRecords] = useState(0);
  const [isLoadingDashboardList, setIsLoadingDashboardList] = useState(false);
  const [isLoadingLostList, setIsLoadingLostList] = useState(false);
  const [isLoadingFoundList, setIsLoadingFoundList] = useState(false);
  const [isLoadingDashboardFoundList, setIsLoadingDashboardFoundList] =
    useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  // Filters state values
  const [dashboardFilterValue, setDashboardFilterValue] = useState("");
  const [dashboardFoundFilterValue, setDashboardFoundFilterValue] =
    useState("");
  const [lostFilterValue, setLostFilterValue] = useState("");
  const [foundFilterValue, setFoundFilterValue] = useState("");
  // Pagination state values
  const [first, setFirst] = useState(0);
  const [firstDashboard, setFirstDashboard] = useState(0);
  const [firstFoundDashboard, setFirstFoundDashboard] = useState(0);
  const [firstLost, setFirstLost] = useState(0);
  const [firstFound, setFirstFound] = useState(0);

  // List count state values
  const [dashboardListCount, setDashboardListCount] = useState(0);
  const [dashboardFoundCount, setDashboardFoundCount] = useState(0);
  const [foundListCount, setFoundDashboardListCount] = useState(0);
  const [lostListCount, setLostDashboardListCount] = useState(0);

  // List state
  const [LostList, setLostList] = useState([]);
  const [FoundList, setFoundList] = useState([]);
  const [lostItemList, setLostItemList] = useState([]);
  const [foundItemList, setFoundItemList] = useState([]);
  const [storageLocation, setStorageLocation] = useState([]);

  // Date
  const currentDate = new Date();
  const sixMonthsAgo = new Date(currentDate);
  sixMonthsAgo.setMonth(currentDate.getMonth() - 6);
  const [fromDate, setFormDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Filters
  const onDasboardFilterChange = (e) => {
    if (!e) {
      getList(0, 10, "");
      return;
    }
    setDashboardFilterValue(e.target.value ? e.target.value : "");
    //console.log(e.target.value);
    getList(0, 10, e.target.value);
  };

  const onDasboardFoundFilterChange = (e) => {
    if (!e) {
      getFoundItems(10, 0, "");
      return;
    }
    setDashboardFoundFilterValue(e?.target?.value);
    getFoundItems(10, 0, e?.target?.value);
  };

  const onLostFilterChange = (e) => {
    if (!e) {
      getLostItemList(0, 10, "");
      return;
    }
    setLostFilterValue(e.target.value ? e.target.value : "");
    getLostItemList(0, 10, e.target.value);
  };
  const onFoundFilterChange = (e) => {
    if (!e) {
      getFoundItemList(0, 10, "");
      return;
    }
    setFoundFilterValue(e.target.value ? e.target.value : "");
    getFoundItemList(0, 10, e.target.value);
  };

  //    Pagination
  const onDashboardPageChange = (e) => {
    setFirstDashboard(e.first);
    getList(e.first, 10);
  };
  const onDashboardFoundPageChange = (e) => {
    setFirstFoundDashboard(e.first);
    getFoundItems(10, e.first);
  };
  const onLostPageChange = (e) => {
    setFirstLost(e.first);
    getLostItemList(e.first, 10, "");
  };
  const onFoundPageChange = (e) => {
    setFirstFound(e.first);
    getFoundItemList(e.first, 10, "");
  };
  // -------------------------------------------------------------

  // Dashboard
  const actionBodyTemplate = (row) => {
    return (
      <div
        onClick={() => {
          navigate(`/editlostform/${row?.id}/g1`);
        }}
      >
        <img src={editIcon} style={{ cursor: "pointer" }} />
      </div>
    );
  };
  const actionBodyFoundTemplate = (row) => {
    return (
      <div
        onClick={() => {
          navigate(`/editfounditem/${row?.id}/g2`);
        }}
      >
        <img src={editIcon} style={{ cursor: "pointer" }} />
      </div>
    );
  };
  const actionLostBodyTemplate = (row) => {
    return (
      <div
        onClick={() => {
          navigate(`/editlostitem/${row?.id}/g3`);
        }}
      >
        <img src={editIcon} style={{ cursor: "pointer" }} />
      </div>
    );
  };

  //Date
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  yesterday.setHours(0, 0, 0, 0);
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const actionFoundBodyTemplate = (row) => {
    //console.log("Row", row);
    return (
      <div>
        <img
          src={editIcon}
          style={{ cursor: "pointer", margin: "15px", height: "auto" }}
          onClick={() => {
            navigate(`/editfounditem/${row?.id}/g4`);
          }}
        />
        <Button
          label={row?.status === "Pending" ? "Return" : "Returned"}
          disabled={row?.status !== "Pending"}
          style={{
            color: "#fff",
            background: "#ae9d81",
            border: "none",
            marginLeft: "70px",
            width: "105px",
            height: "40px",
            cursor: row?.status === "Pending" ? "pointer" : "not-allowed",
          }}
          onClick={() => {
            if (row?.status === "Pending") {
              navigate(`/returnform/${row?.id}/g4`);
            }
          }}
        ></Button>
      </div>
    );
  };
  const nameBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          {rowData.firstName} {rowData.lastName}
        </div>
      </div>
    );
  };
  const storageBodyTemplate = (rowData) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>{rowData?.storageLocation}</div>
      </div>
    );
  };
  const dateAllBodytemplate = (row) => {
    const dateTime = new Date(row?.lostDateTime);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {day + "/" + month + "/" + year + " " + hour + ":" + minutes}
        </p>
      </div>
    );
  };
  const dateAllFoundBodytemplate = (row) => {
    const dateTime = new Date(row?.foundDateTime);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {day + "/" + month + "/" + year + " " + hour + ":" + minutes}
        </p>
      </div>
    );
  };
  const [isFilemodelView, setISFileModelView] = useState(false);

  const imageActionTemplate = (row) => {
    return (
      <>
        <div
          style={{ display: "flex", cursor: "pointer" }}
          className="col-6"
          onClick={() => {
            getImage(row?.id);
          }}
        >
          <img
            src={"data:image/jpeg;base64," + row?.base64}
            alt="Founded item image"
            style={{ width: "100px", height: "100px" }}
          />
          {/* <img src={editIcon} style={{ cursor: 'pointer' }} /> */}
          {/* <i className='pi pi-eye' style={{ cursor: 'pointer', fontSize: "1rem", display: 'flex', alignItems: 'center', margin: "0px 5px" }}></i> */}
        </div>
        <div className="flex col-6'"></div>
      </>
    );
  };
  const [files, setFileList] = useState([]);
  const [isFilePreview, setisFilePreview] = useState(false);
  const [isFilePreviewImage, setisFilePreviewImage] = useState(false);
  const getImage = async (id) => {
    let data = {
      id: id,
      module: "Found",
      isForInspection: "",
      inspectionId: "",
    };
    await GetAllImageFiles(data, cookies()?.bearerToken)
      .then((res) => {
        if (res.status === 200) {
          setFileList(res?.data);
          setISFileModelView(true);
          //console.log(res?.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  const dateLostBodytemplate = (row) => {
    const dateTime = new Date(row?.lostDateTime);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {day + "/" + month + "/" + year + " " + hour + ":" + minutes}
        </p>
      </div>
    );
  };
  const dateFoundBodytemplate = (row) => {
    const dateTime = new Date(row?.foundDateTime);
    const year = dateTime.getFullYear();
    const month = dateTime.getMonth() + 1;
    const day = dateTime.getDate();
    const hour = dateTime.getHours();
    const minutes = dateTime.getMinutes();

    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {day + "/" + month + "/" + year + " " + hour + ":" + minutes}
        </p>
      </div>
    );
  };
  const createdDateBodytemplate = (row) => {
    const [date, month, year] = row?.createdDate.split("/");
    return (
      <div>
        <p style={{ fontSize: "1rem", fontWeight: 400 }}>
          {date + "/" + month + "/" + year}
        </p>
      </div>
    );
  };
  // --------------------------------------------------------------------

  const getList = async (skip, take, searchTerm = "") => {
    setIsLoadingDashboardList(true);
    await GetLostItemListForGSR(skip, take, searchTerm, cookies()?.bearerToken)
      .then((res) => {
        if (res.status === 200) {
          setLostList(res.data.data);
          setDashboardListCount(res.data.count);
          //console.log(res?.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        setIsLoadingDashboardList(false);
      });
  };
  const getLostItemList = (skip, take, searchTerm = "", startDate = "", endDate = "") => {
    setIsLoadingLostList(true);
    GetLostItemList(skip, take, searchTerm, cookies()?.bearerToken, startDate, endDate)
      .then((res) => {
        if (res.status === 200) {
          setLostItemList(res.data.data);
          setLostDashboardListCount(res.data.count);
          //console.log(res?.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        setIsLoadingLostList(false);
      });
  };
  const getFoundItemList = async (skip, take, searchTerm = "") => {
    setIsLoadingFoundList(true);
    await GetFoundItemList(skip, take, searchTerm, cookies()?.bearerToken)
      .then((res) => {
        if (res.status === 200) {
          setFoundItemList(res.data.data);
          setFoundDashboardListCount(res.data.count);
          //console.log("FOund Item List", res?.data?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      })
      .finally(() => {
        setIsLoadingFoundList(false);
      });
  };

  // Get Storage Location
  const getStorageLocation = async () => {
    await GetActiveStorageLocation(cookies()?.bearerToken)
      .then((res) => {
        if (res.status === 200) {
          //console.log("Storage Location", res?.data);
          setStorageLocation(res?.data);
        }
      })
      .catch((err) => {
        //console.log(err);
      })

      .finally(() => { });

  };

  // Date
  const isValidateDate = () => {
    return new Date(fromDate) <= new Date(toDate);
  };
  const onDateChanging = () => {
    if (!isValidateDate()) {
      toast.info("To date must be greater than from date");
      return;
    }
    let date1 = new Date(fromDate);
    let date2 = new Date(toDate);

    date1 = `${date1?.getMonth() + 1
      }/${date1?.getDate()}/${date1?.getFullYear()}`;
    date2 = `${date2?.getMonth() + 1
      }/${date2?.getDate()}/${date2?.getFullYear()}`;

    // //console.log(date1, date2);
    if (tab == "lost-list") {
      getLostItemList(0, 10, lostFilterValue, date1, date2);
    }
    if (tab == "found-list") {
      getAllFoundedItems(10, 0, foundFilterValue, date1, date2);
    }
    // getAllFoundedItems(10, 0, foundFilterValue, date1, date2);
  };

  const getAllFoundedItems = async (
    take,
    skip,
    searchValue = "",
    fromDate = "",
    toDate = ""
  ) => {
    setIsLoadingFoundList(true);

    await GetAllFoundedItems(
      take,
      skip,
      searchValue,
      fromDate,
      toDate,
      cookies()?.bearerToken
    )
      .then((res) => {
        if (res.status == 200) {
          setFoundItemList(res.data.data);
          setFoundDashboardListCount(res.data.count);
        }
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        setIsLoadingFoundList(false);
      });
  };

  const getFoundItems = async (take, skip, searchTerm = "") => {
    setIsLoadingDashboardFoundList(true);
    await GetAllFoundedItems(
      take,
      skip,
      searchTerm,
      "",
      "",
      cookies()?.bearerToken
    )
      .then((res) => {
        if (res.status == 200) {
          setFoundList(res?.data?.data);
          setDashboardFoundCount(res?.data?.count);
        }
      })
      .catch((error) => {
        //console.log(error);
      })
      .finally(() => {
        setIsLoadingDashboardFoundList(false);
      });
  };

  const onClearAllDate = () => {
    setFormDate(null);
    setToDate(null);
    setFoundFilterValue("");
    setLostFilterValue("")
    // getAllFoundedItems(10, 0, "");
    if (tab == "lost-list") {
      getLostItemList(0, 10, "");
    }
    if (tab == "found-list") {
      getAllFoundedItems(10, 0, "");
    }
  };

  const OnExportClick = async () => {
    // let route = "LostDetails?";
    // let param = `strUserId=${cookies()?.userId}&searchTerm=${dashboardFilterValue}`;
    // exportDataInLandD(route, param);

    let urlRoute = `LostAndFound/Lost/GetAll?skip=0&take=${dashboardListCount}&searchTerm=${dashboardFilterValue}&forExport=true`;
    exportFilterdExcel(urlRoute, cookies()?.bearerToken);
  };
  const OnExportFoundDashboardClick = async () => {
    let route = "FoundDetails?";
    let param = `strUserId=${btoa(0)}&searchTerm=${dashboardFoundFilterValue}`;
    exportDataInLandD(route, param);
  };
  const OnExportLostClick = async () => {
    // let route = "LostDetails?";
    // let param = `strUserId=${btoa(0)}&searchTerm=${dashboardFilterValue}`;
    // exportDataInLandD(route, param);
    const start = fromDate
      ? fromDate.getFullYear() + '/' +
      (fromDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      fromDate.getDate().toString().padStart(2, '0') : "";

    const end = toDate
      ? toDate.getFullYear() + '/' +
      (toDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      toDate.getDate().toString().padStart(2, '0')
      : "";
    let urlRoute = `LostAndFound/Lost/All?skip=0&take=${lostListCount}&searchTerm=${lostFilterValue}&from=${start}&to=${end}&forExport=true`;
    exportFilterdExcel(urlRoute, cookies()?.bearerToken);
  };
  const OnExportFoundClick = async () => {
    // let date1 = "";
    // let date2 = "";
    // if (fromDate && toDate) {
    //   date1 = new Date(fromDate);
    //   date2 = new Date(toDate);

    //   date1 = `${date1?.getMonth() + 1
    //     }/${date1?.getDate()}/${date1?.getFullYear()}`;
    //   date2 = `${date2?.getMonth() + 1
    //     }/${date2?.getDate()}/${date2?.getFullYear()}`;

    // }
    // let route = "FoundDetails?";
    // let param = `searchTerm=${foundFilterValue}&from=${date1}&to=${date2}`;
    // exportDataInLandD(route, param);
    const start = fromDate
      ? fromDate.getFullYear() + '/' +
      (fromDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      fromDate.getDate().toString().padStart(2, '0') : "";

    const end = toDate
      ? toDate.getFullYear() + '/' +
      (toDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
      toDate.getDate().toString().padStart(2, '0')
      : "";
    let urlRoute = `LostAndFound/Found/All?skip=0&take=${foundListCount}&searchTerm=${foundFilterValue}&from=${start}&to=${end}&forExport=true`;
    exportFilterdExcel(urlRoute, cookies()?.bearerToken);
  };

  // TabView
  const handleTabChange = (index) => {
    console.log("Index", index);
    // setActiveIndex(index);
    setDashboardFilterValue("");
    setDashboardFoundFilterValue("");
    setLostFilterValue("");
    setFoundFilterValue("");
    if (index === 0) {
      navigate(`/lostandfound/lost`);
    }
    if (index === 1) {
      navigate(`/lostandfound/lost-list`);
    }
    if (index === 2) {
      navigate(`/lostandfound/found-list`);
    }
  };
  // -------------------------------------------------------------

  useEffect(() => {
    getStorageLocation();
    if (tab == "lost") {
      setActiveIndex(0);
      getList(0, 10, "");
    }
    else if (tab == "lost-list") {
      setActiveIndex(1);
      getLostItemList(0, 10, "");
    } else if (tab == "found-list") {
      setActiveIndex(2);
      getFoundItemList(0, 10, "");
    }
  }, [tab]);

  return (
    <>
      <Dialog
        header={"Attachments"}
        visible={isFilemodelView}
        // modal={false}
        style={{ width: "20vw" }}
        onHide={() => setISFileModelView(false)}
      >
        <>
          {files.length > 0 && (
            <div className="file-list">
              {files.map((file, index) => {
                return (
                  <div className="file-item" key={index}>
                    <div
                      className="file-name"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setisFilePreview(true);
                        setisFilePreviewImage(file?.base64);
                      }}
                    >
                      {file?.fileModule + file?.fileName}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          {/* <img src={"data:image/jpeg;base64," + files[0]?.base64} alt="" srcset="" /> */}
        </>
      </Dialog>
      <Dialog
        // header={<div><p style={{ textAlign: "center" }}>Image Preview</p></div>}
        visible={isFilePreview}
        style={{ width: "80vw", height: "90vh" }}
        onHide={() => setisFilePreview(false)}
      >
        <div style={{ display: "flex" }}>
          <img
            style={{ margin: "auto" }}
            src={"data:image/jpeg;base64," + isFilePreviewImage}
            alt=""
            srcset=""
          />
        </div>
      </Dialog>
      {/* <FileView visible={isFilemodelView} imagelist={imageList} setVisible={setISFileModelView} header={"Attachments"} /> */}

      <div className="lostandfound">
        <div className="header">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexGrow: "3",
              justifyContent: "flex-start",
            }}
            className="flex align-items-center org"
          >
            <div className="menubtn" style={{ padding: "10px 10px 5px 20px" }}>
              <i
                className="pi pi-align-justify"
                style={{
                  fontSize: "1rem",
                  color: "#ae9d81",
                  cursor: "pointer",
                }}
                onClick={() =>
                  dispatch(setNavVisibilty({ sideNavVisibility: true }))
                }
              ></i>
            </div>

            <div
              className="heading h-auto"
              style={{ paddingLeft: "15px", marginLeft: "40px" }}
            >
              <p
                style={{
                  color: "#2d6c8b",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  lineHeight: "normal",
                  letterSpacing: "-0.3px",
                }}
              >
                Lost and Found - Guest
              </p>
            </div>
          </div>
        </div>
        <div className="card" style={{ padding: "0 10px" }}>
          <TabView
            activeIndex={activeIndex}
            style={{ marginLeft: "20px" }}
            onTabChange={(e) => {
              handleTabChange(e.index);
              setLostFilterValue("");
              setFoundFilterValue("");
              setToDate(null);
              setFormDate(null);
            }}
          >
            <TabPanel
              header="Lost Dashboard"

              className={`${activeIndex === 0 ? "active_tab tab_view" : "tab_view"}`}

            // {activeIndex === 0 ?active_tab : ''}

            >
              <div
                className="tab_header"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  position: "absolute",
                  right: "0px",
                  top: "128px"
                }}
              >
                <div className="mx-2">
                  <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                  <div
                    className="search"
                    style={{
                      border: "solid 1px #ae9d81",
                      borderRadius: "5px",
                    }}
                  >

                    <span className="d-flex align-items-center" style={{ height: "40px", }}>
                      <i
                        className="pi pi-search"
                        style={{
                          color: "black",
                          fontWeight: "700",
                          fontSize: "1rem",
                          cursor: "pointer",
                          padding: "0 0 0 10px"
                        }}
                      />
                      <InputText
                        value={dashboardFilterValue}
                        onChange={onDasboardFilterChange}
                        placeholder="Search"
                        style={{ border: "unset", width: 170 }}
                      />
                      <i
                        className="pi pi-times"
                        style={{
                          color: "black",
                          fontWeight: "400",
                          fontSize: "16px",
                          padding: "0 10px"
                        }}
                        onClick={() => {
                          setDashboardFilterValue("");
                          onDasboardFilterChange(null);
                        }}
                      />
                    </span>
                  </div>
                </div>
                <div className="mx-2 align-self-end">
                  <Button
                    label="Add Lost Item"
                    onClick={() => {
                      navigate("/addlostform/g1");
                    }}
                    style={{
                      backgroundColor: "#ae9d81",
                      color: "#fff",
                      border: "1px #ae9d81 solid",
                      cursor: "pointer",
                    }}
                  />
                </div>
                <div className="mx-2 align-self-end">
                  <Button
                    label="Export"
                    onClick={OnExportClick}
                    icon={"pi pi-file-export"}
                    style={{
                      backgroundColor: "transparent",
                      color: "#ae9d81",
                      border: "1px #ae9d81 solid",
                      borderRadius: "20px",
                      cursor: "pointer",
                      height: "46px"
                    }}
                  />
                </div>
                <div>

                  {/* <Button label='Add Found Item' onClick={() => { navigate('/addfoundform/1') }} style={{ backgroundColor: '#ae9d81', color: '#fff', border: '1px #ae9d81 solid', marginRight: '20px', cursor: 'pointer', position: 'absolute', top: 150, height: '40px', right: 10 }} /> */}
                </div>
              </div>
              {isLoadingDashboardList ? (
                <div
                  className="spinner spinner-center"
                  style={{ marginLeft: "750px" }}
                ></div>
              ) : (
                <>
                  <DataTable
                    value={LostList}
                    tableStyle={{ width: "90rem" }}
                    style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                    emptyMessage={
                      <>
                        <p style={{ textAlign: "center" }}>
                          Lost list is empty
                        </p>
                      </>
                    }
                    rows={10}
                  >
                    <Column field="lostCode" header="Lost Id"></Column>
                    <Column
                      header="Customer Name"
                      body={nameBodyTemplate}
                    ></Column>
                    <Column field="category" header="Lost Category"></Column>
                    <Column
                      field="categoryAR"
                      header="Lost Category AR"
                    ></Column>
                    <Column
                      field="lostDateTime"
                      header="Lost Date and Time"
                      body={dateAllBodytemplate}
                    ></Column>
                    <Column
                      field="createdDate"
                      header="Created Date"
                      body={createdDateBodytemplate}
                    ></Column>
                    <Column
                      field="whereDidYouLose"
                      header="Lost Location"
                    ></Column>
                    <Column field="status" header="Status"></Column>
                    <Column header="Action" body={actionBodyTemplate}></Column>
                  </DataTable>
                </>
              )}
              {isLoadingDashboardList || LostList.length == 0 ? (
                ""
              ) : (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Paginator
                    first={firstDashboard}
                    rows={10}
                    totalRecords={dashboardListCount}
                    onPageChange={(e) => onDashboardPageChange(e, 1)}
                    template={{
                      layout:
                        "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                    }}
                    className="justify-content-start"
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel
              header="My Lost Items"
              className={activeIndex === 1 ? "active_tab tab_view" : "tab_view"}
            >
              <div className="absolute" style={{
                right: "0px",
                top: "130px"
              }}>

                <div className="flex align-items-center" style={{ justifyContent: "unset" }}>
                  <div className="mx-2">
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                    <div
                      className="search"
                      style={{
                        border: "solid 1px #ae9d81",
                        borderRadius: "5px",
                        width: '200px'
                      }}
                    >
                      <span className="" style={{ display: "flex", alignItems: "center", height: "40px", }}>
                        <i
                          className="pi pi-search"
                          style={{
                            color: "black",
                            fontWeight: "700",
                            fontSize: "1rem",
                            cursor: "pointer",
                            padding: "0 10px"
                          }}
                        />
                        <InputText
                          value={lostFilterValue}
                          onChange={onLostFilterChange}
                          placeholder="Search"
                          style={{ border: "unset", width: "100%" }}
                          className="p-0"
                        />
                        <i
                          className="pi pi-times"
                          style={{
                            color: "black",
                            fontWeight: "400",
                            fontSize: "16px",
                            padding: "0 10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setLostFilterValue("");
                            onLostFilterChange(null);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="mx-2">
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>From Date</h4>
                    <Calendar
                      value={fromDate}
                      placeholder="Select"
                      onChange={(date) => {
                        setFormDate(date?.value);
                      }}
                      showIcon
                      selectionMode="single"
                      style={{
                        color: "#fff",
                        cursor: "pointer",
                        // position: "absolute",
                        // right: '24%',
                        // top: ' 123px',
                        height: "40px",
                        width: "150px",
                      }}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="mx-2">
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>To Date</h4>
                    <Calendar
                      value={toDate}
                      placeholder="End Date"
                      onChange={(date) => setToDate(date?.value)}
                      selectionMode="single"
                      showIcon
                      style={{
                        color: "#fff",
                        cursor: "pointer",
                        // position: "absolute",
                        // right: '13%',
                        // top: ' 123px',
                        height: "40px",
                        width: "150px",
                      }}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="mx-2 align-self-end">
                    <Button
                      onClick={onDateChanging}
                      disabled={fromDate == "" || toDate == ""}
                      style={{
                        color: "#fff",
                        backgroundClip: "#ae9d81",
                        backgroundColor: "#ae9d81",
                        border: "unset",
                        cursor: "pointer",
                        // position: "absolute",
                        // right: "6.8%",
                        // top: "123px",
                        height: "38px",
                        lineHeight: "38px",
                        zIndex: 1
                      }}
                    >
                      Check
                    </Button>
                  </div>
                  <div className="mx-2 align-self-end">
                    <Button
                      onClick={() => {
                        onClearAllDate();
                      }}
                      style={{
                        color: "#fff",
                        backgroundColor: "#adadad",
                        backgroundClip: "#adadad",
                        cursor: "pointer",
                        // position: "absolute",
                        // right: "1.5%",
                        // top: "123px",
                        border: "unset",
                        height: "38px",
                        lineHeight: "38px",
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                  <div className="mx-2 align-self-end">
                    <Button
                      label="Export"
                      onClick={OnExportLostClick}
                      icon={"pi pi-file-export"}
                      style={{
                        backgroundColor: "transparent",
                        color: "#ae9d81",
                        border: "1px #ae9d81 solid",
                        borderRadius: "20px",
                        marginRight: "20px",
                        cursor: "pointer",
                        height: "46px",
                      }}
                    />
                  </div>
                </div>
              </div>
              {isLoadingLostList ? (
                <div
                  className="spinner spinner-center"
                  style={{ marginLeft: "750px" }}
                ></div>
              ) : (
                <>
                  <DataTable
                    value={lostItemList}
                    tableStyle={{ width: "90rem" }}
                    style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                    emptyMessage={
                      <>
                        <p style={{ textAlign: "center" }}>
                          Lost list is empty
                        </p>
                      </>
                    }
                    rows={10}
                  >
                    <Column field="lostCode" header="Lost Id"></Column>
                    <Column field="category" header="Lost Category"></Column>
                    <Column
                      field="categoryAR"
                      header="Lost Category AR"
                    ></Column>
                    <Column field="whatDidYouLose" header="Lost Item"></Column>
                    <Column
                      field="lostDateTime"
                      header="Lost Date and Time"
                      body={dateLostBodytemplate}
                    ></Column>
                    <Column
                      field="createdDate"
                      header="Created Date"
                      body={createdDateBodytemplate}
                    ></Column>
                    <Column
                      field="whereDidYouLose"
                      header="Lost Location"
                    ></Column>
                    <Column field="status" header="Status"></Column>
                    <Column
                      header="Action"
                      body={actionLostBodyTemplate}
                    ></Column>
                  </DataTable>
                </>
              )}

              {isLoadingLostList || lostItemList.length == 0 ? (
                ""
              ) : (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Paginator
                    first={firstLost}
                    rows={10}
                    totalRecords={lostListCount}
                    onPageChange={(e) => onLostPageChange(e, 1)}
                    template={{
                      layout:
                        "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                    }}
                    className="justify-content-start"
                  />
                </div>
              )}
            </TabPanel>
            <TabPanel
              header="Found Items"
              className={activeIndex === 2 ? "active_tab tab_view" : "tab_view"}
            >

              <div className="absolute" style={{
                right: "0px",
                top: "130px"
              }}>

                <div className="flex align-items-center" style={{ justifyContent: "unset" }}>
                  <div className="mx-2">
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>Search</h4>
                    <div
                      className="search"
                      style={{
                        border: "solid 1px #ae9d81",
                        width: "200px",
                        borderRadius: "4px"
                      }}
                    >
                      <span className="" style={{ display: "flex", alignItems: "center" }}>
                        <i
                          className="pi pi-search"
                          style={{
                            color: "black",
                            fontWeight: "700",
                            fontSize: "1rem",
                            cursor: "pointer",
                            padding: "0 10px"
                          }}
                        />
                        <InputText
                          value={foundFilterValue}
                          onChange={onFoundFilterChange}
                          placeholder="Search"
                          style={{ border: "unset", width: "100%", padding: "0.5rem 0" }}
                        />
                        <i
                          className="pi pi-times"
                          style={{
                            color: "black",
                            fontWeight: "400",
                            fontSize: "16px",
                            padding: "0 10px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setFoundFilterValue("");
                            onFoundFilterChange(null);
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="mx-2 align-self-end">
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>From Date</h4>
                    <Calendar
                      value={fromDate}
                      placeholder="Start Date"
                      onChange={(date) => {
                        setFormDate(date?.value);
                      }}
                      showIcon
                      selectionMode="single"
                      style={{
                        color: "#fff",
                        cursor: "pointer",
                        // position: "absolute",
                        // right: '24%',
                        // top: ' 123px',
                        height: "40px",
                        width: "150px",
                      }}
                      maxDate={today}
                    />
                  </div>
                  <div className="mx-2 align-self-end">
                    <h4 className='mb-1 mt-0' style={{ color: "#ae9d81" }}>To Date</h4>
                    <Calendar
                      value={toDate}
                      placeholder="End Date"
                      onChange={(date) => setToDate(date?.value)}
                      selectionMode="single"
                      showIcon
                      style={{
                        color: "#fff",
                        cursor: "pointer",
                        // position: "absolute",
                        // right: '13%',
                        // top: ' 123px',
                        height: "40px",
                        width: "150px",
                      }}
                      maxDate={today}
                    />
                  </div>
                  <div className="mx-2 align-self-end">
                    <Button
                      onClick={onDateChanging}
                      disabled={fromDate == "" || toDate == ""}
                      style={{
                        color: "#fff",
                        backgroundClip: "#ae9d81",
                        backgroundColor: "#ae9d81",
                        cursor: "pointer",
                        border: "unset",
                        // position: "absolute",
                        // right: "6.8%",
                        // top: "123px",
                        height: "38px",
                        lineHeight: "38px",
                        zIndex: 1
                      }}
                    >
                      Check
                    </Button>
                  </div>
                  <div className="mx-2 align-self-end">
                    <Button
                      onClick={() => {
                        onClearAllDate();
                      }}
                      style={{
                        color: "#fff",
                        backgroundColor: "#adadad",
                        backgroundClip: "#adadad",
                        border: "unset",
                        cursor: "pointer",
                        // position: "absolute",
                        // right: "1.5%",
                        // top: "123px",
                        height: "38px",
                        lineHeight: "38px",
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                  <div className="mx-2  align-self-end">
                    <Button
                      label="Export"
                      onClick={OnExportFoundClick}
                      icon={"pi pi-file-export"}
                      style={{
                        backgroundColor: "transparent",
                        color: "#ae9d81",
                        border: "1px #ae9d81 solid",
                        borderRadius: "20px",
                        cursor: "pointer",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>
              </div>
              {isLoadingFoundList ? (
                <div
                  className="spinner spinner-center"
                  style={{ marginLeft: "750px" }}
                ></div>
              ) : (
                <>
                  <DataTable
                    value={foundItemList}
                    tableStyle={{ width: "90rem" }}
                    style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                    emptyMessage={
                      <>
                        <p style={{ textAlign: "center" }}>
                          Found list is empty
                        </p>
                      </>
                    }
                    rows={10}
                  >
                    <Column field="foundCode" header="Found Id"></Column>
                    <Column field="category" header="Found Category"></Column>
                    <Column
                      field="categoryAR"
                      header="Found Category AR"
                    ></Column>
                    <Column field="whatDidYouFind" header="Item Name"></Column>
                    <Column
                      field="foundDateTime"
                      header="Found Date and Time"
                      body={dateFoundBodytemplate}
                    ></Column>
                    <Column
                      field="createdDate"
                      header="Created Date"
                      body={createdDateBodytemplate}
                    ></Column>
                    <Column
                      field="storageLocationId"
                      header="Storage Location"
                      body={storageBodyTemplate}
                    ></Column>
                    <Column header="Image" body={imageActionTemplate}></Column>
                    <Column field="status" header="Status"></Column>
                    {/* <Column header='Action' body={actionFoundBodyTemplate}></Column> */}
                  </DataTable>
                </>
              )}
              {isLoadingFoundList || foundItemList.length == 0 ? (
                ""
              ) : (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  <Paginator
                    first={firstFound}
                    rows={10}
                    totalRecords={foundListCount}
                    onPageChange={(e) => onFoundPageChange(e, 1)}
                    template={{
                      layout:
                        "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                    }}
                    className="justify-content-start"
                  />
                </div>
              )}
            </TabPanel>
          </TabView>
        </div>
      </div>
    </>
  );
}

export default LostAndFound;
