//api/GuestInspection/GetGuestInspectionsInfo
import Axios from '../Helpers/Axios'

export function GetGuestInspectionsInfo(token) {
    return Axios('GuestInspection/GetGuestInspectionsInfo', 'get', null, token)
}
export function GetFeedbacks({ skip, take }, token) {
    return Axios(`DepartmentTeam/GetFeedbacks?skip=${skip}&take=${take}`, 'get', null, token)
}

export function GetSearchedFeedbacks({ skip, take, searchValue }, token) {
    return Axios(`DepartmentTeam/GetFeedbacks?skip=${skip}&take=${take}&searchTerm=${searchValue}`, 'get', null, token)
}
export function GetGuestStaffInspections(take, skip, token, searchTerm) {
    return Axios(`DepartmentTeam/GetGuestInspectionForDashboard?skip=${skip}&take=${take}&searchTerm=${searchTerm}`, 'get', null, token)
}
export function GetGuestStaffInspectionsForAdmin(take, skip, token, searchTerm, startDate = "", endDate = "") {
    return Axios(`GuestInspection/GetGuestInspectionsForAdmin?fromDate=${startDate}&toDate=${endDate}&forExport=false&searchTerm=${searchTerm}&take=${take}&skip=${skip}`, 'get', null, token)
}
export function getSearchedInspectionForDashboard(take, skip, searchValue, token) {
    return Axios(`DepartmentTeam/GetGuestInspectionForDashboard?skip=${skip}&take=${take}&searchTerm=${searchValue}`, 'get', null, token)
}

export function EditFeedback({ feedbackId, statusId, isDeleted }, token) {
    return Axios(`DepartmentTeam/EditFeedback?feedbackId=${feedbackId}&statusId=${statusId}&isDeleted=${isDeleted}`, 'PATCH', null, token)
}
