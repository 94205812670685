import React, { useEffect, useState } from 'react';
import logo from '../../assets/images/logo.jpg';
import { useNavigate, useLocation } from 'react-router-dom'
import './style.scss';
import { ToastContainer, toast } from 'react-toastify'
import UseCookies from '../../Helpers/UseCookies';
import { Password } from 'primereact/password';
import { clearState } from '../../Redux/AppSlice';
import { useDispatch, useSelector } from "react-redux";








// import DropdownComponent from '../utilize/DropdownComponent'; 

import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { ValidPassword, UpdatePassword } from '../../Services/ChangePasswordService';




function ChangePassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const Context = useSelector((state) => state.AppContext);
    const [cookies, setCookie, removeCookie] = UseCookies();
    const [oldPassword, setOldPassword] = useState('')
    const [newPassword, setNewpassword] = useState('')
    const [reEnterPassword, setReEnterPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [submitClicked, setIsSubmitClicked] = useState(false);
    // const [password, setPassword] = useState({
    //     "oldPassword": '',
    //     "newPassword": '',
    //     "reenterPassword": ''
    // })

    const checkIsEmpty = () => {
        return oldPassword?.length > 0 && newPassword?.length > 0 && reEnterPassword?.length > 0;
    }
    const getOldPassword = (() => {
        if (!checkIsEmpty()) {
            setIsSubmitClicked(true);
            return
        };
        if (newPassword === reEnterPassword) {

            let token = cookies()?.bearerToken

            ValidPassword(oldPassword, token)
                .then((res) => {

                    if (res.data == true) {
                        ChangePassword();
                    } else {
                        toast.error("Password ii invalid")
                    }
                })
            // .catch((error) => {
            //     toast.error("No User Found");
            // })
        } else {
            toast.error("New Passwords do not match");
            setPasswordsMatch(false);
        }
    });


    const ChangePassword = (() => {
        let token = cookies()?.bearerToken
        UpdatePassword(newPassword, token)
            .then((res) => {

                setNewpassword("")
                setCookie('bearerToken', null);
                removeCookie('roles');
                dispatch(clearState());
                toast.success("Password change successful. You need to log in again.", { position: "top-right" });
                navigate('/');

            })
            .catch((error) => {
                toast.error("No User Found");
            })
    });

    const backToDashboard = () => {
        let roles = JSON.parse(JSON.stringify(cookies()?.roles));
        if (roles?.filter(role => role === "ADMIN")?.length !== 0) {
            navigate('/dashboard');
            return
        }
        if (roles?.filter(role => role === "GUESTSTAFFINSPECTOR")?.length !== 0) {
            navigate('/guestservicestaff');
            return
        }
        if (roles?.filter(role => role === "DEPARTMENTTEAM")?.length !== 0) {
            navigate('/departmentteam/feedback');
            return
        }
        if (roles?.filter(role => role === "GUESTSTAFFSUPERVISOR")?.length !== 0) {
            navigate('/guestservicesupervisor');
            return
        }
        if (roles?.filter(role => role === "MGTENANTRELATIONSHIP")?.length !== 0) {
            navigate('/mgtenentrelationship');
            return
        }
        if (roles?.filter((role) => role === "GSR")?.length !== 0) {
            navigate("/lostandfound/1");
            return;
        }
        if (roles?.filter((role) => role === "SECURITYSTAFF")?.length !== 0) {
            navigate("/security-staff/1");
            return;
        }
        if (roles?.filter((role) => role === "VIP_SUPERVISOR")?.length !== 0) {
            navigate("/vip-user-list");
            return;
        }
        if (roles?.filter((role) => role === "LOUNGE_STAFF")?.length !== 0) {
            navigate("/vip-user-list");
            return;
        }
        if (roles?.filter((role) => role === "PANTRY_STAF")?.length !== 0) {
            navigate("/pantry-staff-inventory");
            return;
        }
        if (
            roles?.filter((role) => role === "SAD_DELIVERY_PARTNER")?.length !== 0
        ) {
            navigate("/shop-and-drop-track-deliveries");
            return;
        }
        if (roles?.filter((role) => role === "GUESTSERVICESTAFF")?.length !== 0) {
            navigate("/guestservicestaff");
            return;
        }
        if (roles?.filter((role) => role === "HF_STORE")?.length !== 0) {
            navigate("/checkin-request");
            return;
        }
    }






    return (
        <div className="ChangePassword">
            <div className='logo' >
                <img alt='image' src={logo} onClick={backToDashboard} style={{ width: "200px", height: "80px", marginTop: "10px", background: "#fff", margin: "10px" }} />
            </div>

            <div
                style={{ width: '500px', padding: "20px", color: 'white', margin: 'auto', marginTop: '50px', backgroundColor: '#ae9d81d0', borderRadius: '5px' }} >
                <div className='defaultWidth'>
                    <p style={{ fontWeight: 700, fontSize: 25, color: "##2D6C8B", textAlign: 'center' }}>Change Password</p>
                </div>
                <div  >
                    <div className='defaultWidth' >
                        <p className='defaultStyle' >Old Password</p>
                        <div className='textinputstyle'>
                            <div style={{ flex: 0.1, alignItems: "center", justifyContent: "center", paddingHorizontal: 6 }}>

                            </div>
                            <div style={{ flex: 0.9, flexGrow: 1 }}>

                                <InputText className='textField'
                                    placeholder='Enter Old Password'
                                    value={oldPassword}
                                    onChange={(e) => { setOldPassword(e.target.value) }}
                                    type='password'


                                // value={email}
                                // onChange={(e) => { setEmail(e.target.value) }}
                                />
                            </div>
                        </div>
                        {submitClicked && !oldPassword && <p style={{ color: "red" }}>Please enter the old password</p>}
                    </div>
                    <div className='defaultWidth' >
                        <p className='defaultStyle' >New Password</p>
                        <div className='textinputstyle'>
                            <div style={{ flex: 0.1, alignItems: "center", justifyContent: "center", paddingHorizontal: 6 }}>

                            </div>
                            <div style={{ flex: 0.9, flexGrow: 1 }}>

                                <InputText className='textField'
                                    placeholder='Enter New Password'
                                    type='password'
                                    value={newPassword}
                                    onChange={(e) => { setNewpassword(e.target.value) }}
                                />
                            </div>
                        </div>
                        {submitClicked && !newPassword && <p style={{ color: "red" }}>Please enter the new password</p>}
                    </div>
                    <div className='defaultWidth' >
                        <p className='defaultStyle' >Re-enter New Password</p>
                        <div className='textinputstyle'>
                            <div style={{ flex: 0.1, alignItems: "center", justifyContent: "center", paddingHorizontal: 6 }}>

                            </div>
                            <div style={{ flex: 0.9, flexGrow: 1 }}>

                                <InputText
                                    placeholder='Enter New Password'
                                    type='password'
                                    className={`textField ${!passwordsMatch ? 'error' : ''}`}
                                    value={reEnterPassword}
                                    onChange={(e) => { setReEnterPassword(e.target.value); setPasswordsMatch(true); }}
                                />
                            </div>
                        </div>
                        {submitClicked && !reEnterPassword && <p style={{ color: "red" }}>Please enter the confirm password</p>}
                        {!passwordsMatch && (
                            <div className='validationError' style={{ color: 'red' }}>
                                Passwords do not match.
                            </div>
                        )}
                    </div>
                </div>


                <div className='defaultWidth' style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
                    <Button onClick={backToDashboard} style={{ border: "1px solid #AE9D81", width: "30%", height: "40px", backgroundColor: "White", cursor: "pointer", borderRadius: "5px", boxShadow: "none", color: '#8E8E8E' }}
                        className='loginButton button'>Cancel</Button>
                    <Button style={{ border: "1px solid #AE9D81", width: "30%", height: "40px", backgroundColor: "White", color: '#ae9d81', cursor: "pointer", borderRadius: "5px", boxShadow: "none", }}
                        className='loginButton button' onClick={getOldPassword} >Submit</Button>

                </div>
            </div>

        </div>

    )

}
export default ChangePassword

