import React, { useEffect, useMemo, useState } from "react";
import UseCookies from "../../Helpers/UseCookies";
// import logo from '../../assets/images/EHD_logo blue.svg';
import logo from "../../assets/images/logo.jpg";
import avatar from "../../assets/images/Ellipse 1.jpg";
import "./style.scss";
import "primeicons/primeicons.css";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { clearState } from "../../Redux/AppSlice";
import { Dropdown } from "primereact/dropdown";

function Header() {
  const navigate = useNavigate();
  const location = useLocation();
  const Context = useSelector((state) => state.AppContext);
  const [cookies, setCookie, removeCookie] = UseCookies();
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dispatch = useDispatch();
  // //console.log("Loaction", location);

  const backToDashboard = () => {
    let roles = JSON.parse(JSON.stringify(cookies()?.roles));

    if (roles?.filter((role) => role === "ADMIN")?.length !== 0) {
      navigate("/dashboard");
      return;
    }

    if (roles?.filter((role) => role === "GUESTSTAFFINSPECTOR")?.length !== 0) {
      navigate("/guestservicestaff");
      return;
    }
    if (roles?.filter((role) => role === "DEPARTMENTTEAM")?.length !== 0) {
      navigate("/departmentteam/feedback");
      return;
    }
    if (
      roles?.filter((role) => role === "GUESTSTAFFSUPERVISOR")?.length !== 0
    ) {
      navigate("/guestservicesupervisor");
      return;
    }
    if (
      roles?.filter((role) => role === "MGTENANTRELATIONSHIP")?.length !== 0
    ) {
      navigate("/mgtenentrelationship");
      return;
    }
    if (roles?.filter((role) => role === "GSR")?.length !== 0) {
      navigate("/lostandfound/1");
      return;
    }
    if (roles?.filter((role) => role === "SECURITYSTAFF")?.length !== 0) {
      navigate("/security-staff/1");
      return;
    }
    if (roles?.filter((role) => role === "VIP_SUPERVISOR")?.length !== 0) {
      navigate("/vip-user-list");
      return;
    }
    if (roles?.filter((role) => role === "LOUNGE_STAFF")?.length !== 0) {
      navigate("/vip-user-list");
      return;
    }
    if (roles?.filter((role) => role === "PANTRY_STAFF")?.length !== 0) {
      navigate("/pantry-purchase");
      return;
    }
    if (
      roles?.filter((role) => role === "SAD_DELIVERY_PARTNER")?.length !== 0
    ) {
      navigate("/shop-and-drop-track-deliveries");
      return;
    }
    if (roles?.filter((role) => role === "GUESTSERVICESTAFF")?.length !== 0) {
      navigate("/guestservicestaff");
      return;
    }
    if (roles?.filter((role) => role === "HF_STORE")?.length !== 0) {
      navigate("/shop-and-drop-track-deliveries");
      return;
    }
    if (roles?.filter((role) => role === "HF_RUNNER")?.length !== 0) {
      navigate("/shop-and-drop-track-deliveries");
      return;
    }

    // handleLogout();
    // return;
  };

  const items = [{ label: "Change Password" }, { label: "Log Out" }];

  const handleLogout = (isLogout = false) => {
    if (isLogout) {
      setCookie("bearerToken", null, { path: '/' });
      removeCookie("bearerToken", { path: '/' });
      removeCookie("roles", { path: '/' });
      removeCookie("userId", { path: '/' });
      removeCookie("organizationID", { path: '/' });
      localStorage.clear();
      localStorage.removeItem("userName");
      localStorage.removeItem("redirectUrl");
      dispatch(clearState());
      // Redirect to the login page or any other appropriate page
      navigate("/");
    }

  };

  const handleDropdownChange = (event) => {

    const selectedItem = event?.value;

    if (selectedItem?.label === "Log Out") {
      handleLogout(true);
    } else {
      navigate("/changepassword");
    }
  };

  // Inactive State timeout

  // Set initial user activity status
  // let userIsActive = true;

  // // // Perform logout action here
  // const setInactive = () => {
  //     if (userIsActive) {
  //         // //console.log("User is inactive. Logout.");
  //         userIsActive = false;
  //         setCookie("bearerToken", null);
  //         setCookie("roles", null);
  //         setCookie("userId", null);
  //         navigate("/");
  //     }
  // };

  // const resetInactiveTimer = () => {
  //     clearTimeout(inactiveTimeout);
  //     if (!userIsActive) {
  //         // //console.log("User is active again.");
  //         userIsActive = true;
  //     }
  //     inactiveTimeout = setTimeout(setInactive, 30000);
  //     // Set the timeout duration (e.g., 5000 milliseconds = 5 seconds)
  // };

  // document.addEventListener("mousemove", resetInactiveTimer);
  // document.addEventListener("mousedown", resetInactiveTimer);
  // document.addEventListener("keydown", resetInactiveTimer);
  // document.addEventListener("scroll", resetInactiveTimer);

  // // Initialize the timeout
  // let inactiveTimeout = setTimeout(setInactive, 30000);
  const onSelectingList = (value) => {
    if (value == "changePassword") {
      navigate("/changepassword");
    } else {
      handleLogout(true);
    }
    setIsDropdownVisible(false);
  };
  //   const DropDown = () => {
  //     return (
  //       <div className="dropdown">
  //         <span onClick={() => setIsDropdownVisible(true)}>
  //           <i className="pi pi-chevron-down"></i>
  //         </span>
  //         <div
  //           className="dropdown-content"
  //           style={{ display: isDropdownVisible ? "block" : "none" }}
  //         >
  //           <p
  //             className="dropdDownItems"
  //             onClick={() => onSelectingList("changePassword")}
  //           >
  //             Change Password
  //           </p>
  //           <p className="dropdDownItems" onClick={() => onSelectingList()}>
  //             Log Out
  //           </p>
  //         </div>
  //       </div>
  //     );
  //   };

  return (
    <>
      {location.pathname !== "/" &&
        location.pathname !== "/feedbackform" &&
        location.pathname !== "/forgotpassword" &&
        location.pathname !== "/changepassword" &&
        location.pathname !== "/feedback-form" &&
        location.pathname !== "/guest-login" &&
        location.pathname !== "/change-pickup" &&
        location.pathname !== "/guest-info" &&
        location.pathname !== "/login" ? (
        <div className="header" style={{ justifyContent: "space-between" }}>
          <div
            className="sm:col-4 md:col-4 lg:col-2"
            style={{
              //  width: '14%',
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          // className="logo"
          >
            <div style={{ width: "80%" }}>
              <img
                src={logo}
                alt="logo"
                onClick={backToDashboard}
                style={{ width: "100%", height: '80px' }}
              />
            </div>

            <div style={{ width: "20%" }}>
              <i
                className="pi pi-home"
                style={{
                  background: "#ae9d81",
                  color: "white",
                  border: "none",
                  padding: "4px",
                  cursor: "pointer",
                  borderRadius: "4px",
                }}
                onClick={backToDashboard}
              ></i>
            </div>
          </div>
          <div
            // className="profile"
            className="sm:col-8 md:col-8 lg:col-10"
            style={{
              //  width: "86%",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <div className="name">{localStorage.getItem("userName")}</div>
            <div
            >
              <Dropdown
                options={items}
                value={""}
                style={{ border: "none", marginTop: "7px", marginRight: "5px" }}
                optionLabel="label"
                onChange={handleDropdownChange}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Header;
