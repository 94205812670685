import Axios from '../Helpers/Axios';

export function GetFeedbackChannelList(token, skip = 0, take = 10, searchTerm = "", status = "", IsExport = false, showAll = false) {
    return Axios(`FeedbackChannel/GetList?skip=${skip}&take=${take}&searchTerm=${searchTerm}&IsExport=${IsExport}&status=${status}&showAll=${showAll}`, 'get', null, token)
}
export function AddFeedbackChannel(data, token) {
    return Axios('FeedbackChannel/Add', 'post', data, token)
}
export function EditFeedbackChannel(data, token) {
    return Axios('FeedbackChannel/Edit', 'post', data, token)
}


