import { useNavigate, useParams } from 'react-router-dom';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import './style.scss';
import { Button } from 'primereact/button';
import { useEffect } from 'react';
import UseCookies from '../../Helpers/UseCookies';
import { useState } from 'react';
import { AddLostItemForm, EditLostItemForm, GetLostItem } from '../../Services/LostandFoundService';
import { GetActiveCategory, GetCategory } from '../../Services/LostAndFoundCategoryService';
import { toast } from 'react-toastify';
import { CiImageOn } from 'react-icons/ci';
import { DownloadFile } from '../../Services/Files';


function AdminLostView() {
    const { id } = useParams();
    const [cookies, setCookie, removeCookie] = UseCookies();
    const navigate = useNavigate();
    const [addLostForm, setAddLostForm] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        category: '',
        lostItem: '',
        lostDateTime: '',
        lostLocation: '',
        attachment: []
    });
    const [isAdd, setIsAdd] = useState(false);
    const [isInvalid, setIsInvalid] = useState(false);
    const [activeCategories, setActiveCategories] = useState([]);

    const getLostItem = async (id) => {
        await GetLostItem(id, cookies()?.bearerToken)
            .then((res) => {
                if (res.status == 200) {
                    let lostItem = res.data;
                    //console.log("Lost Item", lostItem);
                    setAddLostForm((prev) => ({
                        ...prev,
                        firstName: lostItem?.firstName,
                        lastName: lostItem?.lastName,
                        email: lostItem?.email,
                        phoneNumber: lostItem?.phoneNumber,
                        category: lostItem?.category,
                        lostItem: lostItem?.whatDidYouLose,
                        lostDateTime: lostItem?.lostDateTime,
                        lostLocation: lostItem?.whereDidYouLose,
                        statusId: lostItem?.statusId,
                        files: lostItem?.files,
                    }));

                }
            })
            .catch((err) => {
                //console.log(err);
            })
            .finally(() => {
                //console.log("Edit Form Data", addLostForm);
            })

    };



    const getCategories = async () => {
        await GetCategory(cookies()?.bearerToken)
            .then((res) => {
                if (res.status === 200) {
                    //console.log("Active Categories", res);
                    setActiveCategories(res.data);
                }
            })
            .catch((err) => {
                //console.log(err);
            })
    };




    const isAdmin = () => {
        let roles = JSON.parse(JSON.stringify(cookies()?.roles));
        return (roles?.filter(role => role === "ADMIN")?.length !== 0)
    };

    const downloadFile = (_) => {
        DownloadFile(_.fileName, _.blobUrl, cookies()?.bearerToken);
    };



    useEffect(() => {
        if (id !== undefined) {
            getLostItem(id);
            setIsAdd(false);
            return;
        }
        setIsAdd(true);
    }, [])


    return (
        <div className="AddLostForm">
            <div className="header" style={{ backgroundColor: '#ae9d81', color: 'white' }}>
                <i className="pi pi-arrow-left" style={{ cursor: 'pointer' }} onClick={() => { navigate('/admin-lost-found/lost') }} ></i>
                <div style={{ margin: 'auto', fontWeight: '600', fontSize: '18px' }}>View Lost Form Details</div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0px 50px' }}>
                <div>
                    <p>First Name</p>
                    <div>
                        <InputText placeholder='Enter First Name' style={{ width: '350px' }} value={addLostForm?.firstName} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, firstName: e.target.value })) }} disabled />
                    </div>
                    {isInvalid && addLostForm?.firstName == "" &&
                        <p style={{ color: "red" }}>First name is required</p>
                    }
                </div>
                <div>
                    <p>Last Name</p>
                    <div>
                        <InputText placeholder='Enter Last Name' style={{ width: '350px' }} value={addLostForm?.lastName} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, lastName: e.target.value })) }} disabled />
                    </div>
                    {isInvalid && addLostForm?.lastName == "" &&
                        <p style={{ color: "red" }}>Last name is required</p>
                    }
                </div>
                <div>
                    <p>Email</p>
                    <div>
                        <InputText placeholder='Enter Email' style={{ width: '350px' }} value={addLostForm?.email} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, email: e.target.value })) }} disabled />
                    </div>
                    {isInvalid && addLostForm?.email == "" &&
                        <p style={{ color: "red" }}>Email is required</p>
                    }
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div>
                    <p>Mobile Number</p>
                    <div>
                        <InputText placeholder='Enter Phone number' style={{ width: '350px' }} value={addLostForm?.phoneNumber} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, phoneNumber: e.target.value })) }} disabled />
                    </div>
                    {isInvalid && addLostForm?.phoneNumber == "" &&
                        <p style={{ color: "red" }}>Mobile number is required</p>
                    }
                </div>
                <div>
                    <p>Category</p>
                    <div>
                        <InputText placeholder='Category' style={{ width: '350px', borderColor: '#ae9d81' }} value={addLostForm?.category} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, category: e.target.value })) }} disabled />
                    </div>
                    {isInvalid && addLostForm?.category == "" &&
                        <p style={{ color: "red" }}>Category is required</p>
                    }
                </div>
                <div>
                    <p>What did you lost?</p>
                    <div>
                        <InputText placeholder='Enter what did you lost' style={{ width: '350px' }} value={addLostForm?.lostItem} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, lostItem: e.target.value })) }} disabled />
                    </div>
                    {isInvalid && addLostForm?.lostItem == "" &&
                        <p style={{ color: "red" }}>Lost item  is required</p>
                    }
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '20px 50px 0px 50px' }}>
                <div>
                    <p> When did you lost?(Date )</p>
                    <div>
                        <Calendar placeholder='Select date' className="calendar" style={{ width: '350px' }} showIcon value={new Date(addLostForm?.lostDateTime)} maxDate={new Date()} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, lostDateTime: e.target.value })) }} disabled />
                    </div>
                    {isInvalid && addLostForm?.lostDateTime == "" &&
                        <p style={{ color: "red" }}>Date and time of lost item is required</p>
                    }
                </div>
                <div>
                    <p>Where did you lost?</p>
                    <div>
                        <InputText placeholder='Enter where did you lost' style={{ width: '350px', borderColor: '#ae9d81' }} value={addLostForm?.lostLocation} onChange={(e) => { setAddLostForm((prev) => ({ ...prev, lostLocation: e.target.value })) }} disabled />
                    </div>
                    {isInvalid && addLostForm?.lostLocation == "" &&
                        <p style={{ color: "red" }}>Location where the item is lost  is required</p>
                    }
                </div>
                <div>
                    <p>Attachment</p>
                    <div>
                        <div style={{ width: '350px', height: '160px', border: '1px solid #ae9d81', borderRadius: '10px' }} >
                            {addLostForm?.files?.length == 0 ? <p style={{ marginLeft: "10px", fontWeight: 100, color: "#9d9d9d" }} >No attachments found</p> :
                                <div style={{ padding: "10px", overflow: 'scroll', height: '150px  ', overflowX: 'hidden' }}>
                                    {addLostForm?.files?.length > 0 &&
                                        addLostForm?.files.map((image) => {
                                            return (
                                                <div style={{ display: "flex", alignItems: "center", marginLeft: '5px', overflowX: 'hidden' }}>
                                                    <CiImageOn style={{ fontSize: 20 }} />
                                                    <p style={{ fontSize: '12px', padding: "0 10px", cursor: "pointer" }} onClick={() => {
                                                        downloadFile(image);
                                                    }}>{image.fileName}</p>
                                                </div>)
                                        })
                                    }
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex', justifyContent: 'end', gap: '20px', margin: "10px 50px 50px 0px"
            }}>
                <Button label='Cancel' className='cnl-btn' onClick={() => { navigate('/admin-lost-found/lost') }} />

            </div>

        </div>
    )
}

export default AdminLostView