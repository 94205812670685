import { toast } from "react-toastify";
import React, { useState, useEffect, useRef } from "react";
import "./Inventory.scss";
import UseCookies from "../../../../Helpers/UseCookies";
import { useDispatch } from "react-redux";
import { setNavVisibilty } from "../../../../Redux/AppSlice";

// Prime React Components
import Modalinfo from "../../../utili/Modalinfo";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Paginator } from "primereact/paginator";
import { Dialog } from "primereact/dialog";
import { Badge } from "primereact/badge";
import {
  GetPantryItems,
  BulkUpdateInventoryItem,
} from "../../../../Services/VIPLoungePantryStaff";
import { FileUpload } from "primereact/fileupload";

import localAsset from "../../../../assets/files/VIP_PatchPantryItems.csv";
import editIcon from "../../../../assets/images/editIcon.jpg";

// ------------------------------

// Assests
import nonFound from "../../../../assets/images/nonfound.png";
import { useNavigate } from "react-router-dom";
// ------------------------------

const rowper = 10;
function InventoryBulkEdit() {
  const [cookies, setCookie, removeCookie] = UseCookies();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isConfirmationModalOpen, setIsConformationModalOpen] = useState(false);

  // Pagination
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  // Search && Filter
  const [searchFilterValue, setSearchFilterValue] = useState("");

  // Inventory List
  const [inventoryList, setInventoryList] = useState([]);
  const navigate = useNavigate();

  // Updated Data for Bulk edit
  const [bulkEdit, setBulkEdit] = useState([]);

  const getStatusSeverity = (row) => {
    let statusSeverity = "";
    if (row.status === "Inactive") {
      statusSeverity = "warning";
    } else if (row.status === "Active") {
      statusSeverity = "success";
    }
    return statusSeverity;
  };

  //quantity
  const quantityTextBoxTemplate = (row) => {
    return (
      <>
        <InputText
          type="number"
          value={row?.quantity}
          onChange={(e) => {
            handleInputValue(row, Number(e?.target?.value));
            handleBulkEditChanges(row, Number(e?.target?.value));
          }}
        />
      </>
    );
  };

  const statusTemplate = (row) => {
    return (
      <div className="delivery-status">
        <Badge value={row.status} severity={getStatusSeverity(row)} />
      </div>
    );
  };

  const handleBulkEditChanges = (row, value) => {
    let index = bulkEdit.findIndex((item) => row.id == item.id);
    if (index === -1) {
      bulkEdit.push(row);
    } else {
      bulkEdit[index] = row;
    }
    console.log(bulkEdit);
  };

  const handleInputValue = (row, value) => {
    let tempInvoiceList = [...inventoryList];
    let index = inventoryList.findIndex((val) => val?.id == row?.id);
    if (index !== -1 && tempInvoiceList[index]) {
      tempInvoiceList[index].quantity = value;
    }
    setInventoryList([...tempInvoiceList]);
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    getInventoryList(event.first, rowper, searchFilterValue);
  };

  const handleUpdateQuantity = async (row) => {
    let data = [...bulkEdit].map((row) => {
      return {
        quantity: row?.quantity,
        id: row?.id,
      };
    });
    await BulkUpdateInventoryItem(data, cookies()?.bearerToken)
      .then((res) => {
        if (res?.status === 200) {
          toast.success("Updated successfully");
          navigate("/pantry-staff-inventory");
        }
      })
      .finally(() => {
        setBtnLoading(false);
        setIsLoading(false);
      });
  };

  const getInventoryList = async (skip = 0, take = rowper, searchTerm = "") => {
    setIsLoading(true);
    await GetPantryItems(skip, take, searchTerm, cookies()?.bearerToken)
      .then((res) => {
        console.log("new res", res);
        if (res?.status === 200) {
          console.log("res?.data?.data", res?.data?.data);
          let allInventoryData = res?.data?.data?.map((val) => {
            if (val && val?.quantity) {
              val.quantity = 0;
            }
            return val;
          });
          console.log("allInventoryData", allInventoryData);
          setInventoryList(allInventoryData);
          setTotalCount(res?.data?.totalRecords);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Import Items to Inventory

  useEffect(() => {
    getInventoryList();
  }, []);

  return (
    <>
      {isLoading ? (
        <div
          className="spinner spinner-center"
          style={{ marginLeft: "750px" }}
        ></div>
      ) : (
        <>
          <div className="Inventory-bulk-edit">
            <div
              className="header"
              style={{
                height: "60px",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: "15px",
                }}
              >
                <div className="menubtn">
                  <i
                    className="pi pi-arrow-left"
                    style={{
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      navigate("/pantry-staff-inventory");
                    }}
                  ></i>
                </div>
                <div className="heading">
                  <p
                    style={{
                      color: "#00488f",
                      fontWeight: 700,
                      fontSize: "18px",
                    }}
                  >
                    Inventory bulk edit
                  </p>
                </div>
              </div>
              <Button
                label="Update"
                onClick={() => {
                  if (bulkEdit.length) {
                    setIsConformationModalOpen(true);
                  }
                }}
                style={{ backgroundColor: "#ae9d81", border: "none" }}
              />
            </div>

            <div className="inventory-table">
              <div className="inventory-table-content">
                {!isLoading ? (
                  <>
                    <DataTable
                      value={inventoryList}
                      style={{ boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" }}
                      tableStyle={{ width: "90rem", margin: "10px 39px" }}
                      stripedRows
                      rows={10}
                      emptyMessage={
                        <>
                          <p style={{ textAlign: "center" }}>List is empty</p>
                        </>
                      }
                    >
                      <Column
                        field="name"
                        header="Name"
                        style={{ width: "23%" }}
                      ></Column>
                      <Column
                        field="amount"
                        header="Price"
                        style={{ width: "23%" }}
                      ></Column>
                      <Column
                        style={{ width: "23%" }}
                        field="status"
                        header="Status"
                        body={statusTemplate}
                      ></Column>
                      <Column
                        style={{ width: "23%" }}
                        field={"quantity"}
                        header={"Quantity"}
                        body={quantityTextBoxTemplate}
                      ></Column>
                    </DataTable>
                  </>
                ) : (
                  <div
                    className="spinner spinner-center"
                    style={{
                      display: "flex",
                      margin: "auto",
                      marginTop: "200px",
                    }}
                  ></div>
                )}
              </div>
            </div>
            {inventoryList?.length == 0 || isLoading ? (
              ""
            ) : (
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Paginator
                  first={first}
                  rows={rowper}
                  totalRecords={totalCount}
                  onPageChange={(e) => onPageChange(e, 1)}
                  template={{
                    layout:
                      "RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink  LastPageLink CurrentPageReport",
                  }}
                  className="justify-content-start"
                />
              </div>
            )}
          </div>
          <Dialog
            header={
              <h3 style={{ textAlign: "center", color: "#ae9d81", margin: 0 }}>
                Confirmation
              </h3>
            }
            visible={isConfirmationModalOpen}
            style={{ width: "30%" }}
            onHide={() => {
              setIsConformationModalOpen(false);
            }}
          >
            <div className="track-delivery-view-success-modal">
              <div className="success-modal-content">
                <p style={{ fontSize: "18px" }}>
                  Are you sure want update changes?
                </p>
              </div>
              <div className="success-modal-btn" style={{ gap: "10px" }}>
                <Button
                  label={"No"}
                  style={{
                    backgroundColor: "#8e8e8e",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setIsConformationModalOpen(false);
                  }}
                />
                <Button
                  label={"Yes"}
                  loading={btnLoading}
                  style={{
                    backgroundColor: "#ae9d81",
                    border: "none",
                    borderRadius: "3px",
                    width: "120px",
                    height: "40px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setBtnLoading(true);
                    setIsLoading(true);
                    handleUpdateQuantity();
                  }}
                />
              </div>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
}

export default InventoryBulkEdit;
